import React, { useState } from "react";
import { Modal, CloseButton, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import saRequestSlice, {
  clearSaRequestFee,
} from "../../../../store/sarequestSlice";
import { useCurrency } from "../../../hooks/currency";
import ModalPassword from "./modalPassword";
import { postSaRequest } from "../../../../store/inputSlice/service";
import inputSlice from "../../../../store/inputSlice";
import ModalAgreement from "./modalAgreement";

function ModalRequest({ selectedAccountId, show, onHide }) {
  const dispatch = useDispatch();
  const { currencyFormat, getAccountInfo } = useCurrency();
  const { amount, fee, net_amount } = useSelector(
    saRequestSlice.selector.requestFee
  );
  const { loading: inputLoading } = useSelector(inputSlice.selector.all);
  const [showPassModal, setShowPassModal] = useState(false);
  const [showAgreementModal, setShowAgreementModal] = useState(false);

  const handleConfirm = () => {
    // setShowAgreementModal(true);
    setShowPassModal(true);
  };

  const handleCloseAll = () => {
    dispatch(clearSaRequestFee());
    setShowPassModal(false);
    setShowAgreementModal(false);
    onHide();
  };

  const postSa = (cb) => {
    dispatch(postSaRequest(cb));
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* showPassModal ? (
          <ModalAgreement
            show={showAgreementModal}
            onHide={() => setShowAgreementModal(false)}
            setShowPassModal={setShowPassModal}
          />
        ) : */}
        {showPassModal ? (
          <ModalPassword
            action={postSa}
            loading={inputLoading}
            show={showPassModal}
            onHide={handleCloseAll}
          />
        ) : (
          <Modal.Body className="p-4">
            <div align="right">
              <CloseButton onClick={onHide} />
            </div>
            <div className="bsa-request">
              <p className="bsa-request-title mb-0">
                {"SA_CONFIRM_REQUEST_TTL_TXT".tr()}
              </p>
              <p className="bsa-request-desc">
                {"SA_CONFIRM_DETAIL_TTL_TXT".tr()}
              </p>
              <div className="bsa-request-detail">
                <p className="mb-0 bsa-request-detail-title">
                  {"SA_TOTAL_RECEIVE_TXT".tr()}
                </p>
                <p className="mb-0 bsa-request-detail-total">
                  IDR {currencyFormat(net_amount)}
                </p>
                <hr />
                <p className="mb-0 bsa-request-detail-title">
                  {"SA_RECEIVE_ACC_TXT".tr()}
                </p>
                <p className="mb-0 bsa-request-detail-right">
                  {getAccountInfo(selectedAccountId)}
                </p>
                <hr />
                <p className="mb-2 bsa-request-detail-title">
                  {"SA_CONFIRM_DETAIL_TTL_TXT".tr()}
                </p>
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-0 bsa-request-detail-left">
                    {"HEADER_SA_AMOUNT_TXT".tr()}
                  </p>
                  <p className="mb-0 bsa-request-detail-right">
                    IDR {currencyFormat(amount)}
                  </p>
                </div>

                {fee.map((feeItem, fid) => {
                  return (
                    <div
                      key={fid}
                      className="d-flex align-items-center justify-content-between"
                    >
                      <p className="mb-0 bsa-request-detail-left">
                        {feeItem.fee_label}
                      </p>
                      <p className="mb-0 bsa-request-detail-right">
                        IDR {currencyFormat(feeItem.fee_amount)}
                      </p>
                    </div>
                  );
                })}

                <hr />
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-0 bsa-request-detail-left">
                    {"SA_TOTAL_RECEIVE_TXT".tr()}
                  </p>
                  <p className="mb-0 bsa-request-detail-right">
                    IDR {currencyFormat(net_amount)}
                  </p>
                </div>
              </div>
            </div>
            <Button
              className="btn btn-primary w-100 mt-3 pt-2 pb-2"
              onClick={handleConfirm}
            >
              {"CONFIRM_TXT".tr()}
            </Button>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
}

export default ModalRequest;

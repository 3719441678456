import React from 'react'
import { Button } from 'react-bootstrap';
import Email from '../../../../assets/img/img_set_email.png';

function PartChangeStatus({ onHide, setPage }) {
    const handleSubmit = () => {
        onHide();
        setPage(1);
    }
    
    return (
        <div className='bsa-modal-forgot-password'>
            <div className='bsa-logo-change-email' align="center">
                <img src={Email} alt="Change Email" />
            </div>
            <p className='bsa-modal-forgot-password-title mb-3'>{'Change Email'}</p>
            <p className='bsa-modal-forgot-password-desc mb-3'>Please check your email inbox</p>
            <Button variant="primary" className="w-100 pt-2 pb-2" onClick={() => handleSubmit()}>
                {"OK"}
            </Button>
        </div>
    )
}

export default PartChangeStatus
import React from 'react'
import { CloseButton, Button } from 'react-bootstrap'
import ProfilePic from '../../../../assets/img/avatar_2.png';
import LockIcon from '@mui/icons-material/Lock';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
// import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LogoutIcon from '@mui/icons-material/Logout';
import { useSelector } from 'react-redux';
// import EmailIcon from '@mui/icons-material/Email';
import userSlice from '../../../../store/userSlice';
import { IconButton } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

function PartProfileModal({ onHide, onLogout, setPage }) {
    const { userInfo } = useSelector(userSlice.selector.all)

    return (
        <>
            <div className='bsa-close-modal-btn' align="right">
                <CloseButton onClick={onHide} />
            </div>
            <div className='bsa-profile-account'>
                <div className='d-flex align-items-center justify-content-center'>
                    <div className='bsa-profile-account-pic'>
                        <img src={ProfilePic} width={100} alt={"Profile"} />
                    </div>
                    <div className='bsa-profile-account-detail'>
                        <p className='bsa-profile-account-detail-name mb-1'>{userInfo?.full_name}</p>
                        <p className='bsa-profile-account-detail-code mb-1'>{userInfo?.employee_code}</p>
                        <p className='bsa-profile-account-detail-pt mb-1'>{userInfo?.company_name}</p>
                        <p className='bsa-profile-account-detail-code mb-0 w-100 d-flex align-items-center'>
                            email@gmail.com
                            <IconButton className='ms-2 p-1' onClick={() => setPage(4)}>
                                <ModeEditIcon className='fill-primary' sx={{ fontSize: 15 }} />
                            </IconButton>
                        </p>
                    </div>
                </div>
            </div>
            <div className='bsa-profile-menu'>
                <Button onClick={() => setPage(2)} variant='light' className='bg-white d-flex align-items-center justify-content-between border-0 w-100 mb-1 p-3'>
                    <div className='d-flex align-items-center'>
                        <LockIcon className='fill-primary' />
                        <p className='ms-2 mb-0'>Change Password</p>
                    </div>
                </Button>
                {/* <Button onClick={() => setPage(4)} variant='light' className='bg-white d-flex align-items-center justify-content-between border-0 w-100 mb-1 p-3'>
                    <div className='d-flex align-items-center'>
                        <EmailIcon className='fill-primary' />
                        <p className='ms-2 mb-0'>Change Email</p>
                    </div>
                </Button> */}
                <Button onClick={() => setPage(3)} variant='light' className='bg-white d-flex align-items-center justify-content-between border-0 w-100 mb-1 p-3'>
                    <div className='d-flex align-items-center'>
                        <PersonOutlineIcon className='fill-primary' />
                        <p className='ms-2 mb-0'>FAQ</p>
                    </div>
                    <ChevronRightIcon fontSize='small' />
                </Button>
                {/* <Button onClick={() => setPage(5)} variant='light' className='bg-white d-flex align-items-center justify-content-between border-0 w-100 mb-1 p-3'>
                    <div className='d-flex align-items-center'>
                        <MailOutlineIcon className='fill-primary' />
                        <p className='ms-2 mb-0'>Contact Us</p>
                    </div>
                    <ChevronRightIcon fontSize='small' />
                </Button> */}
            </div>
            <div className='ps-3 mt-5'>
                <Button onClick={onLogout} variant='primary' className='w-100 pt-2 pb-2'><LogoutIcon fontSize='small' className='me-2' /> {"Logout"}</Button>
            </div>
        </>
    )
}

export default PartProfileModal
import { createSlice } from "@reduxjs/toolkit"

const toastSlice = createSlice({
  name: 'toastStore',
  initialState: {
    toasts: [],
    currentId: 0
  },
  reducers: {
    addToast: (state, action) => {
      if(state.toasts.length === 5){
        let current = [...state.toasts, {...action.payload, id : state.currentId + 1 }]
        current.shift()
        state.toasts = current
      } else {
        const exists = state.toasts.find((toast) => toast.message === action.payload.message)
        if(!exists) state.toasts.push({...action.payload, id : state.currentId + 1 })
      }
      state.currentId = state.currentId + 1
    },
    removeToast: (state, action) => {
      let current = [...state.toasts.filter((toast) => toast.id !== action.payload)]
      state.toasts = current
    }
  },
})

export const {
  addToast, removeToast
} = toastSlice.actions

toastSlice.selector = {
  all: (state) => state[toastSlice.name]
}

export default toastSlice
import React from "react";
import { Modal, CloseButton } from "react-bootstrap";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";

function ModalCameraLeft({ show, onHide, setPhotoLeft }) {
  const handleTakePhoto = (dataUri) => {
    setPhotoLeft(dataUri);
    // console.log('data URL : ' + dataUri);
    onHide();
  };

  function handleCameraError(error) {
    // console.log('handleCameraError', error);
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="p-4">
        <div align="right">
          <CloseButton onClick={onHide} />
        </div>
        <div className="bsa-modal-cameraleft">
          <div className="bsa-modal-cameraleft-cover"></div>
          <Camera
            isFullscreen={true}
            idealResolution={{ width: 640, height: 480 }}
            imageType={IMAGE_TYPES.JPG}
            imageCompression={0.97}
            onCameraError={(error) => {
              handleCameraError(error);
            }}
            onTakePhotoAnimationDone={(dataUri) => {
              handleTakePhoto(dataUri);
            }}
          />
        </div>
        <p className="font-11 w-50 mx-auto text-center mb-0">
          Pastikan foto identifikasi anda terlihat jelas pada kamera.
        </p>
      </Modal.Body>
    </Modal>
  );
}

export default ModalCameraLeft;

import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SignIn from "./account/signin";
import Dashboard from "./dashboard";
import HomePage from "./home";
import { getAccessToken, getAuthToken } from "../store/userSlice/service";
import userSlice from "../store/userSlice";
import { useTranslation } from "../store/languageSlice/languageHook";
import ProtectedRoute from "./account/RouteProtector";
import Toaster from "./layouts/toast";
import { loadServices } from "../store/serviceSlice/service";

import {
  getRegularSAHistory,
  getSAHistory,
} from "../store/sarequestSlice/service";

import { getUserData } from "../store/userSlice/service";

export default function Core() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken, authorization } = useSelector(userSlice.selector.all);
  const { flag_information } = useSelector(userSlice.selector.userData);

  const routes = [
    { url: "", element: <SignIn /> },
    // { url: "", element: <HomePage /> },
    // { url: "/signin", element: <SignIn /> },
    {
      url: "/dashboard/*",
      element: (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      ),
    },
    // { url: "*", element: <HomePage /> },
    { url: "*", element: <SignIn /> },
  ];

  useEffect(() => {
    if (!accessToken) {
      dispatch(getAccessToken());
    } else {
      dispatch(getAuthToken());
    }
  }, [dispatch, accessToken]);

  useEffect(() => {
    if (authorization) {
      dispatch(getUserData());
      dispatch(getSAHistory());
      dispatch(loadServices());
      dispatch(getRegularSAHistory("1900-01-01", "2030-01-01"));
    }
  }, [dispatch, authorization]);

//   useEffect(() => {
//     if (!flag_information) {
//       navigate("/dashboard/kyc");
//     }
//   }, []);

  const { init } = useTranslation();
  init();

  return (
    <div className="bsa">
      <Routes>
        {routes.map((data, i) => (
          <Route key={i} exact path={`${data.url}`} element={data.element} />
        ))}
      </Routes>
      <Toaster />
    </div>
  );
}

import { KeyboardArrowDown } from "@mui/icons-material";
import { FormControl, MenuItem, OutlinedInput, Select } from "@mui/material";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import inputSlice, { setInputData } from "../../../../store/inputSlice";
import serviceSlice from "../../../../store/serviceSlice";

function RequestStepEmergency() {
  const dispatch = useDispatch();
  const { emergencyInfoRelation } = useSelector(serviceSlice.selector.data);
  const {
    emergency_name_first,
    emergency_mobileno_first,
    emergency_relation_id_first,
    emergency_name_second,
    emergency_mobileno_second,
    emergency_relation_id_second,
  } = useSelector(inputSlice.selector.kyc);

  const handleInput = (name, value) => {
    dispatch(setInputData({ name, value }));
  };

  return (
    <div className="bsa-registration">
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-md-6">
              <p className="mb-3 bsa-title-text-step font-14">
                {"PRIMARY_CONTACT_TXT".tr()}
              </p>
              {emergency_name_first.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"FULL_NAME".tr()} ({emergency_name_first.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">{"FULL_NAME".tr()}</p>
              )}
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  type="text"
                  name="bsaFullName"
                  placeholder={"FULL_NAME_CAPTION_TXT".tr()}
                  className="mb-3"
                  inputProps={{ maxLength: 50 }}
                  value={emergency_name_first.value}
                  onChange={(e) =>
                    handleInput("emergency_name_first", e.target.value)
                  }
                />
              </FormControl>
              {emergency_mobileno_first.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"HOME_PHONE_NUMBER_TXT".tr()} (
                  {emergency_mobileno_first.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">
                  {"HOME_PHONE_NUMBER_TXT".tr()}
                </p>
              )}
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  type="text"
                  name="bsaHomePhoneNumber"
                  placeholder={"HOME_PHONE_NUMBER_CAPTION_TXT".tr()}
                  className="mb-3"
                  // inputProps={{ maxLength : 20 }}
                  value={emergency_mobileno_first.value}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/\D/g, "");
                    handleInput("emergency_mobileno_first", newValue);
                  }}
                />
              </FormControl>
              {emergency_relation_id_first.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"RELATIONSHIP_TXT".tr()} (
                  {emergency_relation_id_first.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">
                  {"RELATIONSHIP_TXT".tr()}
                </p>
              )}
              <FormControl fullWidth className="mb-3">
                <Select
                  displayEmpty
                  IconComponent={KeyboardArrowDown}
                  className={
                    emergency_relation_id_first.value === ""
                      ? "bsa-unselect-mui"
                      : ""
                  }
                  value={emergency_relation_id_first.value}
                  onChange={(e) =>
                    handleInput("emergency_relation_id_first", e.target.value)
                  }
                >
                  <MenuItem disabled={true} value={""}>
                    {"RELATIONSHIP_CAPTION_TXT".tr()}
                  </MenuItem>
                  {emergencyInfoRelation.map((info, infoid) => {
                    return (
                      <MenuItem key={infoid} value={info.ID}>
                        {info.values.tr()}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6">
              <p className="mb-3 bsa-title-text-step font-14">
                {"SECONDARY_CONTACT_TXT".tr()}
              </p>
              {emergency_name_second.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"FULL_NAME".tr()} ({emergency_name_second.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">{"FULL_NAME".tr()}</p>
              )}
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  type="text"
                  name="bsaFullName"
                  placeholder={"FULL_NAME_CAPTION_TXT".tr()}
                  className="mb-3"
                  inputProps={{ maxLength: 50 }}
                  value={emergency_name_second.value}
                  onChange={(e) =>
                    handleInput("emergency_name_second", e.target.value)
                  }
                />
              </FormControl>
              {emergency_mobileno_second.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"HOME_PHONE_NUMBER_TXT".tr()} (
                  {emergency_mobileno_second.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">
                  {"HOME_PHONE_NUMBER_TXT".tr()}
                </p>
              )}
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  type="text"
                  name="bsaHomePhoneNumber"
                  placeholder={"HOME_PHONE_NUMBER_CAPTION_TXT".tr()}
                  className="mb-3"
                  // inputProps={{ maxLength : 20 }}
                  value={emergency_mobileno_second.value}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/\D/g, "");
                    handleInput("emergency_mobileno_second", newValue);
                  }}
                />
              </FormControl>
              {emergency_relation_id_second.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"RELATIONSHIP_TXT".tr()} (
                  {emergency_relation_id_second.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">
                  {"RELATIONSHIP_TXT".tr()}
                </p>
              )}
              <FormControl fullWidth className="mb-3">
                <Select
                  displayEmpty
                  IconComponent={KeyboardArrowDown}
                  className={
                    emergency_relation_id_second.value === ""
                      ? "bsa-unselect-mui"
                      : ""
                  }
                  value={emergency_relation_id_second.value}
                  onChange={(e) =>
                    handleInput("emergency_relation_id_second", e.target.value)
                  }
                >
                  <MenuItem disabled={true} value={""}>
                    {"RELATIONSHIP_CAPTION_TXT".tr()}
                  </MenuItem>
                  {emergencyInfoRelation.map((info, infoid) => {
                    return (
                      <MenuItem key={infoid} value={info.ID}>
                        {info.values.tr()}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequestStepEmergency;

import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import inputSlice from '../../../../store/inputSlice';
import { postRegularSaRequest } from '../../../../store/inputSlice/service';
import { clearSaRequestFee } from '../../../../store/sarequestSlice';
import userSlice from '../../../../store/userSlice';
import ModalPassword from './modalPassword';
import PartRegularModal from './partRegular';
import PartTermsConditionModal from './partTermsCondition';

function ModalRegular({ selectedAccountId, show, onHide }) {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { loading: inputLoading} = useSelector(inputSlice.selector.all)
    const { flag_information } = useSelector(userSlice.selector.userData)

    const [page, setPage] = useState(1);
    const [showPassModal, setShowPassModal] = useState(false)

    let MovePage;

    const handleConfirm = () => {
        if(!flag_information){
            setShowPassModal(true)
            setPage(1)
            onHide()
        } else {
            navigate('/dashboard/kyc')
        }
    }

    const handleCancelSa = () => {
        setPage(1)
        onHide()
    }

    if(page === 1) {
        MovePage = <PartRegularModal onHide={onHide} setPage={setPage} selectedAccountId={selectedAccountId} />
    }
    else if(page === 2) {
        MovePage = <PartTermsConditionModal handleConfirm={handleConfirm} onHide={handleCancelSa} setPage={setPage} />
    }

    const handleCloseAll = () => {
        dispatch(clearSaRequestFee())
        setShowPassModal(false)
        onHide()
    }

    const postSa = (cb) => {
        dispatch(postRegularSaRequest(cb))
    }

    return (
        <>
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className='p-4'>
                {MovePage}
            </Modal.Body>
        </Modal>
        <ModalPassword action={postSa} loading={inputLoading} show={showPassModal} onHide={handleCloseAll} />
        </>
    )
}

export default ModalRegular
import React, { useState } from 'react'
import { Button, Collapse } from 'react-bootstrap'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function PartFAQModal({ setPage }) {
    const [openOne, setOpenOne] = useState(true);
    const [openTwo, setOpenTwo] = useState(false);
    const [openThree, setOpenThree] = useState(false);
    const [openFour, setOpenFour] = useState(false);
    const [openFive, setOpenFive] = useState(false);
    const [openSix, setOpenSix] = useState(false);
    const [openSev, setOpenSev] = useState(false);

    return (
        <div className='bsa-faq'>
            <div className='bsa-close-modal-btn' align="left">
                <Button variant='light' className='bg-white border-0 p-0' onClick={() => setPage(1)}>
                    <ArrowBackIosNewIcon className='fill-muted' />
                </Button>
            </div>
            <p className='bsa-faq-title mb-3'>Frequently Asked Question</p>
            <div className='bsa-faq-desc'>
                <Button
                    variant='light'
                    onClick={() => setOpenOne(!openOne)}
                    aria-expanded={openOne}
                    className='mb-3 bg-white w-100 d-flex align-items-center justify-content-between'
                >
                    <p className='mb-0 bsa-popup-insurance-title'>{"FAQ_01_TXT".tr()}</p>
                    {openOne ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </Button>
                <Collapse in={openOne}>
                    <p className='bsa-faq-desc-text'>
                        {"FAQ_01_CAPTION_TXT".tr()}
                    </p>
                </Collapse>

                <Button
                    variant='light'
                    onClick={() => setOpenTwo(!openTwo)}
                    aria-expanded={openTwo}
                    className='mb-3 bg-white w-100 d-flex align-items-center justify-content-between'
                >
                    <p className='mb-0 bsa-popup-insurance-title'>
                        {"FAQ_02_TXT".tr()}
                        </p>
                    {openTwo ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </Button>
                <Collapse in={openTwo}>
                    <p className='bsa-faq-desc-text'>
                        {"FAQ_02_CAPTION_TXT".tr()}
                    </p>
                </Collapse>

                <Button
                    variant='light'
                    onClick={() => setOpenThree(!openThree)}
                    aria-expanded={openThree}
                    className='mb-3 bg-white w-100 d-flex align-items-center justify-content-between'
                >
                    <p className='mb-0 bsa-popup-insurance-title'>
                        {"FAQ_03_TXT".tr()}
                        </p>
                    {openThree ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </Button>
                <Collapse in={openThree}>
                    <p className='bsa-faq-desc-text'>
                        {"FAQ_03_CAPTION_TXT".tr()}    
                    </p>
                </Collapse>

                <Button
                    variant='light'
                    onClick={() => setOpenFour(!openFour)}
                    aria-expanded={openFour}
                    className='mb-3 bg-white w-100 d-flex align-items-center justify-content-between'
                >
                    <p className='mb-0 bsa-popup-insurance-title'>
                        {"FAQ_04_TXT".tr()}
                        </p>
                    {openFour ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </Button>
                <Collapse in={openFour}>
                    <p className='bsa-faq-desc-text'>
                        {"FAQ_04_CAPTION_TXT".tr()}
                    </p>
                </Collapse>

                <Button
                    variant='light'
                    onClick={() => setOpenFive(!openFive)}
                    aria-expanded={openFive}
                    className='mb-3 bg-white w-100 d-flex align-items-center justify-content-between'
                >
                    <p className='mb-0 bsa-popup-insurance-title'>
                        {"FAQ_05_TXT".tr()}
                        </p>
                    {openFive ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </Button>
                <Collapse in={openFive}>
                    <p className='bsa-faq-desc-text'>
                        {"FAQ_05_CAPTION_TXT".tr()}
                    </p>
                </Collapse>

                <Button
                    variant='light'
                    onClick={() => setOpenSix(!openSix)}
                    aria-expanded={openSix}
                    className='mb-3 bg-white w-100 d-flex align-items-center justify-content-between'
                >
                    <p className='mb-0 bsa-popup-insurance-title'>
                        {"FAQ_06_TXT".tr()}
                        </p>
                    {openSix ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </Button>
                <Collapse in={openSix}>
                    <p className='bsa-faq-desc-text'>
                        {"FAQ_06_CAPTION_TXT".tr()}
                    </p>
                </Collapse>

                <Button
                    variant='light'
                    onClick={() => setOpenSev(!openSev)}
                    aria-expanded={openSev}
                    className='mb-3 bg-white w-100 d-flex align-items-center justify-content-between'
                >
                    <p className='mb-0 bsa-popup-insurance-title'>
                        {"FAQ_07_TXT".tr()}
                        </p>
                    {openSev ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </Button>
                <Collapse in={openSev}>
                    <p className='bsa-faq-desc-text'>
                        {"FAQ_07_CAPTION_TXT".tr()}
                    </p>
                </Collapse>
            </div>
        </div>
    )
}

export default PartFAQModal
import axios from "axios"
// import Swal from "sweetalert2"
import { addToast } from "./toastSlice"

// export const apiPath = 'https://10.10.29.98:8103'
// export const apiPath = 'https://uat-api.beever-ecosystem.com'
export const apiPath = 'https://api.beever-ecosystem.com'

const baseAxios = axios.create({ 
    baseURL : apiPath, 
    // headers: {
    //     Company: companyCode
    // }
})

const axiosSuccessMiddleware = (response) => {
    const { data } = response
    if(Object.keys(data).includes('code')){
        if(data){
            if(data.code === 0 || data.code === '0'){
                return response
            } else {
                return Promise.reject(data)
            }
        }
    }
    return response
}

const axiosFailedMiddleware = (cb, dispatch, navigate) => {
    return (error) => {
        if(error && error.response && error.response.data){
            switch(error.response.data.code){
                case '1000':
                    break
                default:
                    if(dispatch){
                        dispatch(addToast({ title : 'Error', message: error.response.data.message }))
                    }
                    // Swal.fire('Error 1', error.response.data.message)
            }
        } else {
            switch(error.code){
                case '10001':
                    if(error.message.includes('expire')){
                        localStorage.removeItem('bsa-act')
                        localStorage.removeItem('bsa-info')
                        if(navigate){
                            navigate('/signin', { replace: true })
                        }
                    } else if(error.message === 'JWT signature does not match locally computed signature. JWT validity cannot be asserted and should not be trusted.'){
                        localStorage.removeItem('bsa-act')
                        localStorage.removeItem('bsa-info')
                        if(navigate){
                            navigate('/signin', { replace: true })
                        }
                    }
                    break
                default:
                    if(dispatch){
                        dispatch(addToast({ title : 'Error', message: error.message }))
                    }
            }
        }
        if(cb){
            cb(error)
        }
        return Promise.reject(error);
    }
}

baseAxios.interceptors.response.use(axiosSuccessMiddleware, axiosFailedMiddleware())

export { baseAxios, axiosFailedMiddleware }
import React from 'react'
import { Toast, ToastContainer } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import toastSlice, { removeToast } from '../../../store/toastSlice'

export default function Toaster () {
    const dispatch = useDispatch()
    
    const { toasts } = useSelector(toastSlice.selector.all)

    const handleClose = (timeout, id) => {
        clearTimeout(timeout)
        dispatch(removeToast(id))
    }
    return (
        <ToastContainer className="p-3" containerPosition='fixed' position="top-end">
            {
                toasts.map((toast, ti) => {
                    let timeout = setTimeout(() => dispatch(removeToast(toast.id)), 5000)
                    return <Toast bg='light' key={ti} onClose={() => handleClose(timeout, toast.id)}>
                        <Toast.Body>
                           <strong> {toast.message.toString().tr() ?? 'info'}</strong>
                        </Toast.Body>
                    </Toast>
                })
            }
        </ToastContainer>
    )
}
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  FormControl,
  IconButton,
  OutlinedInput,
  Popover,
  Slider,
} from "@mui/material";
import { Button, Tab, Tabs } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
// import LogoText from "../../../assets/img/beever_exchange.png";
import LogoText from "../../../assets/img/logo_smartek.png";
import LogoPikkat from "../../../assets/img/pikkat_logo.png";
// import LogoWhite from "../../../assets/img/logo_beever_white_landscape.png";
import LogoWhite from "../../../assets/img/logo_smartek_white.png";
import Avatar from "../../../assets/img/avatar_2.png";
import Card from "../../../assets/img/card_account.png";
import CardEmpty from "../../../assets/img/card_empty.png";
import Dollar from "../../../assets/img/dollar.png";
import LanguageSelector from "../../layouts/language";
import userSlice from "../../../store/userSlice";
import { getSAFee } from "../../../store/sarequestSlice/service";
import saRequestSlice, {
  clearSaRequestFee,
} from "../../../store/sarequestSlice";
import { setRegularSaRequest, setSaRequest } from "../../../store/inputSlice";
import LogoutIcon from "@mui/icons-material/Logout";
import StoreIcon from "@mui/icons-material/Store";
import ModalRequest from "./components/modalRequest";
import { signOut } from "../../../store/userSlice/service";
import PersonIcon from "@mui/icons-material/Person";
import ModalProfile from "./components/modalProfile";
import ModalRegular from "./components/modalRegular";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AddIcon from "@mui/icons-material/Add";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import AccountManagement from "./components/accountManagement";
import serviceSlice from "../../../store/serviceSlice";
import { removeAccount } from "../../../store/serviceSlice/service";
import Swal from "sweetalert2";
import ModalHistory from "./components/modalHistory";
import accountNumberHider from "../../../helpers/account";
import { useCurrency } from "../../hooks/currency";
import ModalDetailHistory from "./components/modalDetailHistory";
import ModalMenu from "./components/modalMenu";
import { useNavigate } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function DashboardPageIndex() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openChoose, setOpenChoose] = useState(false);
  const [modalHistory, setModalHistory] = useState(false);
  const [modalDetailHistory, setModalDetailHistory] = useState(false);
  const [detailId, setDetailId] = useState(null);
  const {
    currencyFormatWithPrefix,
    currencyFormatBahtWithPrefix,
    currencyFormatKyatWithPrefix,
    currencyFormatRielWithPrefix,
    currencyFormatKipWithPrefix,
  } = useCurrency();
  const { loading } = useSelector(userSlice.selector.all);
  const { full_name, company_name } = useSelector(userSlice.selector.userInfo);
  const { amount, min_amount, warung_amount } = useSelector(
    userSlice.selector.userData
  );
  const activeAccount = useSelector(serviceSlice.selector.activeAccount);
  const {
    requestFee: { total_fee, loading: loadingSaRequest },
    requestHistory: { data: historyData, loading: loadingSaHistory },
  } = useSelector(saRequestSlice.selector.all);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modalRequest, setModalRequest] = React.useState(false);
  const [modalRegular, setModalRegular] = React.useState(false);
  const [modalProfile, setModalProfile] = React.useState(false);
  const [editingAmount, setEditingAmount] = React.useState(false);
  const [loadingDeleteAccount, setLoadingDeleteAccount] = React.useState(false);
  const [modalBankAccount, setModalBankAccount] = useState(false);
  const [tab, setTab] = useState("sa");
  const { flag_information } = useSelector(userSlice.selector.userData);
  const [values, setValues] = React.useState({
    requestAmount: 0,
    requestAmountDisplay: "",
    receivingAccount: "",
    account_id: 0,
    accountNumber: true,
  });
  const [openTooltip, setOpenTooltip] = React.useState(false);

  const handleTooltipClick = () => {
    setOpenTooltip((prevOpen) => !prevOpen);
  };

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Simple tooltip
    </Tooltip>
  );

  // const ThaiCurrency = (number) => {
  //     return new Intl.NumberFormat('en-TH', {
  //         style: 'currency',
  //         currency: 'THB',
  //         currencyDisplay: "code"
  //     })
  //         .format(number)
  //         .replace("THB", "")
  //         .replace(/(\.+\d{2})/, '')
  //         .trim()
  // }

  const openModalDetail = (referenceid) => {
    setDetailId(referenceid);
    setModalDetailHistory(true);
  };

  const disableSaRequest = () => {
    let disabled = true;
    if (values.requestAmount >= min_amount) {
      disabled = false;
    }
    if (!activeAccount) {
      disabled = true;
    }
    if (!total_fee) {
      disabled = true;
    }
    if (loadingSaRequest) {
      disabled = true;
    }
    if (!flag_information) {
      disabled = true;
    }
    if (values.requestAmount + total_fee > amount) {
      disabled = true;
    }
    return disabled;
  };

  const disableSaRegular = () => {
    let disabled = true;
    if (values.requestAmount >= min_amount) {
      disabled = false;
    }
    if (!activeAccount) {
      disabled = true;
    }
    if (!total_fee) {
      disabled = true;
    }
    if (loadingSaRequest) {
      disabled = true;
    }
    return disabled;
  };

  const handleRupiah = (event) => {
    setValues({
      ...values,
      requestAmount: event.target.value,
      requestAmountDisplay: currencyFormatWithPrefix(
        Number(event.target.value)
      ),
    });
  };

  const handleChange = (event) => {
    if (event.target.value && event.target.value <= amount) {
      setValues({
        ...values,
        [event.target.name]: Number(event.target.value),
        requestAmountDisplay: currencyFormatWithPrefix(
          Number(event.target.value)
        ),
      });
    } else {
      setValues({
        ...values,
        [event.target.name]: 0,
        requestAmountDisplay: "",
      });
    }
  };

  // function numFormatter(num) {
  //   if (num > 999 && num < 1000000) {
  //     return "THB " + ((num * 0.002292) / 1000).toFixed(0) + " K";
  //   } else if (num >= 1000000) {
  //     return "THB " + ((num * 0.002292) / 1000000).toFixed(0) + " M";
  //   } else if (num < 900) {
  //     return "THB " + num;
  //   }
  // }

  // IDR FORMAT
  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return "IDR " + (num / 1000).toFixed(0) + " K";
    } else if (num >= 1000000) {
      return "IDR " + (num / 1000000).toFixed(0) + " M";
    } else if (num < 900) {
      return "IDR " + num;
    }
  }

  const handleHideChoose = () => {
    localStorage.removeItem("bsa-ask");
    setOpenChoose(false);
    if (!flag_information) {
      navigate("/dashboard/kyc");
    }
  };

  const handleClickAccount = () => {
    setValues({
      ...values,
      accountNumber: !values.accountNumber,
    });
  };

  const handleMouseDownAccount = (event) => {
    event.preventDefault();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteAccount = () => {
    dispatch(removeAccount(activeAccount.account_id, setLoadingDeleteAccount));
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-primary btn-lg me-2",
      cancelButton: "btn btn-secondary btn-lg",
    },
    buttonsStyling: false,
  });

  const InfoTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#00a3b4",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#00a3b4",
    },
  }));

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  React.useEffect(() => {
    if (open) {
      document.body.classList.remove("bsa-popover-setclose");
    } else {
      document.body.classList.add("bsa-popover-setclose");
    }

    if (localStorage.getItem("bsa-ask")) {
      setOpenChoose(true);
    }
  }, [open]);

  if (loading) {
    return <></>;
  }

  return (
    <>
      <ModalMenu show={openChoose} onHide={handleHideChoose} />
      <div className="bsa-dashboard-content-route-cover">
        <div className="row mb-4">
          <div className="col-5 col-md-6">
            {/* Desktop View */}
            <img
              src={LogoText}
              alt={"Smartek"}
              width={180}
              className="d-none d-md-block"
            />
            {/* Mobile View */}
            <img
              src={LogoWhite}
              alt={"Smartek"}
              width={180}
              // width={140}
              className="d-block d-md-none"
            />
          </div>
          <div className="col-7 col-md-6" align="right">
            <ModalProfile
              show={modalProfile}
              onHide={() => {
                setModalProfile(false);
              }}
              onLogout={() => dispatch(signOut())}
            />
            <div className="bsa-header d-none d-md-flex">
              <a
                href="https://t.me/Pikkat_bot"
                rel="noreferrer"
                target="__blank"
              >
                <Button className="smrtk-btn-shop">
                  <StoreIcon fontSize="small" className="me-1" />
                  {"BTN_SHOP_TXT".tr()}
                </Button>
              </a>
              <LanguageSelector />
              <div className="bsa-profile" onClick={handleClick}>
                {"WELCOME_BACK_TXT".tr()},&nbsp;<strong>{full_name}</strong>{" "}
                <img src={Avatar} alt={"Avatar"} />
              </div>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                elevation={1}
                className="bsa-popover"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <div className="bsa-popover-account pt-2 pb-2">
                  <Button
                    onClick={() => {
                      setModalProfile(true);
                      setAnchorEl(null);
                    }}
                    variant="link"
                    className="w-100"
                  >
                    <PersonIcon fontSize="small" className="me-2" /> {"Profile"}
                  </Button>
                  <Button
                    onClick={() => dispatch(signOut())}
                    variant="link"
                    className="w-100"
                  >
                    <LogoutIcon fontSize="small" className="me-2" />{" "}
                    {"Logout".tr()}
                  </Button>
                </div>
              </Popover>
            </div>
            <div className="bsa-headermob d-block d-md-none">
              <div className="bsa-profile" onClick={handleClick}>
                {"WELCOME_BACK_TXT".tr()},&nbsp;<strong>{full_name}</strong>{" "}
                <img src={Avatar} alt={"Avatar"} />
              </div>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                elevation={1}
                className="bsa-popover"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <div className="bsa-popover-account pt-2 pb-2">
                  <Button
                    onClick={() => {
                      setModalProfile(true);
                      setAnchorEl(null);
                    }}
                    variant="link"
                    className="w-100"
                  >
                    <PersonIcon fontSize="small" className="me-2" />{" "}
                    {"MENU_HOME_PROFILE_TXT".tr()}
                  </Button>
                  <Button
                    onClick={() => dispatch(signOut())}
                    variant="link"
                    className="w-100"
                  >
                    <LogoutIcon fontSize="small" className="me-2" />{" "}
                    {"Logout".tr()}
                  </Button>
                </div>
              </Popover>
              <LanguageSelector />
              <a
                href="https://t.me/Pikkat_bot"
                rel="noreferrer"
                target="__blank"
              >
                <Button className="smrtk-btn-shop">
                  <StoreIcon fontSize="small" className="me-1" />
                  {"BTN_SHOP_TXT".tr()}
                </Button>
              </a>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12 col-md-5 spacing-card">
            <div className="card border-0 border-radius-10 h-100">
              <div className="card-body ps-4 pe-4 pt-3 pb-3">
                <div className="d-flex smrtk-bill">
                  <div className="w-full">
                    <p className="fw-semibold">{"HEADER_SA_AMOUNT_TXT".tr()}</p>
                    <h3 className="text-primary fw-bolder">
                      {currencyFormatWithPrefix(Number(amount))}
                    </h3>
                  </div>
                  <div className="w-full smrtk-bill-shop">
                    <div className="smrtk-bill-shop-head">
                      <InfoTooltip
                        onClose={handleTooltipClose}
                        open={openTooltip}
                        title={"DETAIL_SHOP_PLAFOND".tr()}
                      >
                        <InfoOutlinedIcon
                          className="smrtk-bill-shop-tooltip-mob"
                          onClick={handleTooltipClick}
                        />
                      </InfoTooltip>
                      <InfoTooltip title={"DETAIL_SHOP_PLAFOND".tr()}>
                        <InfoOutlinedIcon className="smrtk-bill-shop-tooltip-desk" />
                      </InfoTooltip>

                      <p className="fw-semibold">
                        {"HEADER_SHOP_AMOUNT_TXT".tr()}
                      </p>
                    </div>
                    <h3 className="text-primary fw-bolder">
                      {currencyFormatWithPrefix(Number(warung_amount))}
                    </h3>
                  </div>
                </div>
                <p className="bsa-text-label-form mb-2 mt-4">
                  {"SA_REQUEST_AMOUNT_TXT".tr()}
                </p>
                <FormControl variant="outlined" fullWidth>
                  <OutlinedInput
                    value={
                      editingAmount
                        ? values.requestAmount
                        : values.requestAmountDisplay
                    }
                    type="text"
                    onChange={handleChange}
                    name="requestAmount"
                    placeholder={"SA_REQUEST_AMOUNT_TXT".tr()}
                    className="mb-3 bsa-field-request-text"
                    onFocus={() => {
                      setEditingAmount(true);
                    }}
                    onBlur={() => {
                      setEditingAmount(false);
                      if (values.requestAmount >= min_amount) {
                        dispatch(getSAFee(values.requestAmount));
                        dispatch(
                          setSaRequest({
                            name: "request_amt",
                            value: values.requestAmount,
                          })
                        );
                        dispatch(
                          setRegularSaRequest({
                            name: "request_amount",
                            value: values.requestAmount,
                          })
                        );
                      } else {
                        dispatch(clearSaRequestFee());
                      }
                    }}
                  />
                </FormControl>
                {amount ? (
                  <Slider
                    min={Math.ceil(amount / 10)}
                    max={amount}
                    marks
                    value={values.requestAmount}
                    step={Math.ceil(amount / 10)}
                    valueLabelDisplay="auto"
                    valueLabelFormat={numFormatter}
                    onChange={handleRupiah}
                    className="bsa-slider-primary"
                    onChangeCommitted={() => {
                      if (amount) {
                        dispatch(getSAFee(values.requestAmount));
                        dispatch(
                          setSaRequest({
                            name: "request_amt",
                            value: values.requestAmount,
                          })
                        );
                        dispatch(
                          setRegularSaRequest({
                            name: "request_amount",
                            value: values.requestAmount,
                          })
                        );
                      }
                    }}
                  />
                ) : (
                  <></>
                )}
                <div className="d-flex align-items-center justify-content-between mb-3 w-100">
                  <p className="bsa-text-label-formleft mb-0">
                    {"SA_TOTAL_FEE_TXT".tr()}
                  </p>
                  <p className="bsa-text-label-formright mb-0 text-default fw-bolder">
                    {Number(total_fee)
                      ? currencyFormatWithPrefix(Number(total_fee))
                      : "IDR 0"}
                  </p>
                </div>
                <div className="row mb-2">
                  <div className="col-12 col-md-12 spacing-mob">
                    <ModalRequest
                      selectedAccountId={activeAccount?.account_id}
                      show={modalRequest}
                      onHide={() => setModalRequest(false)}
                    />
                    <Button
                      onClick={() => setModalRequest(true)}
                      variant={disableSaRequest() ? "secondary" : "primary"}
                      disabled={disableSaRequest()}
                      className="w-100 pt-2 pb-2"
                    >
                      {"SA_REQUEST_TXT".tr()}
                    </Button>
                  </div>
                  {/* <div className="col-12 col-md-6">
                    <ModalRegular
                      selectedAccountId={activeAccount?.account_id}
                      show={modalRegular}
                      onHide={() => setModalRegular(false)}
                    />
                    <Button
                      onClick={() => setModalRegular(true)}
                      variant={
                        disableSaRegular() ? "secondary" : "outline-primary"
                      }
                      disabled={disableSaRegular()}
                      className="w-100 pt-2 pb-2"
                    >
                      {"SA_REGULAR_TOPUP_TXT".tr()}
                    </Button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 spacing-card">
            <div className="card border-0 border-radius-10 h-100">
              <div className="card-body ps-4 pe-4 pt-3 pb-3">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-0 fw-semibold">
                    {"SA_RECEIVE_ACC_TXT".tr()}
                  </p>
                </div>
                <div className="bsa-account-bank mb-4 mt-3">
                  {activeAccount ? (
                    <>
                      <img src={Card} alt="Card" width={"100%"} />
                      <p className="bsa-account-bank-account">
                        {activeAccount.bank_name ? activeAccount.bank_name : ""}
                      </p>
                      <p className="bsa-account-bank-number">
                        {!values.accountNumber
                          ? activeAccount?.emp_acc_number
                          : accountNumberHider(activeAccount?.emp_acc_number)}
                      </p>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickAccount}
                        onMouseDown={handleMouseDownAccount}
                        className="bsa-account-bank-button"
                      >
                        {values.accountNumber ? (
                          <VisibilityOff className="fill-white" />
                        ) : (
                          <Visibility className="fill-white" />
                        )}
                      </IconButton>
                    </>
                  ) : (
                    <Button
                      variant="light"
                      className="bsa-account-bank-warning p-0"
                      //   onClick={() => setModalBankAccount(true)}
                    >
                      <div className="bsa-account-bank-warning-overlay"></div>
                      <img src={CardEmpty} alt="Card" width={"100%"} />
                      <div className="bsa-account-bank-warning-text">
                        <GppMaybeIcon
                          className="fill-white mb-3"
                          fontSize="large"
                        />
                        <p className="mb-0">
                          {"DB_PLEASE_ADD".tr()}
                          <br />
                          {"DB_BEFORE_YOU".tr()}
                        </p>
                      </div>
                    </Button>
                  )}
                </div>
                {activeAccount ? (
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="">
                      <p className="mb-0 font-11 text-start">
                        {"DISBURSE_ACC_NAME_TXT".tr()}
                      </p>
                      <p className="fw-bold font-13 mb-1 text-start">
                        {activeAccount.emp_acc_name
                          ? activeAccount.emp_acc_name
                          : "Your Name"}
                      </p>
                    </div>
                    <div className="">
                      <p className="mb-0 font-11 text-end">
                        {"COMPANY_TXT".tr()}
                      </p>
                      <p className="fw-bold font-12 mb-1 text-end">
                        {company_name ? company_name : "Your Company"}
                      </p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {company_name === "PT Berlian Amal Perkasa" ? (
                  <></>
                ) : activeAccount ? (
                  <div className="row mb-2">
                    <div className="col-12 col-md-12">
                      <Button
                        onClick={() =>
                          swalWithBootstrapButtons
                            .fire({
                              title: "ADD_CC_CAPTION_TXT".tr(),
                              icon: "warning",
                              confirmButtonText:
                                "CONFIRM_DELETE_ACCOUNT_TXT".tr(),
                              cancelButtonText: "CANCEL_TXT".tr(),
                              showCancelButton: true,
                            })
                            .then((willDelete) => {
                              if (willDelete.isConfirmed) {
                                deleteAccount();

                                swalWithBootstrapButtons.fire({
                                  title: "ADD_CC_CAPTION_SUCCESS_TXT".tr(),
                                  confirmButtonText: "OK_TXT".tr(),
                                  icon: "success",
                                });
                              }
                            })
                        }
                        variant={activeAccount ? "info" : "secondary"}
                        disabled={loadingDeleteAccount}
                        className="w-100 pt-2 pb-2 btn-soft-primary"
                        startIcon={<AddIcon />}
                      >
                        {"DELETE_TXT".tr()}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <AccountManagement
            modalBankAccount={modalBankAccount}
            setModalBankAccount={setModalBankAccount}
          />
        </div>
        <Tabs
          defaultActiveKey="profile"
          id="uncontrolled-tab-example"
          className="smrtk-tabs-table-home mt-4"
        >
          <Tab
            eventKey="home"
            title={"TRANSACTION_SA_TXT".tr()}
            onClick={() => setTab("sa")}
          >
            <div className="row">
              <div className="col-12 col-md-12 spacing-card">
                <div className="card border-0 border-radius-10 h-100">
                  <div className="card-body ps-4 pe-4 pt-3 pb-3">
                    <div className="row mb-3">
                      <div className="col-12">
                        <p className="fw-semibold font-14 d-none d-md-block">
                          {"LATEST_TRANSACTION_TXT".tr()}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-md-5">
                            <div className="row">
                              <div className="col-md-6">
                                {/* Desktop View */}
                                <p className="fw-bolder font-13 d-none d-md-block">
                                  {"SA_NUMBER_TXT".tr()}
                                </p>
                                {/* Mobile View */}
                                <p className="fw-bolder font-13 d-block d-md-none">
                                  {"SA_NUMBER_TXT".tr()}
                                </p>
                              </div>
                              <div className="col-md-6 d-none d-md-block">
                                <p className="fw-bolder font-13">
                                  {"SA_RECEIVE_ACC_TXT".tr()}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 d-none d-md-block">
                            <div className="row">
                              <div className="col-md-6 d-none d-md-block">
                                <p className="fw-bolder font-13">
                                  {"SA_DISBURSMENT_DATE_TXT".tr()}
                                </p>
                              </div>
                              <div className="col-md-6 d-none d-md-block">
                                <p className="fw-bolder font-13">
                                  {"DISBURSE_AMOUNT_TXT".tr()}
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-md-3 d-none d-md-block">
                        <div className="row">
                          <div className="col-md-6">
                            <p className="fw-bolder font-13">
                              {"AGREEMENT_TXT".tr()}
                            </p>
                          </div>
                          <div className="col-md-6 text-center">
                            <p className="fw-bolder font-13">
                              {"AGREEMENT_DOC_TXT".tr()}
                            </p>
                          </div>
                        </div>
                      </div> */}
                          <div className="col-md-1 d-none d-md-block">
                            <p className="fw-bolder font-13">
                              {"STATUS_TXT".tr()}
                            </p>
                          </div>
                          <div className="col-md-2 d-none d-md-block">
                            <p className="fw-bolder font-13">
                              {"REASON_TXT".tr()}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ModalDetailHistory
                      detailId={detailId}
                      show={modalDetailHistory}
                      onHide={() => setModalDetailHistory(false)}
                    />

                    {loadingSaHistory ? (
                      <div className="bsa-column-table">
                        <div className="row">
                          <div className="col-12 bsa-tabs-box justify-content-center pt-4 pb-4">
                            <p className="mb-0 font-13">Please wait ...</p>
                          </div>
                        </div>
                      </div>
                    ) : historyData.filter((data) => data.productid !== "03")
                        .length ? (
                      historyData
                        .filter((data) => data.productid !== "03")
                        .slice(0, 5)
                        .map((data, hid) => {
                          const {
                            createtime,
                            loanamount,
                            orderstatus,
                            orderstatus_desc,
                            sa_number,
                            bank_acc_no,
                            bank_acc_name,
                            disburse_date,
                          } = data;
                          // const openDetail = () => {
                          //     setDetailId(referenceid)
                          //     setModalDetailHistory(true)
                          // }
                          return (
                            <div key={hid} className="bsa-column-table">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="row">
                                    <div className="col-12 col-md-5">
                                      <div className="row">
                                        {/* Desktop View */}
                                        <div className="col-md-6 d-none d-md-flex">
                                          <BootstrapTooltip
                                            title={sa_number}
                                            placement="top"
                                          >
                                            <p className="mb-0 font-12 bsa-has-overtext-data text-primary fw-bold">
                                              {sa_number}
                                            </p>
                                          </BootstrapTooltip>
                                        </div>
                                        {/* Mobile View */}
                                        <div className="col-12 d-flex d-md-none">
                                          <img
                                            src={Dollar}
                                            alt="Dollar"
                                            width={25}
                                          />
                                          <p className="mb-0 ms-3 font-12 bsa-has-overtext-data">
                                            {sa_number}
                                          </p>
                                        </div>
                                        <div className="col-md-6 d-none d-md-flex">
                                          <p className="fw-semibold font-12 mb-0">
                                            {bank_acc_name}
                                            <br />
                                            {bank_acc_no}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-12 col-md-4 d-none d-md-block">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <p className="mb-0 me-3 font-12">
                                            {disburse_date ?? "-"}
                                          </p>
                                        </div>
                                        <div className="col-md-6 font-12">
                                          <p className="fw-semibold mb-0">
                                            {currencyFormatWithPrefix(
                                              Number(loanamount)
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div className="col-md-3 d-none d-md-block">
                                <div className="row">
                                  <div className="col-md-6">
                                    <p className="fw-semibold font-12 mb-0">
                                      {createtime.slice(0, -4)}
                                    </p>
                                  </div>
                                  <div className="col-md-6 text-center">
                                    <a
                                      href={
                                        "https://beever-oss.oss-ap-southeast-5.aliyuncs.com/Online%20service%20agreement%20for%20NON%20P2P%20and%20Thailand.pdf"
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                      className="btn btn-link font-11 p-0"
                                    >
                                      View PDF
                                    </a>
                                  </div>
                                </div>
                              </div> */}
                                    <div className="col-md-1 d-none d-md-block">
                                      <p className="fw-semibold font-12 mb-0">
                                        {orderstatus}
                                      </p>
                                    </div>
                                    <div className="col-md-2 d-none d-md-block">
                                      <p className="fw-semibold font-12 mb-0">
                                        {orderstatus_desc ?? "-"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                    ) : (
                      <div className="bsa-column-table">
                        <div className="row">
                          <div className="col-12">
                            <div className="bsa-tabs-box justify-content-center pt-4 pb-4">
                              <p className="mb-0 font-12">
                                {"DB_NO_TRANSACTION".tr()}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <p className="text-center mt-3">
                      <ModalHistory
                        openModalDetail={openModalDetail}
                        show={modalHistory}
                        isShop={tab === "shop"}
                        onHide={() => setModalHistory(false)}
                      />
                      <Button
                        onClick={() => setModalHistory(true)}
                        variant="link"
                        className="btn-link font-12"
                      >
                        {"DB_SEE_MORE".tr()}
                      </Button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab
            eventKey="profile"
            title={"TRANSACTION_SHOPPING_TXT".tr()}
            onClick={() => setTab("shop")}
          >
            <div className="row">
              <div className="col-12 col-md-12 spacing-card">
                <div className="card border-0 border-radius-10 h-100">
                  <div className="card-body ps-4 pe-4 pt-3 pb-3">
                    <div className="row mb-3">
                      <div className="col-12">
                        <p className="fw-semibold font-14 d-none d-md-block">
                          {"LATEST_TRANSACTION_TXT".tr()}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-md-5">
                            <div className="row">
                              <div className="col-md-6">
                                {/* Desktop View */}
                                <p className="fw-bolder font-13 d-none d-md-block">
                                  {"SA_NUMBER_TXT".tr()}
                                </p>
                                {/* Mobile View */}
                                <p className="fw-bolder font-13 d-block d-md-none">
                                  {"SA_NUMBER_TXT".tr()}
                                </p>
                              </div>
                              <div className="col-md-6 d-none d-md-block">
                                <p className="fw-bolder font-13">
                                  {"SA_RECEIVE_ACC_TXT".tr()}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 d-none d-md-block">
                            <div className="row">
                              <div className="col-md-6 d-none d-md-block">
                                <p className="fw-bolder font-13">
                                  {"SA_DISBURSMENT_DATE_TXT".tr()}
                                </p>
                              </div>
                              <div className="col-md-6 d-none d-md-block">
                                <p className="fw-bolder font-13">
                                  {"DISBURSE_AMOUNT_TXT".tr()}
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-md-3 d-none d-md-block">
                        <div className="row">
                          <div className="col-md-6">
                            <p className="fw-bolder font-13">
                              {"AGREEMENT_TXT".tr()}
                            </p>
                          </div>
                          <div className="col-md-6 text-center">
                            <p className="fw-bolder font-13">
                              {"AGREEMENT_DOC_TXT".tr()}
                            </p>
                          </div>
                        </div>
                      </div> */}
                          <div className="col-md-1 d-none d-md-block">
                            <p className="fw-bolder font-13">
                              {"STATUS_TXT".tr()}
                            </p>
                          </div>
                          <div className="col-md-2 d-none d-md-block">
                            <p className="fw-bolder font-13">
                              {"REASON_TXT".tr()}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ModalDetailHistory
                      detailId={detailId}
                      show={modalDetailHistory}
                      onHide={() => setModalDetailHistory(false)}
                    />

                    {loadingSaHistory ? (
                      <div className="bsa-column-table">
                        <div className="row">
                          <div className="col-12 bsa-tabs-box justify-content-center pt-4 pb-4">
                            <p className="mb-0 font-13">Please wait ...</p>
                          </div>
                        </div>
                      </div>
                    ) : historyData.filter((data) => data.productid === "03")
                        .length ? (
                      historyData
                        .filter((data) => data.productid === "03")
                        .slice(0, 5)
                        .map((data, hid) => {
                          const {
                            createtime,
                            loanamount,
                            orderstatus,
                            orderstatus_desc,
                            sa_number,
                            bank_acc_no,
                            bank_acc_name,
                            disburse_date,
                          } = data;
                          // const openDetail = () => {
                          //     setDetailId(referenceid)
                          //     setModalDetailHistory(true)
                          // }
                          return (
                            <div key={hid} className="bsa-column-table">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="row">
                                    <div className="col-12 col-md-5">
                                      <div className="row">
                                        {/* Desktop View */}
                                        <div className="col-md-6 d-none d-md-flex">
                                          <BootstrapTooltip
                                            title={sa_number}
                                            placement="top"
                                          >
                                            <p className="mb-0 font-12 bsa-has-overtext-data text-primary fw-bold">
                                              {sa_number}
                                            </p>
                                          </BootstrapTooltip>
                                        </div>
                                        {/* Mobile View */}
                                        <div className="col-12 d-flex d-md-none">
                                          <img
                                            src={Dollar}
                                            alt="Dollar"
                                            width={25}
                                          />
                                          <p className="mb-0 ms-3 font-12 bsa-has-overtext-data">
                                            {sa_number}
                                          </p>
                                        </div>
                                        <div className="col-md-6 d-none d-md-flex">
                                          <p className="fw-semibold font-12 mb-0">
                                            {bank_acc_name}
                                            <br />
                                            {bank_acc_no}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-12 col-md-4 d-none d-md-block">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <p className="mb-0 me-3 font-12">
                                            {disburse_date ?? "-"}
                                          </p>
                                        </div>
                                        <div className="col-md-6 font-12">
                                          <p className="fw-semibold mb-0">
                                            {currencyFormatWithPrefix(
                                              Number(loanamount)
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div className="col-md-3 d-none d-md-block">
                                <div className="row">
                                  <div className="col-md-6">
                                    <p className="fw-semibold font-12 mb-0">
                                      {createtime.slice(0, -4)}
                                    </p>
                                  </div>
                                  <div className="col-md-6 text-center">
                                    <a
                                      href={
                                        "https://beever-oss.oss-ap-southeast-5.aliyuncs.com/Online%20service%20agreement%20for%20NON%20P2P%20and%20Thailand.pdf"
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                      className="btn btn-link font-11 p-0"
                                    >
                                      View PDF
                                    </a>
                                  </div>
                                </div>
                              </div> */}
                                    <div className="col-md-1 d-none d-md-block">
                                      <p className="fw-semibold font-12 mb-0">
                                        {orderstatus}
                                      </p>
                                    </div>
                                    <div className="col-md-2 d-none d-md-block">
                                      <p className="fw-semibold font-12 mb-0">
                                        {orderstatus_desc ?? "-"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                    ) : (
                      <div className="bsa-column-table">
                        <div className="row">
                          <div className="col-12">
                            <div className="bsa-tabs-box justify-content-center pt-4 pb-4">
                              <p className="mb-0 font-12">
                                {"DB_NO_TRANSACTION".tr()}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <p className="text-center mt-3">
                      <ModalHistory
                        openModalDetail={openModalDetail}
                        isShop={tab === "shop"}
                        show={modalHistory}
                        onHide={() => setModalHistory(false)}
                      />
                      <Button
                        onClick={() => setModalHistory(true)}
                        variant="link"
                        className="btn-link font-12"
                      >
                        {"DB_SEE_MORE".tr()}
                      </Button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default DashboardPageIndex;

import { createSlice } from "@reduxjs/toolkit"

const serviceSlice = createSlice({
  name: 'serviceStore',
  initialState: {
    partners: [],
    activeAccount: null,
    bank: {
      bank: [],
      emoney: []
    },
    data: {
      jobIndustry: [],
      emergencyInfoRelation: [],
      education: [],
      residentalInfoStatus: [],
      gender: [],
      province: [],
      jobType: [],
      maritalStatus: [],
      religion: [],
      jobPosition: [],
    },
    loading : true
  },
  reducers: {
    setPartners: (state, action) => {
      state.partners = action.payload
      state.loading = false
    },
    setActiveAccount:(state, action) => {
      if(action.payload){
        localStorage.setItem('bsa-pai', action.payload.account_id)
      }
      state.activeAccount = action.payload
    },
    setBank: (state, action) => {
      state.bank[action.payload.type] = action.payload.data // payload : { type, data }
    },
    setData: (state, action) => {
      state.data = action.payload
      state.loading = false
    },
    setLoading: (state, action) => {
      state.loading = !!action.payload
    }
  },
})

export const {
  setPartners, setData, setLoading, setBank, setActiveAccount
} = serviceSlice.actions

serviceSlice.selector = {
  all: (state) => state[serviceSlice.name],
  partners: (state) => state[serviceSlice.name].partners,
  data: (state) => state[serviceSlice.name].data,
  bank: (state) => state[serviceSlice.name].bank,
  activeAccount: (state) => state[serviceSlice.name].activeAccount,
}

export default serviceSlice
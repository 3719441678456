import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import Sidebars from '../layouts/sidebars';
import DashboardPageIndex from './pages/indexDashboard';
// import RegularPageIndex from './pages/indexRegular';
import RequestPageIndex from './pages/indexRequest';

function Dashboard() {

    const routes = [
        { url: "/", element: <DashboardPageIndex /> },
        { url: "/kyc", element: <RequestPageIndex /> },
        { url: "*", element: <DashboardPageIndex /> },
        // { url: "/regular", element: <RegularPageIndex /> },
    ];

    // useEffect

    return (
        <div className='bsa-dashboard'>
            <div className='bsa-dashboard-content'>
                <div className="bsa-dashboard-content-sidebar">
                    <Sidebars />
                </div>

                <div className="bsa-dashboard-content-route">
                    <Routes>
                        {routes.map((data, i) => (
                            <Route key={i} path={`${data.url}`} element={data.element} />
                        ))}
                    </Routes>
                </div>
            </div>
        </div>
    )
}

export default Dashboard
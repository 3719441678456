import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLanguage } from ".";

export function useTranslation() {

  const dispatch = useDispatch()
  
  const { active, translations, languages } = useSelector((state) => state.languageStore)

  const translate = (key) => {
    return translations[key] && translations[key][active] ? translations[key][active] : key
  }

  const changeLanguage = (name) => {
    const find = languages.find((lang) => lang.name === name)
    if(find){
      dispatch(setLanguage(find.value))
      localStorage.setItem('bsa-lang', find.value)
    } else {
      console.error('Invalid language name supllied, available options:', languages.map((lang) => lang.name)).join(', ')
    }
  }

  const activeName = () => {
    let find = languages.find((language) => language.value === active)
    if(find){
      return find.name
    } else {
      // return 'English'
      return 'Indonesia'
    }
  }

  const init = () => {
    // eslint-disable-next-line
    Object.defineProperty(String.prototype, "tr", {
      value: function tr() {
        return translate(this)
      },
      configurable: true,
      enumerable: false,
      writeable: true
    });
  }

  useEffect(() => {
    if(localStorage.getItem('bsa-lang')){
      dispatch(setLanguage(localStorage.getItem('bsa-lang')))
    }
  },[dispatch])

  return  { translate, changeLanguage, init, languages, active, activeName }
}

import React from "react";
import { CloseButton, Modal, Button } from "react-bootstrap";
import LogoPikkat from "../../../../assets/img/logo_pikkatsmartek.png";
import LogoSmartek from "../../../../assets/img/logo_smartek.png";
import LogoSmartek2 from "../../../../assets/img/logo_2.png";

function ModalMenu({ show, onHide }) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size={"lg"}
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="smrtk-options-modal"
    >
      <Modal.Body className="p-4">
        {/* <div className="bsa-close-modal-btn" align="right">
          <CloseButton onClick={onHide} />
        </div> */}

        <h1>{"CHOOSE_OPT".tr()}</h1>

        <div className="smrtk-options">
          <div className="smrtk-option-smrtk smrtk" onClick={onHide}>
            {/* <img src={LogoSmartek} alt="Smartek Logo"  className="mobile"/> */}
            <img src={LogoSmartek2} alt="Smartek Logo" />
            <h2>Salary Advance</h2>
          </div>
          <div className="smrtk-option-smrtk pkt">
            <a href="https://t.me/Pikkat_bot" rel="noreferrer" target="__blank">
              <img src={LogoPikkat} alt="Pikkat Logo" />
              <h2>Belanja di Warung</h2>
            </a>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalMenu;

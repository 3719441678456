import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import LogoSmartek from '../../../../assets/img/logo_2.png'
// import LogoBeever from '../../../../assets/img/logo.png'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { deactivateRegularSa, getRegulerRequestDetail } from '../../../../store/sarequestSlice/service';
import { useCurrency } from '../../../hooks/currency';

function PartDetailHistory({ setPage, detailId }) {

    const dispatch = useDispatch()
    const { currencyFormatWithPrefix, currencyFormatBahtWithPrefix, dateFormat } = useCurrency()
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(detailId){
            dispatch(getRegulerRequestDetail(detailId, (data, err) => {
                if(err){
                    setPage(1)
                } else {
                    setLoading(false)
                    setData(data)
                }
            }))
        }
    },[detailId, dispatch, setPage])

    const stopRegularSa = (saId) => {
        dispatch(deactivateRegularSa(saId, (data, err) => {
            if(err) {
                return false
            }
            if(data){
                setPage(1)
            }
        }))
    }

    if(loading){
        return <div className='d-flex justify-content-center'><CircularProgress /></div>
    }

    return (
        <div className='bsa-history'>
            <div className='bsa-close-modal-btn' align="left">
                <Button variant='light' className='bg-white border-0 p-0' onClick={() => setPage(1)}>
                    <ArrowBackIosNewIcon className='fill-muted' />
                </Button>
            </div>
            <p className='bsa-history-title mb-3'>Detail Transaction</p>
            <div className='bsa-history-box mb-3'>
                <div className='bsa-history-box-card'>
                    <div className='bsa-history-box-card-head'>
                        <div className='d-flex align-items-center'>
                            <img src={LogoSmartek} alt={"Smartek"} width={40} />
                            <div className='ms-3'>
                                <p className='mb-0 bsa-history-box-card-second'>Salary Advance</p>
                                <p className='mb-0 bsa-history-box-card-first'>{dateFormat(data.created_date)}</p>
                            </div>
                        </div>
                        {
                            data.status === 'active' ?
                            <Button variant='danger' onClick={() => stopRegularSa(data.regular_sys_sa_id)}>STOP</Button>
                            :
                                <></>
                        }
                    </div>
                    <div className='bsa-history-box-card-content'>
                        <p className='mb-0 bsa-history-box-card-first'>Total Receiving</p>
                        <p className='mb-0 bsa-history-box-card-second'>{currencyFormatWithPrefix(Number(data.total_receiving))}</p>
                    </div>
                </div>
            </div>
            <div className='bsa-history-detail p-3'>
                <div className='d-flex align-items-center justify-content-between'>
                    <p className='bsa-history-detail-left'>Receiving Account</p>
                    <div>
                        <p className='mb-0 bsa-history-detail-right'>{data.emp_acc_number}</p>
                        <p className='bsa-history-detail-right'>{data.bank_name}</p>
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                    <p className='bsa-history-detail-left'>Reguler Top Up</p>
                    <p className='bsa-history-detail-right'>Salary Advance</p>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                    <p className='bsa-history-detail-left'>Disbursement Date</p>
                    <p className='bsa-history-detail-right'>{data.disburse_date} of every month</p>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                    <p className='bsa-history-detail-left'>Your Salary Advance Amount</p>
                    <p className='bsa-history-detail-right'>{currencyFormatWithPrefix(Number(data.request_amount))}</p>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                    <p className='mb-0 bsa-history-detail-left'>Transfer Fee</p>
                    <p className='mb-0 bsa-history-detail-right'>{currencyFormatWithPrefix(Number(data.fee_amount))}</p>
                </div>
                <hr />
                <div className='d-flex align-items-center justify-content-between'>
                    <p className='mb-0 bsa-history-detail-last-left'>Total Receiving</p>
                    <p className='mb-0 bsa-history-detail-last-right'>{currencyFormatWithPrefix(Number(data.total_receiving))}</p>
                </div>
            </div>
        </div>
    )
}

export default PartDetailHistory
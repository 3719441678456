// Need to use the React-specific entry point to allow generating React hooks
import { setBank, setData, setLoading, setPartners } from '.';
import { axiosFailedMiddleware, baseAxios } from '../configs'
import { addToast } from '../toastSlice';
import { getUserData } from '../userSlice/service';


export const loadServices = () => {
    return (dispatch, getState) => {
        try {
            let authorization = 'Bearer '+ getState().userStore.authorization
            return Promise.all([
                baseAxios.post('/v2/api/getlistcompany', null, {
                    headers: {
                        Authorization: authorization,
                        Company: getState().userStore.company_code
                    }
                }),
                baseAxios.get('/v2/api/info/bank/all', {
                    headers: {
                        Authorization: authorization,
                        Company: getState().userStore.company_code
                    }
                })
            ]).then((response) => {
                const listData = response[0].data
                const bankData = response[1].data
                dispatch(setPartners(listData.partners))
                dispatch(setData(listData.data))
                dispatch(setBank({ type: 'emoney', data: bankData.emoney}))
                dispatch(setBank({ type: 'bank', data: bankData.bank}))
                
            })
            .catch(axiosFailedMiddleware(() => {}, dispatch))
        } catch(err) {
            // console.log(err.message)
        } finally {
            dispatch(setLoading(false))
        }
    };
}

export const addAccount = ({bank_code, bank_name, emp_acc_name, emp_acc_number}, setSubmitLoading) => {
    return (dispatch, getState) => {
        try {
            setSubmitLoading(true)
            return baseAxios.post('/v2/api/info/account/add', {
                employee_id : getState().userStore.userInfo.employee_id,
                bank_code,
                bank_name,
                emp_acc_name,
                emp_acc_number,
            }, {
                headers: {
                    Authorization: 'Bearer '+ getState().userStore.authorization,
                    Company: getState().userStore.company_code
                }
            }).then(() => {
                dispatch(getUserData())
                dispatch(loadServices())
                dispatch(addToast({
                    title: 'SUCCESS_TXT'.tr(),
                    message: 'ADD_ACCOUNT_SUCCESS_TXT'.tr()
                }))
                return true
            }).catch((err) => {
                dispatch(addToast({ title: 'ERROR_PAGE_TTL_TXT_01'.tr(), message: err.message }))
                return false
            })
        } catch(err) {
            dispatch(addToast({ title: 'ERROR_PAGE_TTL_TXT_01'.tr(), message: err.message }))
        } finally {
            setSubmitLoading(false)
        }
    };
}



export const removeAccount = (account_id, setRemoveLoading) => {
    return (dispatch, getState) => {
        try {
            setRemoveLoading(true)
            return baseAxios.post('/v2/api/info/account/delete', { account_id }, {
                headers: {
                    Authorization: 'Bearer '+ getState().userStore.authorization,
                    Company: getState().userStore.company_code
                }
            }).then(() => {
                dispatch(getUserData())
                return true
            }).catch((err) => {
                dispatch(addToast({ title: 'ERROR_PAGE_TTL_TXT_01'.tr(), message: err.message }))
                return false
            })
        } catch(err) {
            dispatch(addToast({ title: 'ERROR_PAGE_TTL_TXT_01'.tr(), message: err.message }))
        } finally {
            setRemoveLoading(false)
        }
    };
}
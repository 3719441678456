import React from 'react'
import { Modal } from 'react-bootstrap'
import PartChangeConfirmation from './partChangeConfirmation';
import PartChangeStatus from './partChangeStatus';
// import PartContactUsModal from './partContactUs';
import PartEmailModal from './partEmail';
import PartFAQModal from './partFAQ';
import PartPasswordModal from './partPassword';
import PartProfileModal from './partProfile';

function ModalProfile({ show, onHide, onLogout }) {
    const [page, setPage] = React.useState(1);

    let MovePage;

    if(page === 1) {
        MovePage = <PartProfileModal onHide={onHide} onLogout={onLogout} setPage={setPage} />
    }
    else if(page === 2) {
        MovePage = <PartPasswordModal onHide={onHide} setPage={setPage} />
    }
    else if(page === 3) {
        MovePage = <PartFAQModal setPage={setPage} />
    }
    else if(page === 4) {
        MovePage = <PartEmailModal onHide={onHide} setPage={setPage} />
    }
    else if(page === 5) {
        MovePage = <PartChangeConfirmation setPage={setPage} />
    }
    else if(page === 6) {
        MovePage = <PartChangeStatus onHide={onHide} setPage={setPage} />
    }

    React.useEffect(() => {
        // console.log(page);
    }, [page])

    return (
        <Modal
            show={show}
            onHide={onHide}
            size={page === 6 ? "sm" : "md"}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className='p-4'>
                {MovePage}
            </Modal.Body>
        </Modal>
    )
}

export default ModalProfile
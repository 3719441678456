import { KeyboardArrowDown } from "@mui/icons-material";
import {
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import serviceSlice from "../../../../store/serviceSlice";
import { useSelector, useDispatch } from "react-redux";
import inputSlice, { setInputData } from "../../../../store/inputSlice";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

function RequestStepWorkInfo() {
  const dispatch = useDispatch();
  const {
    education,
    jobType: jobTypes,
    jobPosition: jobPositions,
  } = useSelector(serviceSlice.selector.data);
  const {
    education_id,
    job_position_id,
    job_type_id,
    work_since,
    npwp_id,
    income,
  } = useSelector(inputSlice.selector.kyc);

  const handleInput = (name, value) => {
    dispatch(setInputData({ name, value }));
  };

  return (
    <div className="bsa-registration">
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-md-6">
              {education_id.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"LAST_EDUCATION_TXT".tr()} ({education_id.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">
                  {"LAST_EDUCATION_TXT".tr()}
                </p>
              )}
              <FormControl fullWidth className="mb-3">
                <Select
                  displayEmpty
                  IconComponent={KeyboardArrowDown}
                  className={
                    education_id.value === "" ? "bsa-unselect-mui" : ""
                  }
                  value={education_id.value}
                  onChange={(e) => handleInput("education_id", e.target.value)}
                >
                  <MenuItem disabled={true} value={""}>
                    {"LAST_EDUCATION_CAPTION_TXT".tr()}
                  </MenuItem>
                  {education.map((residentStatus, rid) => {
                    return (
                      <MenuItem key={rid} value={residentStatus.ID}>
                        {residentStatus.values.tr()}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {job_type_id.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"JOB_TYPE_TXT".tr()} ({job_type_id.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">{"JOB_TYPE_TXT".tr()}</p>
              )}
              <FormControl fullWidth className="mb-3">
                <Select
                  displayEmpty
                  IconComponent={KeyboardArrowDown}
                  className={job_type_id.value === "" ? "bsa-unselect-mui" : ""}
                  value={job_type_id.value}
                  onChange={(e) => handleInput("job_type_id", e.target.value)}
                >
                  <MenuItem disabled={true} value={""}>
                    {"JOB_TYPE_CAPTION_TXT".tr()}
                  </MenuItem>
                  {jobTypes.map((residentStatus, rid) => {
                    return (
                      <MenuItem key={rid} value={residentStatus.ID}>
                        {residentStatus.values.tr()}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {job_position_id.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"JOB_POSITION_TXT".tr()} ({job_position_id.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">
                  {"JOB_POSITION_TXT".tr()}
                </p>
              )}
              <FormControl fullWidth className="mb-3">
                <Select
                  displayEmpty
                  IconComponent={KeyboardArrowDown}
                  className={
                    job_position_id.value === "" ? "bsa-unselect-mui" : ""
                  }
                  value={job_position_id.value}
                  onChange={(e) =>
                    handleInput("job_position_id", e.target.value)
                  }
                >
                  <MenuItem disabled={true} value={""}>
                    {"JOB_POSITION_CAPTION_TXT".tr()}
                  </MenuItem>
                  {jobPositions.map((residentStatus, rid) => {
                    return (
                      <MenuItem key={rid} value={residentStatus.ID}>
                        {residentStatus.values.tr()}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6">
              {work_since.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"WORK_SINCE_TXT".tr()} ({work_since.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">
                  {"WORK_SINCE_TXT".tr()}
                </p>
              )}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  placeholder={"KYC_DOB_CAPTION_TXT".tr()}
                  inputFormat="MM-yyyy"
                  value={work_since.value}
                  onChange={(value) =>
                    handleInput("work_since", value.toString())
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      name="bsaDateOfBirth"
                      className="mb-3"
                    />
                  )}
                />
              </LocalizationProvider>
              {/* <FormControl variant="outlined" fullWidth>
                                <OutlinedInput
                                    type="text"
                                    name="bsaWorkSince"
                                    placeholder={"WORK_SINCE_CAPTION_TXT".tr()}
                                    className="mb-3"
                                    value={work_since.value}
                                    onChange={(e) => handleInput('work_since', e.target.value)}
                                />
                            </FormControl> */}
              {npwp_id.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"TAX_ID_TXT".tr()} ({npwp_id.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">{"TAX_ID_TXT".tr()}</p>
              )}
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  type="text"
                  name="bsaNPWP"
                  placeholder={"TAX_ID_CAPTION_TXT".tr()}
                  className="mb-3"
                  inputProps={{ maxLength: 16 }}
                  value={npwp_id.value}
                  onChange={(e) => handleInput("npwp_id", e.target.value)}
                />
              </FormControl>
              {income.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"INCOME_TXT".tr()} ({income.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">{"INCOME_TXT".tr()}</p>
              )}
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  type="text"
                  name="bsaIncome"
                  placeholder={"INCOME_CAPTION_TXT".tr()}
                  className="mb-3"
                  inputProps={{ maxLength: 9 }}
                  value={income.value}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/\D/g, "");
                    handleInput("income", newValue);
                    // handleInput('monthly_limit', e.target.value)
                  }}
                />
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequestStepWorkInfo;

/*
employee_id             v
employee_code           v
national_filename       -> foto ktp 1
handheld_filename       -> foto ktp 2
gender_id               v
mothers_name            v
religion_id             v
marital_status_id       v
marital_status_name     v
status                  v
company_id              -
company_code            v
company_name            v
no_of_dependents        v
bank_code
bank_account_no
bank_account_name
purpose_id              02
monthly_limit           
*/

import { createSlice } from "@reduxjs/toolkit"

const initialSaRequest = {
  employee_code: null,
  request_amt: null,
  password: null,
  account_id: null,
}
const initialRegularSaRequest = {
  employee_id: null,
  account_id: null,
  product_type: 'salary_advanced',
  request_amount: null,
  disburse_date: null,
  password: null,
}

const inputSlice = createSlice({
  name: 'inputStore',
  initialState: {
    saRequest: initialSaRequest,
    regularSaRequest: initialRegularSaRequest,
    kyc : {
      // active_flg: 1,
      ////////////////////////////////////////////////
      full_name: {
        value: '',
        error: null
      },
      email: {
        value: '',
        error: null
      },
      birthdate: {
        value: '',
        error: null
      },
      mobile_phone: {
        value: '',
        error: null
      },
      national_id: {
        value: '',
        error: null
      },
      birthplace: {
        value: '',
        error: null
      },
      ////////////////////////////////////////////////
      emergency_name_first: {
        value: '',
        error: null
      },
      emergency_mobileno_first: {
        value: '',
        error: null
      },
      emergency_relation_id_first: {
        value: '',
        error: null
      },
      emergency_name_second: {
        value: '',
        error: null
      },
      emergency_mobileno_second: {
        value: '',
        error: null
      },
      emergency_relation_id_second: {
        value: '',
        error: null
      },
      /////////////////////////////////////////////////
      residential_status_id: {
        value: '',
        error: null
      },
      district: {
        value: '',
        error: null
      },
      address: {
        value: '',
        error: null
      },
      village: {
        value: '',
        error: null
      },
      province: {
        value: '',
        error: null
      },
      postal_code: {
        value: '',
        error: null
      },
      rt_id: {
        value: '',
        error: null
      },
      rw_id: {
        value: '',
        error: null
      },
      city: {
        value: '',
        error: null
      },
      home_phone: {
        value: '',
        error: null
      },
      /////////////////////////////////////////////////
      employee_id: {
        value: '',
        error: null
      },
      employee_code: {
        value: '',
        error: null
      },
      npwp_id: {
        value: '',
        error: null
      },
      gender_id: {
        value: '',
        error: null
      },
      mothers_name: {
        value: '',
        error: null
      },
      religion_id: {
        value: '',
        error: null
      },
      education_id: {
        value: '',
        error: null
      },
      marital_status_id: {
        value: '',
        error: null
      },
      marital_status_name: {
        value: '',
        error: null
      },
      status: {
        value: '',
        error: null
      },
      company_id: {
        value: '',
        error: null
      },
      company_code: {
        value: 'PIKKAT',
        error: null
      },
      company_name: {
        value: 'Salim',
        error: null
      },
      national_filename: {
        value: '',
        error: null
      },
      handheld_filename: {
        value: '',
        error: null
      },
      no_of_dependents: {
        value: '',
        error: null
      },
      job_type_id: {
        value: '',
        error: null
      },
      job_position_id: {
        value: '',
        error: null
      },
      work_since: {
        value: '',
        error: null
      },
      income: {
        value: '',
        error: null
      },
      bank_code: {
        value: '',
        error: null
      },
      bank_account_no: {
        value: '',
        error: null
      },
      bank_account_name: {
        value: '',
        error: null
      },
      purpose_id: {
        value: "02",
        error: null
      },
      monthly_limit: {
        value: '',
        error: null
      }
    },
    loading: false
  },
  reducers: {
    setSaRequest: (state, action) => {
      const keys = Object.keys(action.payload)
      if(keys.includes('name') && keys.includes('value')){
        state.saRequest[action.payload.name] = action.payload.value
      } else {
        return state
      }
    },
    setRegularSaRequest: (state, action) => {
      const keys = Object.keys(action.payload)
      if(keys.includes('name') && keys.includes('value')){
        state.regularSaRequest[action.payload.name] = action.payload.value
      } else {
        return state
      }
    },
    setInputData: (state, action) => {
      const keys = Object.keys(action.payload)
      if(keys.includes('name') && keys.includes('value')){
        state.kyc[action.payload.name] = {
          value : action.payload.value,
          error: null
        }
      } else {
        return state
      }
    },
    clearSaRequest: (state) => {
      state.saRequest = initialSaRequest
    },
    clearRegularSaRequest: (state) => {
      state.saRequest = initialRegularSaRequest
    },
    setInputLoading: (state, action) => {
      state.loading = action.payload
    },
    setInputError: (state, action) => {
      state.kyc[action.payload.name] = {
        ...state.kyc[action.payload.name],
        error: action.payload.value
      }
    },
  },
})

export const {
  setInputData, setInputError, setSaRequest, setRegularSaRequest, clearSaRequest, clearRegularSaRequest, setInputLoading
} = inputSlice.actions

inputSlice.selector = {
  all: (state) => state[inputSlice.name],
  kyc: (state) => state[inputSlice.name].kyc,
  saRequest: (state) => state[inputSlice.name].saRequest,
  regularSaRequest: (state) => state[inputSlice.name].regularSaRequest,
}

export default inputSlice
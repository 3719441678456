import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import PartChangePassword from './partChangePassword';
import PartConfirmation from './partConfirmation';

function ModalChangePassword({ show, onHide }) {

    const [page, setPage] = React.useState(1);
    const [formData, setFormData] = useState({
        employee_code: '',
        currentPass: '',
        password : '',
        new_password: ''
    })

    const [formError, setFormError] = useState({
        hasError: false,
        errorMessage: ''
    })

    let MovePage;

    if(page === 1) {
        MovePage = <PartChangePassword formError={formError} setFormError={setFormError} setPage={setPage} setFormData={setFormData} />
    }
    else if(page === 2) {
        MovePage = <PartConfirmation onHide={onHide} formData={formData} setFormError={setFormError} setPage={setPage} />
    }

    return (
        <Modal
            show={show}
            onHide={() => false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className='p-4'>
                {MovePage}
            </Modal.Body>
        </Modal>
    )
}

export default ModalChangePassword
import React, { useState } from "react";
import {
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useSelector, useDispatch } from "react-redux";
import inputSlice, { setInputData } from "../../../../store/inputSlice";
import serviceSlice from "../../../../store/serviceSlice";
import { KeyboardArrowDown } from "@mui/icons-material";

function RequestStepPersonal() {
  const dispatch = useDispatch();
  const [openBirthDate, setOpenBirthDate] = useState(false);
  const { gender, religion, maritalStatus } = useSelector(
    serviceSlice.selector.data
  );
  const {
    full_name,
    email,
    mobile_phone,
    national_id,
    birthdate,
    birthplace,
    gender_id,
    mothers_name,
    religion_id,
    marital_status_id,
    no_of_dependents,
  } = useSelector(inputSlice.selector.kyc);

  const handleInput = (name, value) => {
    dispatch(setInputData({ name, value }));
  };

  return (
    <div className="bsa-registration">
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-md-6">
              {full_name.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"FULL_NAME_TXT".tr()} ({full_name.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">{"FULL_NAME_TXT".tr()}</p>
              )}
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  type="text"
                  name="bsaFullName"
                  placeholder={"FULL_NAME_CAPTION_TXT".tr()}
                  className="mb-3"
                  inputProps={{ maxLength: 255 }}
                  value={full_name.value}
                  onChange={(e) => handleInput("full_name", e.target.value)}
                />
              </FormControl>
              {email.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"EMAIL_TXT".tr()} ({email.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">{"EMAIL_TXT".tr()}</p>
              )}
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  type="text"
                  name="bsaEmailAddress"
                  placeholder={"EMAIL_CAPTION_TXT".tr()}
                  className="mb-3"
                  inputProps={{ maxLength: 255 }}
                  value={email.value}
                  onChange={(e) => handleInput("email", e.target.value)}
                />
              </FormControl>
              {mobile_phone.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"PHONE_NUMBER_TXT".tr()} ({mobile_phone.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">
                  {"PHONE_NUMBER_TXT".tr()}
                </p>
              )}
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  disabled
                  type="text"
                  name="bsaPhoneNumber"
                  placeholder={"PHONE_NUMBER_CAPTION_TXT".tr()}
                  className="mb-3"
                  // inputProps={{ maxLength : 20 }}
                  value={mobile_phone.value}
                  onChange={(e) => handleInput("mobile_phone", e.target.value)}
                />
              </FormControl>
              {religion_id.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"RELIGION_TXT".tr()} ({religion_id.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">{"RELIGION_TXT".tr()}</p>
              )}
              <FormControl fullWidth className="mb-3">
                <Select
                  displayEmpty
                  IconComponent={KeyboardArrowDown}
                  className={religion_id.value === "" ? "bsa-unselect-mui" : ""}
                  value={religion_id.value}
                  onChange={(e) => handleInput("religion_id", e.target.value)}
                >
                  <MenuItem disabled={true} value={""}>
                    {"RELIGION_CAPTION_TXT".tr()}
                  </MenuItem>
                  {religion.map((residentStatus, rid) => {
                    return (
                      <MenuItem key={rid} value={residentStatus.ID}>
                        {residentStatus.values.tr()}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {marital_status_id.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"MARITAL_TXT".tr()} ({marital_status_id.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">{"MARITAL_TXT".tr()}</p>
              )}
              <FormControl fullWidth className="mb-3">
                <Select
                  displayEmpty
                  IconComponent={KeyboardArrowDown}
                  className={
                    marital_status_id.value === "" ? "bsa-unselect-mui" : ""
                  }
                  value={marital_status_id.value}
                  onChange={(e) => {
                    let maritalName = maritalStatus
                      .find((marital) => marital.ID === e.target.value)
                      .values.tr();
                    handleInput("marital_status_id", e.target.value);
                    handleInput("marital_status_name", maritalName);
                  }}
                >
                  <MenuItem disabled={true} value={""}>
                    {"MARITAL_CAPTION_TXT".tr()}
                  </MenuItem>
                  {maritalStatus.map((residentStatus, rid) => {
                    return (
                      <MenuItem key={rid} value={residentStatus.ID}>
                        {residentStatus.values.tr()}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {no_of_dependents.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"KYC_DEPENDENTS_TTL_TXT".tr()} ({no_of_dependents.error.tr()}
                  )
                </p>
              ) : (
                <p className="bsa-label-default mb-2">
                  {"KYC_DEPENDENTS_TTL_TXT".tr()}
                </p>
              )}
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  type="text"
                  name="bsaPlaceOfBirth"
                  placeholder={"KYC_DEPENDENTS_CAPTION_TTL_TXT".tr()}
                  className="mb-3"
                  inputProps={{ maxLength: 32 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      name="bsaDateOfBirth"
                      className="mb-3"
                    />
                  )}
                  value={no_of_dependents.value}
                  onChange={(e) =>
                    handleInput("no_of_dependents", e.target.value)
                  }
                />
              </FormControl>
            </div>
            <div className="col-12 col-md-6">
              {national_id.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"ID_NUMBER_TXT".tr()} ({national_id.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">{"ID_NUMBER_TXT".tr()}</p>
              )}
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  type="text"
                  name="bsaIdentityNumber"
                  placeholder={"KYC_ID_NUMBER_CAPTION_TXT".tr()}
                  className="mb-3"
                  inputProps={{ maxLength: 16 }}
                  value={national_id.value}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/\D/g, "");
                    handleInput("national_id", newValue);
                  }}
                />
              </FormControl>

              <p className="bsa-label-default mb-2">{"DOB_TXT".tr()}</p>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  placeholder={"KYC_DOB_CAPTION_TXT".tr()}
                  inputFormat="MM/dd/yyyy"
                  value={birthdate.value}
                  onChange={(value) =>
                    handleInput("birthdate", value.toISOString())
                  }
                  open={openBirthDate}
                  onOpen={() => setOpenBirthDate(true)}
                  onClose={() => setOpenBirthDate(false)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      name="bsaDateOfBirth"
                      className="mb-3"
                      onClick={() => setOpenBirthDate(true)}
                    />
                  )}
                  inputProps={{
                    readOnly: true,
                  }} // font size of input text
                />
              </LocalizationProvider>
              {birthplace.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"BIRTHPLACE_TXT".tr()} ({birthplace.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">
                  {"BIRTHPLACE_TXT".tr()}
                </p>
              )}
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  type="text"
                  name="bsaPlaceOfBirth"
                  placeholder={"BIRTHPLACE_TXT".tr()}
                  className="mb-3"
                  inputProps={{ maxLength: 255 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      name="bsaDateOfBirth"
                      className="mb-3"
                    />
                  )}
                  value={birthplace.value}
                  onChange={(e) => handleInput("birthplace", e.target.value)}
                />
              </FormControl>
              {gender_id.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"GENDER_TXT".tr()} ({gender_id.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">{"GENDER_TXT".tr()}</p>
              )}
              <FormControl fullWidth className="mb-3">
                <Select
                  displayEmpty
                  IconComponent={KeyboardArrowDown}
                  className={gender_id.value === "" ? "bsa-unselect-mui" : ""}
                  value={gender_id.value}
                  onChange={(e) => handleInput("gender_id", e.target.value)}
                >
                  <MenuItem disabled={true} value={""}>
                    {"GENDER_CAPTION_TXT".tr()}
                  </MenuItem>
                  {gender.map((residentStatus, rid) => {
                    return (
                      <MenuItem key={rid} value={residentStatus.ID}>
                        {residentStatus.values.tr()}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {mothers_name.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"KYC_MOTHER_NAME_TTL_TXT".tr()} ({mothers_name.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">
                  {"KYC_MOTHER_NAME_TTL_TXT".tr()}
                </p>
              )}
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  type="text"
                  name="bsaPlaceOfBirth"
                  placeholder={"KYC_MOTHER_NAME_CAPTION_TTL_TXT".tr()}
                  className="mb-3"
                  inputProps={{ maxLength: 255 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      name="bsaDateOfBirth"
                      className="mb-3"
                    />
                  )}
                  value={mothers_name.value}
                  onChange={(e) => handleInput("mothers_name", e.target.value)}
                />
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequestStepPersonal;

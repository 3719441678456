import React from 'react'
import { FormControl, IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { changePass } from '../../../../store/userSlice/service';
import { addToast } from '../../../../store/toastSlice';

function PartPasswordModal({ setPage, onHide }) {
    const dispatch = useDispatch()
    const [values, setValues] = React.useState({
        showPassword: false,
        showCurrentPassword: false,
        showConfirmPassword: false,
        oldPass : '',
        newPass : '',
        confirmPass: '',
        loading: false
    });

    const setLoading = (value) => {
        setValues({
            ...values,
            loading: value
        })
    }

    const editpass = (name, value) => {
        setValues({
            ...values,
            [name] : value
        })
    }

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleClickShowCurrentPassword = () => {
        setValues({
            ...values,
            showCurrentPassword: !values.showCurrentPassword,
        });
    };

    const handleClickShowConfirmPassword = () => {
        setValues({
            ...values,
            showConfirmPassword: !values.showConfirmPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const submitChangePass = () => {
        if(values.newPass !== values.confirmPass){
            return Swal.fire('Error','PASSWORD_MATCH_ERROR_TXT'.tr())
        }       
        setLoading(true)
        dispatch(changePass(values.oldPass, values.newPass)).then(() => {
            dispatch(addToast({ title: 'Success', message: 'PASSWORD_CHANGED_TXT'.tr()}))
            setLoading(false)
            onHide()
        })
    }

    return (
        <div className='bsa-change-password'>
            <div className='bsa-close-modal-btn' align="left">
                <Button variant='light' className='bg-white border-0 p-0' onClick={() => setPage(1)}>
                    <ArrowBackIosNewIcon className='fill-muted' />
                </Button>
            </div>
            <p className='bsa-change-password-title mb-3'>Change Password</p>
            <p className="bsa-label-default mb-2">Current Password</p>
            <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                    value={values.oldPass}
                    type={values.showCurrentPassword ? 'text' : 'password'}
                    name="bsaCurrentPassword"
                    placeholder={"Your Current Password"}
                    className="bsa-form-password"
                    endAdornment={
                        <InputAdornment position="start">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowCurrentPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {values.showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    onChange={(e) => editpass('oldPass', e.target.value)}
                />
            </FormControl>
            <p className="bsa-label-default mb-2 mt-3">New Password</p>
            <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                    value={values.newPass}
                    type={values.showPassword ? 'text' : 'password'}
                    name="bsaPassword"
                    placeholder={"Your New Password"}
                    className="bsa-form-password"
                    endAdornment={
                        <InputAdornment position="start">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    onChange={(e) => editpass('newPass', e.target.value)}
                />
            </FormControl>
            <p className="bsa-label-default mb-2 mt-3">Confirm New Password</p>
            <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                    value={values.confirmPass}
                    type={values.showConfirmPassword ? 'text' : 'password'}
                    name="bsaConfirmPassword"
                    placeholder={"Enter Your Password"}
                    className="bsa-form-password"
                    endAdornment={
                        <InputAdornment position="start">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {values.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    onChange={(e) => editpass('confirmPass', e.target.value)}
                />
            </FormControl>
            <Button disabled={values.loading} onClick={submitChangePass} variant='primary' className='w-100 mt-3 pt-2 pb-2 mt-5'>
                {"RESET_PASSWORD_CAPTION_TXT".tr()}
            </Button>
        </div>
    )
}

export default PartPasswordModal
import { KeyboardArrowDown } from "@mui/icons-material";
import { FormControl, MenuItem, OutlinedInput, Select } from "@mui/material";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import inputSlice, { setInputData } from "../../../../store/inputSlice";
import serviceSlice from "../../../../store/serviceSlice";

function RequestStepAddress() {
  const dispatch = useDispatch();
  const { province: provinces, residentalInfoStatus } = useSelector(
    serviceSlice.selector.data
  );
  const {
    residential_status_id,
    district,
    address,
    village,
    province,
    postal_code,
    rt_id,
    rw_id,
    city,
    home_phone,
  } = useSelector(inputSlice.selector.kyc);

  const handleInput = (name, value) => {
    dispatch(setInputData({ name, value }));
  };

  return (
    <div className="bsa-registration">
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-md-6">
              {residential_status_id.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"RESIDENT_STATUS_TXT".tr()} (
                  {residential_status_id.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">
                  {"RESIDENT_STATUS_TXT".tr()}
                </p>
              )}
              <FormControl fullWidth className="mb-3">
                <Select
                  displayEmpty
                  IconComponent={KeyboardArrowDown}
                  className={
                    residential_status_id.value === "" ? "bsa-unselect-mui" : ""
                  }
                  value={residential_status_id.value}
                  onChange={(e) =>
                    handleInput("residential_status_id", e.target.value)
                  }
                >
                  <MenuItem disabled={true} value={""}>
                    {"RESIDENT_STATUS_CAPTION_TXT".tr()}
                  </MenuItem>
                  {residentalInfoStatus.map((residentStatus, rid) => {
                    return (
                      <MenuItem key={rid} value={residentStatus.ID}>
                        {residentStatus.values.tr()}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {address.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"ADDRESS_HOME_TXT".tr()} ({address.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">
                  {"ADDRESS_HOME_TXT".tr()}
                </p>
              )}
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  type="text"
                  name="bsaAddress"
                  placeholder={"ADDRESS_CAPTION_TXT".tr()}
                  className="mb-3"
                  inputProps={{ maxLength: 255 }}
                  value={address.value}
                  onChange={(e) => handleInput("address", e.target.value)}
                />
              </FormControl>
              {province.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"PROVINCE_TXT".tr()} ({province.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">{"PROVINCE_TXT".tr()}</p>
              )}
              <FormControl fullWidth className="mb-3">
                <Select
                  displayEmpty
                  IconComponent={KeyboardArrowDown}
                  className={province === 0 ? "bsa-unselect-mui" : ""}
                  value={province.value}
                  onChange={(e) => handleInput("province", e.target.value)}
                >
                  <MenuItem disabled={true} value={""}>
                    {"PROVINCE_CAPTION_TXT".tr()}
                  </MenuItem>
                  {provinces.map((prov, pid) => {
                    return (
                      <MenuItem key={pid} value={prov}>
                        {prov}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {city.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"CITY_TXT".tr()} ({city.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">{"CITY_TXT".tr()}</p>
              )}
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  type="text"
                  name="bsaCity"
                  placeholder={"CITY_CAPTION_TXT".tr()}
                  className="mb-3"
                  inputProps={{ maxLength: 50 }}
                  value={city.value}
                  onChange={(e) => handleInput("city", e.target.value)}
                />
              </FormControl>
            </div>
            <div className="col-12 col-md-6">
              {district.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"DISTRICT_TXT".tr()} ({district.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">{"DISTRICT_TXT".tr()}</p>
              )}
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  type="text"
                  name="bsaDistrict"
                  placeholder={"DISTRICT_CAPTION_TXT".tr()}
                  className="mb-3"
                  inputProps={{ maxLength: 50 }}
                  value={district.value}
                  onChange={(e) => handleInput("district", e.target.value)}
                />
              </FormControl>
              {village.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"VILLAGE_TXT".tr()} ({village.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">{"VILLAGE_TXT".tr()}</p>
              )}
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  type="text"
                  name="bsaVillage"
                  placeholder={"VILLAGE_CAPTION_TXT".tr()}
                  className="mb-3"
                  inputProps={{ maxLength: 50 }}
                  value={village.value}
                  onChange={(e) => handleInput("village", e.target.value)}
                />
              </FormControl>
              {postal_code.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"POSTCODE_TXT".tr()} ({postal_code.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">{"POSTCODE_TXT".tr()}</p>
              )}
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  type="text"
                  name="bsaPostalCode"
                  placeholder={"POSTCODE_CAPTION_TXT".tr()}
                  className="mb-3"
                  // inputProps={{ maxLength : 7 }}
                  value={postal_code.value}
                  onChange={(e) => handleInput("postal_code", e.target.value)}
                />
              </FormControl>
              <div className="row">
                <div className="col-12 col-md-6">
                  {rt_id.error ? (
                    <p className="bsa-label-default mb-2 text-danger">
                      {"RT_TXT".tr()} ({rt_id.error.tr()})
                    </p>
                  ) : (
                    <p className="bsa-label-default mb-2">{"RT_TXT".tr()}</p>
                  )}
                  <FormControl variant="outlined" fullWidth>
                    <OutlinedInput
                      type="text"
                      name="bsaRT"
                      placeholder={"RT_CAPTION_TXT".tr()}
                      className="mb-3"
                      inputProps={{ maxLength: 5 }}
                      value={rt_id.value}
                      onChange={(e) => handleInput("rt_id", e.target.value)}
                    />
                  </FormControl>
                </div>
                <div className="col-12 col-md-6">
                  {rw_id.error ? (
                    <p className="bsa-label-default mb-2 text-danger">
                      {"RW_TXT".tr()} ({rw_id.error.tr()})
                    </p>
                  ) : (
                    <p className="bsa-label-default mb-2">{"RW_TXT".tr()}</p>
                  )}
                  <FormControl variant="outlined" fullWidth>
                    <OutlinedInput
                      type="text"
                      name="bsaRW"
                      placeholder={"RW_CAPTION_TXT".tr()}
                      className="mb-3"
                      inputProps={{ maxLength: 5 }}
                      value={rw_id.value}
                      onChange={(e) => handleInput("rw_id", e.target.value)}
                    />
                  </FormControl>
                </div>
              </div>
              {home_phone.error ? (
                <p className="bsa-label-default mb-2 text-danger">
                  {"HOME_PHONE_NUMBER_TXT".tr()} ({home_phone.error.tr()})
                </p>
              ) : (
                <p className="bsa-label-default mb-2">
                  {"HOME_PHONE_NUMBER_TXT".tr()}
                </p>
              )}
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  type="text"
                  name="bsaHomePhoneNumber"
                  placeholder={"HOME_PHONE_NUMBER_CAPTION_TXT".tr()}
                  className="mb-3"
                  // inputProps={{ maxLength : 20 }}
                  value={home_phone.value}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/\D/g, "");
                    handleInput("home_phone", newValue);
                  }}
                />
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequestStepAddress;

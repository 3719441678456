// Need to use the React-specific entry point to allow generating React hooks
import { setSaRequestFeeLoading, setSaRequestFee, setSaHistoryLoading, setSaHistory, setRegularSaHistory, setRegularSaHistoryLoading } from '.';
import { baseAxios } from '../configs'
import { addToast } from '../toastSlice';
import { getUserData } from '../userSlice/service';


export const getSAFee = (amount) => {
    return (dispatch, getState) => {
        try {
            const account = getState().serviceStore.activeAccount
            if(!account){
                throw (new Error('SA_REQUIRED_ACC_CAPTION_TXT'.tr()))
            }
            dispatch(setSaRequestFeeLoading(true))
            return baseAxios.post('/v2/api/info/fee', {
                employee_code : getState().userStore.userInfo.employee_code,
                account_id: account.account_id,
                amount
            }, { 
                headers : {
                    Authorization: 'Bearer '+ getState().userStore.authorization,
                    Company: getState().userStore.company_code
                }
            }).then(({ data }) => {
                if(data.code === '0'){
                    dispatch(setSaRequestFee(data))
                } else {
                    throw (new Error(data.message))
                }
            }).catch((err) => {
                dispatch(addToast({ title : 'Error', message: err.message }))
            })
        } catch(err) {
            dispatch(addToast({ title : 'Error', message: err.message }))
            // Swal.fire('Error', err.message)
        } finally {
            dispatch(setSaRequestFeeLoading(false))
        }
    };
}

export const getSAHistory = () => {
    return async (dispatch, getState) => {
        try {
            // const accounts = getState().userStore.userData.bank_account
            // if(!accounts.length){
            //     throw (new Error('Must first create a bank account'))
            // }
            dispatch(setSaHistoryLoading(true))
            if(getState().userStore.userInfo.employee_id === 0){
                return false
            }
            return await baseAxios.get(`/v3/api/sa/orderhistory?employee_id=${getState().userStore.userInfo.employee_id}`, { 
                headers : {
                    Authorization: 'Bearer '+ getState().userStore.authorization,
                    Company: getState().userStore.company_code
                }
            }).then(({ data }) => {
                if(data.code === '0'){
                    dispatch(setSaHistory(data.data))
                    return true
                } else {
                    throw (new Error(data.message))
                }
            }).catch((err) => {
                dispatch(addToast({ title: 'Error', message: err.message}))
            })
        } catch(err) {
            const title = err.response && err.response.data ? err.response.data.error : 'Error'
            const message = err.response && err.response.data ? err.response.data.message ?? err.message : 'Error'
            dispatch(addToast({ title, message}))
            // Swal.fire('Error', err.message)
        } finally {
            dispatch(setSaHistoryLoading(false))
        }
    };
}

export const getRegularSAHistory = (start, end) => {
    return async (dispatch, getState) => {
        try {
            dispatch(setRegularSaHistoryLoading(true))
            if(getState().userStore.userInfo.employee_id === 0){
                return false
            }
            return await baseAxios.post(`/v2/api/regularsa/list`, {
                employee_id : getState().userStore.userInfo.employee_id,
                status : "active",
                product_type : "salary_advanced",
                start_date : "2021-11-01",
                end_date : "2022-11-09"
            } , { 
                headers : {
                    Authorization: 'Bearer '+ getState().userStore.authorization,
                    Company: getState().userStore.company_code
                }
            }).then(({ data }) => {
                if(data.code === '0'){
                    dispatch(setRegularSaHistory(data.data))
                    return true
                } else {
                    throw (new Error(data.message))
                }
            }).catch((err) => {
                dispatch(addToast({ title: 'Error', message: err.message}))
            })
        } catch(err) {
            const title = err.response && err.response.data ? err.response.data.error : 'Error'
            const message = err.response && err.response.data ? err.response.data.message ?? err.message : 'Error'
            dispatch(addToast({ title, message}))
            // Swal.fire('Error', err.message)
        } finally {
            dispatch(setRegularSaHistoryLoading(false))
        }
    };
}

export const getRequestDetail = (refId, cb) => { //v2/api/sa/orderstatus?referenceId
    return async (dispatch, getState) => {
        try {
            return await baseAxios.get(`/v2/api/sa/orderstatus?referenceId=${refId}`, { 
                headers : {
                    Authorization: 'Bearer '+ getState().userStore.authorization,
                    Company: getState().userStore.company_code
                }
            }).then(({ data }) => {
                return cb(data, null)
            }).catch((err) => {
                return cb(null, err)
            })
        } catch(err) {
            const title = err.response && err.response.data ? err.response.data.error : 'Error'
            const message = err.response && err.response.data ? err.response.data.message ?? err.message : 'Error'
            dispatch(addToast({ title, message}))
        } finally {
            dispatch(setRegularSaHistoryLoading(false))
        }
    };
}

export const getRegulerRequestDetail = (detailId, cb) => {
    return async (dispatch, getState) => {
        try {
            return await baseAxios.post(`/v2/api/regularsa/detail`, {
                employee_id : getState().userStore.userInfo.employee_id,
                regular_sys_sa_id : detailId
            } , { 
                headers : {
                    Authorization: 'Bearer '+ getState().userStore.authorization,
                    Company: getState().userStore.company_code
                }
            }).then(({ data }) => {
                return cb(data.data, null)
            }).catch((err) => {
                return cb(null, err)
            })
        } catch(err) {
            const title = err.response && err.response.data ? err.response.data.error : 'Error'
            const message = err.response && err.response.data ? err.response.data.message ?? err.message : 'Error'
            dispatch(addToast({ title, message}))
        } finally {
            dispatch(setRegularSaHistoryLoading(false))
        }
    };
}


export const deactivateRegularSa = (saId, cb) => {
    return async (dispatch, getState) => {
        try {
            return await baseAxios.post(`/v2/api/regularsa/deactivate`, {
                employee_id : getState().userStore.userInfo.employee_id,
                regular_sys_sa_id : saId
            } , { 
                headers : {
                    Authorization: 'Bearer '+ getState().userStore.authorization,
                    Company: getState().userStore.company_code
                }
            }).then(async({ data }) => {
                await dispatch(getUserData())
                return cb(data, null)
            }).catch((err) => {
                return cb(null, err)
            })
        } catch(err) {
            const title = err.response && err.response.data ? err.response.data.error : 'Error'
            const message = err.response && err.response.data ? err.response.data.message ?? err.message : 'Error'
            dispatch(addToast({ title, message}))
        } finally {
            dispatch(setRegularSaHistoryLoading(false))
        }
    };
}
import React from 'react'
import { KeyboardArrowDown, Language } from '@mui/icons-material';
import { FormControl, MenuItem, Select } from '@mui/material';
import { useTranslation } from '../../../store/languageSlice/languageHook';

export default function LanguageSelector() {
    const { languages, changeLanguage, activeName } = useTranslation()

    const handleChange = (event) => {
        changeLanguage(event.target.value);
    };

    return (
        <div className='d-flex align-items-center justify-content-end'>
            <Language className='fill-soft-primary' />
            <FormControl className='bsa-select-language ms-2'>
                <Select
                    value={activeName()}
                    onChange={handleChange}
                    IconComponent={KeyboardArrowDown}
                >
                    {
                        languages.map((language, i) => {
                            return <MenuItem key={i} value={language.name}>{language.name}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
        </div>
    )
}
import React from 'react'
import { Button } from 'react-bootstrap';

function PartChangeConfirmation({ setPage }) {
    const handleSubmit = async () => {
        setPage(6);
    }

    return (
        <div>
            <p className='bsa-change-password-titlenew'>Are you sure you want to change ?</p>
            <p className='bsa-change-password-descnew'>If you change this data cannot be undone, please correct the field before you can submit this action.</p>
            <div className='row'>
                <div className='col-6'>
                    <Button onClick={() => setPage(1)} variant='light' className='w-100 pt-2 pb-2'>
                        No
                    </Button>
                </div>
                <div className='col-6'>
                    <Button onClick={handleSubmit} variant='primary' className='w-100 pt-2 pb-2'>
                        Yes
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default PartChangeConfirmation
export default function accountNumberHider(account_number) {
    let result = ''
    for(let num = 0; num < account_number.toString().length; num++){
        if(num < Math.floor(account_number.toString().length/3)){
            result += account_number.toString()[num]
        } else {
            result += 'x'
        }
    }
    return result;
}
import Core from './jsx';
import { store } from './store'
import { Provider } from 'react-redux'

function App() {
  return (
      <Provider store={store}>
        <Core />
      </Provider>
  );
}

export default App;
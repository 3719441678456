import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import ForgotPassword from '../../../../assets/img/img_reset_success.png';

function ModalForgotPassword({ show, onHide, setPage }) {
    const handleBack = () => {
        onHide();
        setPage(1);
    }
    
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className='p-4'>
                <div className='bsa-modal-forgot-password'>
                    <div className='bsa-logo-forgot-password' align="center">
                        <img src={ForgotPassword} alt="Forgot Password" width={300} />
                    </div>
                    <p className='bsa-modal-forgot-password-title mb-3'>{'FORGOT_PASSWORD_CAPTION_TXT'.tr()}</p>
                    <p className='bsa-modal-forgot-password-desc mb-3'>{"RESET_PASSWORD_CHECK_EMAIL_TXT".tr()}</p>
                    <Button variant="primary" className="w-100 pt-2 pb-2" onClick={() => handleBack()}>
                        {"Sign In"}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalForgotPassword
import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  authorization: null,
  accessToken: null,
  company_code: 'PIKKAT',
  userInfo: {
    employee_id: 0,
    full_name: '',
    employee_code: "",
    email: "",
    company_name: "",
    mobile_phone: "",
    is_password_change: 0,
    is_email_confirm: 0,
    is_otp_confirm: 0,
    loading: false
  },
  userData: {
    amount: 0,
    company_name: "",
    close_disburse: 0,
    flag_information: false,
    bank_account: [
        {
            account_id: 99,
            employee_id: 99,
            bank_code: "test",
            bank_name: "test",
            emp_acc_number: "test",
            emp_acc_name: "test"
        }
    ],
    min_amount: 999999,
    freeze_start_date: "2022-08-06",
    freeze_end_date: "2022-08-24",
},
  loading: true
}

const userSlice = createSlice({
  name: 'userStore',
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload
    },
    setAuthorization: (state, action) => {
      state.authorization = action.payload
    },
    setUserData: (state, action) => {
      state.userData = action.payload
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload
      state.loading = false
    },
    setCompanyToken: (state, action) => {
      state.company_code = action.payload
    },
    setLoadingUserInfo: (state, action) => {
      state.userInfo = {
        ...state.userInfo,
        loading: action.payload
      }
    },
    clearUserData: (state) => {
      return initialState
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
})

export const {
  setAccessToken, setAuthorization, setUserData, setLoading, setUserInfo, clearUserData, setLoadingUserInfo, setCompanyToken
} = userSlice.actions

userSlice.selector = {
  all: (state) => state[userSlice.name],
  accessToken: (state) => state[userSlice.name].accessToken,
  authorization: (state) => state[userSlice.name].authorization,
  userData: (state) => state[userSlice.name].userData,
  userInfo: (state) => state[userSlice.name].userInfo,
}

export default userSlice
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from "redux";

import userSlice from "./userSlice";
import languageSlice from "./languageSlice";
import saRequestSlice from './sarequestSlice';
import toastSlice from './toastSlice';
import serviceSlice from './serviceSlice';
import inputSlice from './inputSlice';

const rootReducer = combineReducers({
    [inputSlice.name]: inputSlice.reducer,
    [languageSlice.name]: languageSlice.reducer,
    [saRequestSlice.name]: saRequestSlice.reducer,
    [serviceSlice.name]: serviceSlice.reducer,
    [toastSlice.name]: toastSlice.reducer,
    [userSlice.name]: userSlice.reducer,
})

export const store = configureStore({
    reducer: rootReducer,
    // devTools: false
})
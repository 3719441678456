import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import PartDetailHistory from './partDetailHistory';
import PartModalHistory from './partModalHistory';

function ModalHistory({ show, onHide, openModalDetail, isShop }) {
    const [page, setPage] = useState(1);
    const [regular_sys_sa_id, set_regular_sys_sa_id] = useState(null)

    let MovePage;

    if(page === 1) {
        MovePage = <PartModalHistory isShop={isShop} openRequestDetail={openModalDetail} setDetailId={set_regular_sys_sa_id} onHide={onHide} setPage={setPage} />
    }
    else if(page === 2) {
        MovePage = <PartDetailHistory detailId={regular_sys_sa_id} onHide={onHide} setPage={setPage} />
    }

    const handleClose = () => {
        setPage(1)
        onHide()
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className='p-4'>
                {MovePage}
            </Modal.Body>
        </Modal>
    )
}

export default ModalHistory
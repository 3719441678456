// Need to use the React-specific entry point to allow generating React hooks
import moment from "moment";
import {
  clearRegularSaRequest,
  clearSaRequest,
  setInputError,
  setInputLoading,
} from ".";
import convertBase64ToBlob from "../../helpers/baseToBlob";
import isEmail from "../../helpers/tools";
import { baseAxios } from "../configs";
import { getRegularSAHistory, getSAHistory } from "../sarequestSlice/service";
// import { clearSaRequestFee } from '../sarequestSlice';
// import { setPartners, setData, setLoading } from '../serviceSlice';
import { addToast } from "../toastSlice";
import { getUserData } from "../userSlice/service";

// export const loadServices = () => {
//     return (dispatch, getState) => {
//         try {
//             return baseAxios.post('/v2/api/getlistcompany', null, {
//                 headers: {
//                     Authorization: 'Bearer '+ getState().userStore.authorization,
//                     Company: getState().userStore.company_code
//                 }
//             }).then(({ data }) => {
//                 dispatch(setPartners(data.partners))
//                 dispatch(setData(data.data))
//             }).catch((err) => {
//                 dispatch(addToast({ title: 'Error', message: err.message}))
//             })
//         } catch(err) {
//             dispatch(addToast({ title: 'Error', message: err.message}))
//         } finally {
//             dispatch(setLoading(false))
//         }
//     };
// }

export const postSaRequest = (cb) => {
  return (dispatch, getState) => {
    try {
      dispatch(setInputLoading(true));
      let saData = getState().inputStore.saRequest;
      if (!saData.account_id) {
        throw new Error("Select or create account first!");
      }
      return baseAxios
        .post("/v2/api/sa/add", saData, {
          headers: {
            Authorization: "Bearer " + getState().userStore.authorization,
            Company: getState().userStore.company_code,
          },
        })
        .then(() => {
          dispatch(getUserData());
          dispatch(clearSaRequest());
          dispatch(getSAHistory());
          if (cb) cb();
        })
        .catch((err) => {
          dispatch(addToast({ title: "Error", message: err.message }));
        })
        .finally(() => {
          dispatch(setInputLoading(false));
        });
    } catch (err) {
      dispatch(addToast({ title: "Error", message: err.message }));
    }
  };
};

export const postRegularSaRequest = (cb) => {
  return (dispatch, getState) => {
    try {
      dispatch(setInputLoading(true));
      let saData = getState().inputStore.regularSaRequest;
      if (!saData.account_id) {
        throw new Error("Select or create account first!");
      }
      return baseAxios
        .post("/v2/api/regularsa/add", saData, {
          headers: {
            Authorization: "Bearer " + getState().userStore.authorization,
            Company: getState().userStore.company_code,
          },
        })
        .then(() => {
          dispatch(getUserData());
          dispatch(clearRegularSaRequest());
          // dispatch(clearSaRequestFee())
          dispatch(getRegularSAHistory());
          if (cb) cb();
        })
        .catch((err) => {
          dispatch(addToast({ title: "Error", message: err.message }));
        })
        .finally(() => {
          dispatch(setInputLoading(false));
        });
    } catch (err) {
      dispatch(addToast({ title: "Error", message: err.message }));
    }
  };
};

export const checkKycInput = (setStep, setModalPass) => {
  //panjang bnget njir, males liatnya
  return (dispatch, getState) => {
    let result = true;
    let step = 1;
    const setError = (name, value) => dispatch(setInputError({ name, value }));
    const inputs = getState().inputStore.kyc;
    ////////////////////////////////////////////////////////////////////////////
    //////////////////////////////// Page 1 ////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    if (!inputs.full_name.value) {
      setError("full_name", "KYC_NAME_ERROR_TTL_TXT".tr());
      result = false;
      step = 1;
    }
    if (!inputs.email.value) {
      setError("email", "KYC_EMAIL_ERROR_TTL_TXT_01".tr());
      result = false;
      step = 1;
    }
    if (inputs.email.value && !isEmail(inputs.email.value)) {
      setError("email", "KYC_EMAIL_ERROR_TTL_TXT_02".tr());
      result = false;
      step = 1;
    }
    if (!inputs.religion_id.value) {
      setError("religion_id", "KYC_RELIGION_ERROR_TTL_TXT".tr());
      result = false;
      step = 1;
    }
    if (!inputs.marital_status_id.value) {
      setError("marital_status_id", "KYC_MARITAL_ERROR_TTL_TXT".tr());
      result = false;
      step = 1;
    }
    if (
      !inputs.no_of_dependents.value &&
      inputs.no_of_dependents.value !== "0"
    ) {
      setError("no_of_dependents", "KYC_DEPENDENTS_ERROR_TTL_TXT".tr());
      result = false;
      step = 1;
    }
    if (!inputs.national_id.value) {
      setError("national_id", "KYC_ID_NUMBER_ERROR_TTL_TXT_01".tr());
      result = false;
      step = 1;
    }
    if (!inputs.national_id.value) {
      setError("national_id", "KYC_ID_NUMBER_ERROR_TTL_TXT_01".tr());
      result = false;
      step = 1;
    }
    if (!inputs.birthdate.value) {
      setError("birthdate", "KYC_DOB_ERROR_TTL_TXT".tr());
      result = false;
      step = 1;
    }
    if (!inputs.birthplace.value) {
      setError("birthplace", "Place of Birth is required".tr());
      result = false;
      step = 1;
    }
    if (!inputs.gender_id.value) {
      setError("gender_id", "KYC_GENDER_ERROR_TTL_TXT".tr());
      result = false;
      step = 1;
    }
    if (!inputs.mothers_name.value) {
      setError("mothers_name", "KYC_MOTHER_NAME_ERROR_TTL_TXT".tr());
      result = false;
      step = 1;
    }
    if (!result) {
      setModalPass(false);
      setStep(step);
      return result;
    }
    ////////////////////////////////////////////////////////////////////////////
    //////////////////////////////// Page 2 ////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    if (!inputs.emergency_name_first.value) {
      setError("emergency_name_first", "KYC_GENERIC_REQURIED_ERROR_TXT".tr());
      result = false;
      step = 2;
    }
    if (!inputs.emergency_mobileno_first.value) {
      setError(
        "emergency_mobileno_first",
        "KYC_GENERIC_REQURIED_ERROR_TXT".tr()
      );
      result = false;
      step = 2;
    }
    if (!inputs.emergency_relation_id_first.value) {
      setError("emergency_relation_id_first", "RELATIONSHIP_ERROR_TXT".tr());
      result = false;
      step = 2;
    }
    if (!inputs.emergency_name_second.value) {
      setError("emergency_name_second", "KYC_GENERIC_REQURIED_ERROR_TXT".tr());
      result = false;
      step = 2;
    }
    if (!inputs.emergency_mobileno_second.value) {
      setError(
        "emergency_mobileno_second",
        "KYC_GENERIC_REQURIED_ERROR_TXT".tr()
      );
      result = false;
      step = 2;
    }
    if (!inputs.emergency_relation_id_second.value) {
      setError("emergency_relation_id_second", "RELATIONSHIP_ERROR_TXT".tr());
      result = false;
      step = 2;
    }
    if (!result) {
      setModalPass(false);
      setStep(step);
      return result;
    }
    ////////////////////////////////////////////////////////////////////////////
    //////////////////////////////// Page 3 ////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    if (!inputs.residential_status_id.value) {
      setError("residential_status_id", "RESIDENT_STATUS_ERROR_TTL_TXT".tr());
      result = false;
      step = 3;
    }
    if (!inputs.district.value) {
      setError("district", "DISTRICT_ERROR_TXT".tr());
      result = false;
      step = 3;
    }
    if (!inputs.address.value) {
      setError("address", "KYC_ADDRESS_ERROR_TTL_TXT".tr());
      result = false;
      step = 3;
    }
    if (!inputs.village.value) {
      setError("village", "VILLAGE_ERROR_TXT".tr());
      result = false;
      step = 3;
    }
    if (!inputs.province.value) {
      setError("province", "PROVINCE_ERROR_TTL_TXT".tr());
      result = false;
      step = 3;
    }
    if (!inputs.postal_code.value) {
      setError("postal_code", "POSTCODE_ERROR_TXT".tr());
      result = false;
      step = 3;
    }
    if (!inputs.rt_id.value) {
      setError("rt_id", "RT_ERROR_TXT".tr());
      result = false;
      step = 3;
    }
    if (!inputs.rw_id.value) {
      setError("rw_id", "RW_ERROR_TXT".tr());
      result = false;
      step = 3;
    }
    if (!inputs.city.value) {
      setError("city", "CITY_ERROR_TTL_TXT".tr());
      result = false;
      step = 3;
    }
    if (!inputs.home_phone.value) {
      setError("home_phone", "HOME_PHONE_NUMBER_ERROR_TTL_TXT".tr());
      result = false;
      step = 3;
    }
    if (!result) {
      setModalPass(false);
      setStep(step);
      return result;
    }
    ////////////////////////////////////////////////////////////////////////////
    //////////////////////////////// Page 4 ////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    if (!inputs.education_id.value) {
      setError("education_id", "LAST_EDUCATION_ERROR_TTL_TXT".tr());
      result = false;
      step = 4;
    }
    if (!inputs.job_type_id.value) {
      setError("job_type_id", "JOB_TYPE_ERROR_TXT".tr());
      result = false;
      step = 4;
    }
    if (!inputs.job_position_id.value) {
      setError("job_position_id", "JOB_POSITION_ERROR_TXT".tr());
      result = false;
      step = 4;
    }
    if (!inputs.work_since.value) {
      setError("work_since", "WORK_SINCE_ERROR_TXT".tr());
      result = false;
      step = 4;
    }
    if (!inputs.npwp_id.value) {
      setError("npwp_id", "TAX_ID_ERROR_TXT".tr());
      result = false;
      step = 4;
    }
    if (!inputs.income.value) {
      setError("income", "INCOME_ERROR_TXT".tr());
      result = false;
      step = 4;
    }
    if (!result) {
      setModalPass(false);
      setStep(step);
      return result;
    }
    ////////////////////////////////////////////////////////////////////////////
    return result;
  };
};

export const postKyc = (navigate, files, cb) => {
  return (dispatch, getState) => {
    try {
      const userData = getState().userStore.userInfo;
      let keys = Object.keys(getState().inputStore.kyc);
      let kycData = {};
      for (let k = 0; k < keys.length; k++) {
        if (getState().inputStore.kyc[keys[k]].value !== "") {
          kycData[keys[k]] = getState().inputStore.kyc[keys[k]].value;
        }
      }
      // kycData.active_flg = 1;
      // kycData.status = 125;
      // BANK =====
      // kycData.bank_code = getState().serviceStore.activeAccount.bank_code;
      // kycData.bank_account_no =
      //   getState().serviceStore.activeAccount.emp_acc_number;
      // kycData.bank_account_name =
      //   getState().serviceStore.activeAccount.bank_name;
      kycData.employee_id = kycData?.employee_id || userData?.employee_id;
      kycData.employee_code = kycData?.employee_code || userData?.employee_code;
      let workSince = new Date(kycData.work_since).getUTCMonth() + 1;
      if (workSince < 10) workSince = `0${workSince}`;
      kycData.work_since = `${workSince}-${new Date(
        kycData.work_since
      ).getUTCFullYear()}`;
      const formData = new FormData();
      ////////////
      if (files.id) {
        formData.append("ktpFile", files.id);
      } else {
        if(!!kycData.national_filename){
          console.log(convertBase64ToBlob(kycData.national_filename));
          // console.log(convertBase64ToBlob(kycData.national_filename));
          formData.append(
            "ktpFile",
            convertBase64ToBlob(kycData.national_filename),
            kycData.full_name + "_" + kycData.company_code + "_ktpFile.jpg"
          );
        }
      }
      ////////////
      if (files.selfie) {
        formData.append("selfieFile", files.selfie);
      } else {
        if(!!kycData.handheld_filename){
          formData.append(
            "selfieFile",
            convertBase64ToBlob(kycData.handheld_filename),
            kycData.full_name + "_" + kycData.company_code + "_selfieFile.jpg"
          );
        }
      }
      ////////////
      formData.append(
        "employee_id",
        kycData.employee_id || userData.employee_id
      );
      delete kycData.national_filename;
      delete kycData.handheld_filename;
      // console.log(kycData)
      // return false
      return baseAxios
        .post("/v3/api/kyc/edit", kycData, {
          headers: {
            Authorization: "Bearer " + getState().userStore.authorization,
            Company: getState().userStore.company_code,
          },
        })
        .then(() => {
          return baseAxios.post("/v3/api/kyc/postuserfile", formData, {
            headers: {
              Authorization: "Bearer " + getState().userStore.authorization,
              Company: getState().userStore.company_code,
            },
          });
        })
        .then(() => {
          // if (getState().inputStore.regularSaRequest.disburse_date) {
          //   return baseAxios.post(
          //     "/v2/api/regularsa/add",
          //     getState().inputStore.regularSaRequest,
          //     {
          //       headers: {
          //         Authorization: "Bearer " + getState().userStore.authorization,
          //         Company: getState().userStore.company_code,
          //       },
          //     }
          //   );
          // } else {
          //   return baseAxios.post(
          //     "/v2/api/sa/add",
          //     getState().inputStore.saRequest,
          //     {
          //       headers: {
          //         Authorization: "Bearer " + getState().userStore.authorization,
          //         Company: getState().userStore.company_code,
          //       },
          //     }
          //   );
          // }
        })
        .then(() => {
          dispatch(getUserData());
          if (navigate) navigate("/dashboard");
          if (cb) cb();
        })
        .catch((err) => {
          // console.log(err,'<-----');
          dispatch(addToast({ title: "Error", message: err.message }));
        });
    } catch (err) {
      console.log("ERR", err);
      // console.log(err,'<=---');
      dispatch(addToast({ title: "Error", message: err.message }));
    }
  };
};

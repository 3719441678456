import { Popover } from "@mui/material";
import React, { useRef, useState } from "react";
import PicLeft from "../../../../assets/img/bsa-proof-left.png";
import PicRight from "../../../../assets/img/bsa-proof-right.png";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import ButtonMUI from "@mui/material/Button";
import { Button } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import ModalCameraLeft from "./modalCameraLeft";
import ModalCameraRight from "./modalCameraRight";
import { useSelector, useDispatch } from "react-redux";
import inputSlice, { setInputData } from "../../../../store/inputSlice";

function RequestStepProofIdentity({
  filesLeft,
  setFileLeft,
  filesRight,
  setFileRight,
}) {
  const dispatch = useDispatch();
  const { national_filename, handheld_filename } = useSelector(
    inputSlice.selector.kyc
  );

  const [modalCameraLeft, setModalCameraLeft] = useState(false);
  const [modalCameraRight, setModalCameraRight] = useState(false);
  const [anchorUploadLeft, setAnchorUploadLeft] = useState(null);
  const [anchorUploadRight, setAnchorUploadRight] = useState(null);
  const dataRefLeft = useRef();
  const dataRefRight = useRef();

  const setPhotoLeft = (value) => {
    setFileLeft(null);
    dispatch(setInputData({ name: "national_filename", value }));
  };

  const setPhotoRight = (value) => {
    setFileRight(null);
    dispatch(setInputData({ name: "handheld_filename", value }));
  };

  const handleAnchorUploadLeft = (event) => {
    setAnchorUploadLeft(event.currentTarget);
  };

  const handleAnchorUploadRight = (event) => {
    setAnchorUploadRight(event.currentTarget);
  };

  const handleAnchorUploadLeftClose = () => {
    setAnchorUploadLeft(null);
    document.body.style.overflow = "unset";
    document.body.style.paddingRight = "0";
  };

  const handleAnchorUploadRightClose = () => {
    setAnchorUploadRight(null);
    document.body.style.overflow = "unset";
    document.body.style.paddingRight = "0";
  };

  const handlerFileLeft = (e) => {
    setPhotoLeft(null);
    setAnchorUploadLeft(null);
    setFileLeft(e.target.files[0]);
  };

  const removeFileExistsLeft = () => {
    setFileLeft(null);
    dataRefLeft.current.value = "";
  };

  const handlerFileRight = (e) => {
    setPhotoRight(null);
    setAnchorUploadRight(null);
    setFileRight(e.target.files[0]);
  };

  const removeFileExistsRight = () => {
    setFileRight(null);
    dataRefRight.current.value = "";
  };

  const handleModalCameraLeft = () => {
    setModalCameraLeft(true);
    handleAnchorUploadLeftClose();
  };

  const handleModalCameraRight = () => {
    setModalCameraRight(true);
    handleAnchorUploadRightClose();
  };

  const swapUploadLeft = Boolean(anchorUploadLeft);
  const idUploadLeft = swapUploadLeft ? "simple-popover" : undefined;

  const swapUploadRight = Boolean(anchorUploadRight);
  const idUploadRight = swapUploadRight ? "simple-popover" : undefined;

  // useEffect(() => {
  //     console.log(Boolean(anchorUploadLeft))
  // }, [anchorUploadLeft])

  return (
    <div className="bsa-registration">
      <div className="card">
        <div className="card-body">
          <div className="bsa-proof-identity">
            <h6>{"UPLOAD_PHOTO_HEADING_TXT".tr()}</h6>
            <p className="bsa-proof-identity-desc">
              {"UPLOAD_PHOTO_DESC_TXT".tr()}
            </p>
            <div className="bsa-proof-identity-upload">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div
                    className="bsa-proof-identity-upload-left"
                    onClick={handleAnchorUploadLeft}
                  >
                    {filesLeft ? (
                      <div className="bsa-pict-select">
                        <span className="Filename">
                          <img
                            src={URL.createObjectURL(filesLeft)}
                            alt={filesLeft.name}
                            className="img-fluid"
                          />
                          <Button
                            variant="primary"
                            className="btn-md btn-rounded"
                            onClick={removeFileExistsLeft}
                          >
                            <CloseIcon
                              className="fill-white"
                              fontSize="small"
                            />
                          </Button>
                        </span>
                      </div>
                    ) : national_filename.value ? (
                      <div className="bsa-pict-select">
                        <span className="Filename">
                          <img
                            src={national_filename.value}
                            alt={"id"}
                            className="img-fluid"
                          />
                          <Button
                            variant="primary"
                            className="btn-md btn-rounded"
                            onClick={() => setPhotoLeft(null)}
                          >
                            <CloseIcon
                              className="fill-white"
                              fontSize="small"
                            />
                          </Button>
                        </span>
                      </div>
                    ) : (
                      <>
                        <p className="mb-0">
                          {"UPLOAD_PHOTO_CAPTION_TXT".tr()}
                        </p>
                        <img src={PicLeft} alt="Identity" width={100} />
                      </>
                    )}
                  </div>
                  <ModalCameraLeft
                    setPhotoLeft={setPhotoLeft}
                    show={modalCameraLeft}
                    onHide={() => setModalCameraLeft(false)}
                  />
                  <Popover
                    id={idUploadLeft}
                    open={swapUploadLeft}
                    anchorEl={anchorUploadLeft}
                    onClose={handleAnchorUploadLeftClose}
                    className="bsa-popover bsa-popover-round"
                    elevation={0}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                  >
                    <ButtonMUI
                      className="bsa-popover-upload"
                      onClick={() => handleModalCameraLeft()}
                    >
                      <p className="bsa-popover-upload-text">
                        {"TAKE_PHOTO_TXT".tr()}
                      </p>
                      <CameraAltIcon className="fill-white" fontSize="small" />
                    </ButtonMUI>
                    <ButtonMUI
                      variant="contained"
                      component="label"
                      className="bsa-popover-upload"
                    >
                      <input
                        hidden
                        accept="image/*"
                        onChange={handlerFileLeft}
                        ref={dataRefLeft}
                        type="file"
                      />
                      <p className="bsa-popover-upload-text">
                        {"PICK_PHOTO".tr()}
                      </p>
                      <FolderOpenIcon className="fill-white" fontSize="small" />
                    </ButtonMUI>
                  </Popover>
                </div>
                <div className="col-12 col-md-6">
                  <div
                    className="bsa-proof-identity-upload-right"
                    onClick={handleAnchorUploadRight}
                  >
                    {filesRight ? (
                      <div className="bsa-pict-select">
                        <span className="Filename">
                          <img
                            src={URL.createObjectURL(filesRight)}
                            alt={filesRight.name}
                            className="img-fluid"
                          />
                          <Button
                            variant="primary"
                            className="btn-md btn-rounded"
                            onClick={removeFileExistsRight}
                          >
                            <CloseIcon
                              className="fill-white"
                              fontSize="small"
                            />
                          </Button>
                        </span>
                      </div>
                    ) : handheld_filename.value ? (
                      <div className="bsa-pict-select">
                        <span className="Filename">
                          <img
                            src={handheld_filename.value}
                            alt={"id with selfie"}
                            className="img-fluid"
                          />
                          <Button
                            variant="primary"
                            className="btn-md btn-rounded"
                            onClick={() => setPhotoRight(null)}
                          >
                            <CloseIcon
                              className="fill-white"
                              fontSize="small"
                            />
                          </Button>
                        </span>
                      </div>
                    ) : (
                      <>
                        <p className="mb-0">
                          {"UPLOAD_SELFIE_CAPTION_TXT".tr()}
                        </p>
                        <img src={PicRight} alt="Identity" width={100} />
                      </>
                    )}
                  </div>
                  <ModalCameraRight
                    setPhotoRight={setPhotoRight}
                    show={modalCameraRight}
                    onHide={() => setModalCameraRight(false)}
                  />
                  <Popover
                    id={idUploadRight}
                    open={swapUploadRight}
                    anchorEl={anchorUploadRight}
                    onClose={handleAnchorUploadRightClose}
                    className="bsa-popover bsa-popover-round"
                    elevation={0}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                  >
                    <ButtonMUI
                      className="bsa-popover-upload"
                      onClick={() => handleModalCameraRight()}
                    >
                      <p className="bsa-popover-upload-text">
                        {"TAKE_PHOTO_TXT".tr()}
                      </p>
                      <CameraAltIcon className="fill-white" fontSize="small" />
                    </ButtonMUI>
                    <ButtonMUI
                      variant="contained"
                      component="label"
                      className="bsa-popover-upload"
                    >
                      <input
                        hidden
                        accept="image/*"
                        onChange={handlerFileRight}
                        ref={dataRefRight}
                        type="file"
                      />
                      <p className="bsa-popover-upload-text">
                        {"PICK_PHOTO".tr()}
                      </p>
                      <FolderOpenIcon className="fill-white" fontSize="small" />
                    </ButtonMUI>
                  </Popover>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequestStepProofIdentity;

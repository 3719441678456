import { useSelector } from "react-redux";
import userSlice from "../../../store/userSlice";

export function useCurrency() {
  const { active } = useSelector((state) => state.languageStore);
  const { bank_account } = useSelector(userSlice.selector.userData);

  const getAccountInfo = (account_id) => {
    const search = bank_account.find((ba) => ba.account_id === account_id);
    if (search) {
      return search.bank_name + " - " + search.emp_acc_number;
    } else {
      return "Invalid account";
    }
  };

  const currencyFormat = (number) => {
    if (!number) {
      return "0.";
    }
    return number.toLocaleString(active);
  };

  const currencyFormatWithPrefix = (number) => {
    if (!number) {
      return "IDR 0.";
    }
    return "IDR " + number.toLocaleString(active);
  };

  const dateFormat = (date) => {
    if (!new Date(date)) {
      return "invalid number";
    }
    return new Date(date).toLocaleDateString(active);
  };

  const currencyFormatBahtWithPrefix = (number) => {
    if (!number) {
      return "THB 0.";
    }

    number = number * 0.002292;
    return "THB " + number.toLocaleString(active);
  };

  const currencyFormatKyatWithPrefix = (number) => {
    if (!number) {
      return "MMK 0.";
    }

    number = number * 0.14;
    return "MMK " + number.toLocaleString(active);
  };

  const currencyFormatRielWithPrefix = (number) => {
    if (!number) {
      return "KHR 0.";
    }

    number = number * 0.2593;
    return "KHR " + number.toLocaleString(active);
  };

  const currencyFormatKipWithPrefix = (number) => {
    if (!number) {
      return "LAK 0.";
    }

    number = number * 1.336;
    return "LAK " + number.toLocaleString(active);
  };

  return {
    currencyFormat,
    getAccountInfo,
    dateFormat,
    currencyFormatWithPrefix,
    currencyFormatBahtWithPrefix,
    currencyFormatKyatWithPrefix,
    currencyFormatRielWithPrefix,
    currencyFormatKipWithPrefix,
  };
}

import React from 'react'
import { Link } from 'react-router-dom'
import { Dashboard } from '@mui/icons-material';

function Sidebars() {
  return (
    <div className='bsa-dashboard-content-sidebar-box'>
        <Link to={"/dashboard"} alt="Sidebar Menu" className='active'>
            <Dashboard className='fill-primary' />
        </Link>
    </div>
  )
}

export default Sidebars
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import userSlice from '../../store/userSlice';
import { getStoredData } from '../../store/userSlice/service';

export default function ProtectedRoute({ children }) {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { accessToken, userInfo: { employee_id, is_password_change } } = useSelector(userSlice.selector.all)
    
    useEffect(() => {
        if(!localStorage.getItem('bsa-info')){
            navigate('/signin', { replace: true })
        } else {
            if(!employee_id){
                dispatch(getStoredData())
            } else {
                if(!is_password_change){
                    navigate('/signin', { replace: true })
                }
            }
        }
    },[dispatch, navigate, accessToken, employee_id, is_password_change])

    return children
}

import React, { useEffect, useState } from 'react'
import Password from '../../../../assets/img/img_set_password.png'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FormControl, IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux'
import userSlice from '../../../../store/userSlice';


function PartChangePassword({ setPage, setFormData, formError, setFormError }) {

    const { employee_code } = useSelector(userSlice.selector.userInfo)
    const [values, setValues] = useState({
        showPassword: false,
        showCurrentPassword: false,
        showConfirmPassword: false,
        currentPass: '',
        newPass: '',
        confirmPass: '',
    });

    const editpass = (name, value) => {
        setValues({
            ...values,
            [name]: value
        })
    }
    
    const handleClickShowCurrentPassword = () => {
        setValues({
            ...values,
            showCurrentPassword: !values.showCurrentPassword,
        });
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleClickShowConfirmPassword = () => {
        setValues({
            ...values,
            showConfirmPassword: !values.showConfirmPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async () => {
        setFormData({
            employee_code,
            currentPass: values.currentPass,
            password: values.newPass,
            new_password: values.confirmPass
        })
        setPage(2);
    }

    const checkErrorForm = () => {
        let isError = false
        if(!values.newPass || !values.confirmPass || !values.currentPass){
            isError = true
            setFormError({
                hasError: true,
                errorMessage: ""
            })
        }
        //
        if(values.currentPass){
            if(values.currentPass.length < 8){
                isError = true
                setFormError({
                    hasError: true,
                    errorMessage: "PASSWORD_MIN_LENGTH_ERROR_TXT".tr()
                })
            }
        }
        //
        if(values.newPass){
            if(values.newPass.length < 8){
                isError = true
                setFormError({
                    hasError: true,
                    errorMessage: "PASSWORD_MIN_LENGTH_ERROR_TXT".tr()
                })
            }
        }
        //
        if(values.confirmPass){
            if(values.confirmPass.length < 8){
                isError = true
                setFormError({
                    hasError: true,
                    errorMessage: "PASSWORD_MIN_LENGTH_ERROR_TXT".tr()
                })
            }
        }
        //
        if(values.newPass && values.confirmPass){
            if(values.newPass !== values.confirmPass){
                isError = true
                setFormError({
                    hasError: true,
                    errorMessage: "PASSWORD_MATCH_ERROR_TXT".tr()
                })
            }
        }
        if(!isError){
            setFormError({
                hasError: false,
                errorMessage: ''
            })
        }
        return isError
    }

    useEffect(() => {
        checkErrorForm()
        // eslint-disable-next-line
    }, [values])

    return (
        <div className='bsa-change-password'>
            <div className='bsa-request'>
                <div className='bsa-change-password-home mb-3' align="center">
                    <img src={Password} alt="Password Confirm" />
                </div>
                <p className='bsa-change-password-titlenew'>{'FIRST_CHANGE_PASSWORD_TXT'.tr()}</p>
                <p className="bsa-label-default mb-2 mt-3">{"EMP_ID_TXT".tr()}</p>
                <FormControl variant="outlined" fullWidth>
                    <OutlinedInput
                        value={employee_code}
                        disabled
                        name="employee_code"
                        placeholder={""}
                        className="bsa-form-password"
                    />
                </FormControl>
                <p className="bsa-label-default mb-2 mt-3">{"CURRENT_PASSWORD_TXT".tr()}</p>
                <FormControl variant="outlined" fullWidth>
                    <OutlinedInput
                        value={values.currentPass}
                        type={values.showCurrentPassword ? 'text' : 'password'}
                        name="bsaPassword"
                        placeholder={"CURRENT_PASSWORD_CAPTION_TXT".tr()}
                        className="bsa-form-password"
                        endAdornment={
                            <InputAdornment position="start">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowCurrentPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        onChange={(e) => editpass('currentPass', e.target.value)}
                    />
                </FormControl>
                <p className="bsa-label-default mb-2 mt-3">{"NEW_PASSWORD_TXT".tr()}</p>
                <FormControl variant="outlined" fullWidth>
                    <OutlinedInput
                        value={values.newPass}
                        type={values.showPassword ? 'text' : 'password'}
                        name="bsaPassword"
                        placeholder={"NEW_PASSWORD_CAPTION_TXT".tr()}
                        className="bsa-form-password"
                        endAdornment={
                            <InputAdornment position="start">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        onChange={(e) => editpass('newPass', e.target.value)}
                    />
                </FormControl>
                <p className="bsa-label-default mb-2 mt-3">{"CONFIRM_PASSWORD_TXT".tr()}</p>
                <FormControl variant="outlined" fullWidth>
                    <OutlinedInput
                        value={values.confirmPass}
                        type={values.showConfirmPassword ? 'text' : 'password'}
                        name="bsaConfirmPassword"
                        placeholder={"CONFIRM_PASSWORD_CAPTION_TXT".tr()}
                        className="bsa-form-password"
                        endAdornment={
                            <InputAdornment position="start">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        onChange={(e) => editpass('confirmPass', e.target.value)}
                    />
                </FormControl>
            </div>
            {
                formError.hasError ?
                    <p className="bsa-label-default text-danger m-0 mt-2">{formError.errorMessage}</p>
                :
                    <></>
            }
            
            <Button disabled={formError.hasError} onClick={handleSubmit} variant='primary' className='w-100 mt-3'>
                Confirmation
            </Button>
        </div>
    )
}

export default PartChangePassword
import React from 'react'
import { Modal } from 'react-bootstrap'
import PartRequestPasswordModal from './partRequestPassword';
import PartRequestSuccessModal from './partRequestSuccess';

function ModalPassword({ show, onHide, action, loading }) {
    const [page, setPage] = React.useState(1);

    let MovePage;

    if(page === 1) {
        MovePage = <PartRequestPasswordModal action={action} loading={loading ? loading : false} onHide={onHide} setPage={setPage} />
    }
    else if(page === 2) {
        MovePage = <PartRequestSuccessModal onHide={onHide} />
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className='p-4'>
                {MovePage}
            </Modal.Body>
        </Modal>
    )
}

export default ModalPassword
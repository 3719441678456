// import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FormControl, InputAdornment, OutlinedInput } from "@mui/material";
import { PhoneAndroid, Person } from "@mui/icons-material";
import ModalForgotPassword from "./modalForgotPassword";
import { useDispatch } from 'react-redux'
import { resetPass } from "../../../../store/userSlice/service";

// import userSlice from "../../../../store/userSlice";
// import { signInUser } from "../../../../store/userSlice/service";

export default function FormForgotPassword({ setPage }) {
    const dispatch = useDispatch()
    // const navigate = useNavigate()
    // const { loading } = useSelector(userSlice.selector.userInfo)
    const [modalFP, setModalFP] = useState(false);
	const [values, setValues] = useState({
        // bsaPhoneNumber: "087788437520",
        // bsaCode: "BEEVERTH001",
	});
    const [loading, setLoading] = useState(false)
    
	const handleChange = (event) => {
		setValues({
			...values,
			[event.target.name]: event.target.value,
		});
	};

    const submitForm = async(e) => {
        e.preventDefault();
        setLoading(true)
        dispatch(resetPass(values.bsaCode, values.bsaPhoneNumber, (data) => {
            if(data){
                setModalFP(true);
            }
            setLoading(false)
        }))
    }

    return (
        <form onSubmit={submitForm} className="bsa-form-signin mb-4">
            <ModalForgotPassword show={modalFP} onHide={() => setModalFP(false)} setPage={setPage} />
            <p className='text-primary fw-bolder fs-6 mt-4 text-center mb-4'>
                {'FORGOT_PASSWORD_CAPTION_TXT'.tr()}
            </p>
            <div className="row">
                <div className="col-12 mb-3">
                    <p className="bsa-text-label-form mb-2">{"EMP_ID_TXT".tr()}</p>
                    <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                            value={values.bsaCode}
                            type="text"
                            onChange={handleChange}
                            name="bsaCode" 
                            placeholder={"EMPLOYEE_ID_CAPTION_TXT".tr()}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Person />
                                </InputAdornment>
                            }                            
                        />
                    </FormControl>
                </div>
                <div className="col-12 mb-3">
                    <p className="bsa-text-label-form mb-2">{"PHONE_NUMBER_TXT".tr()}</p>
                    <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                            value={values.bsaPhoneNumber}
                            type="text" 
                            onChange={handleChange}
                            name="bsaPhoneNumber" 
                            placeholder={"PHONE_NUMBER_CAPTION_TXT".tr()}
                            startAdornment={
                                <InputAdornment position="start">
                                    <PhoneAndroid />
                                </InputAdornment>
                            }                            
                        />
                    </FormControl>
                </div>
            </div>
            <div className="mt-3">
                <Button disabled={loading} variant="primary" className="w-100 pt-2 pb-2" type="submit">
                    {"RESET_PASSWORD_CAPTION_TXT".tr()}
                </Button>
            </div>
            <p className="mt-2">
                <Button disabled={loading} variant="link" className="bsa-text-forgot-password w-100 text-center" onClick={() => setPage(1)}>
                    {"BACK_SIGN_IN_CAPTION_TXT".tr()}
                </Button>
            </p>
        </form>
	);
}
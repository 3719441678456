import { CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CloseButton } from 'react-bootstrap'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import LogoSmartek from '../../../../assets/img/logo.png'
import { getRequestDetail } from '../../../../store/sarequestSlice/service'
import { addToast } from '../../../../store/toastSlice'
import { useCurrency } from '../../../hooks/currency'

function ModalDetailHistory({ show, onHide, detailId }) {

    const dispatch = useDispatch()
    const { currencyFormatWithPrefix, currencyFormatBahtWithPrefix, dateFormat } = useCurrency()
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [amount, setAmount] = useState(0)
    
    useEffect(() => {
        if(detailId){
            setLoading(true)
            dispatch(getRequestDetail(detailId, (data, err) => {
                if(err){
                    dispatch(addToast({ title: 'Error', message: err.message}))
                    onHide()
                }
                if(data){
                    setLoading(false)
                    setData(data)
                }
            }))
        }
    /* eslint-disable */
    },[detailId, dispatch])

    useEffect(() => {
        if(data && data.request_amt){
            let amt = Number(data.request_amt)
            for(let i = 0; i < data.fee.length; i++){
                amt -= Number(data.fee[i].fee_amount)
            }
            setAmount(amt)
        }
        /* eslint-disable */
    },[data])

    // if(loading){
    //     return <div className='d-flex justify-content-center'><CircularProgress /></div>
    // }

    // const { total_fee, fee, data, bank_name, bank_account, request_amt } = data

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {
                loading?
                    <div className='d-flex justify-content-center p-5'><CircularProgress /></div>
                :
                    <Modal.Body className='p-4'>
                        <div className='bsa-history'>
                            <div align="right">
                                <CloseButton onClick={onHide} />
                            </div>
                            <p className='bsa-history-title mb-3'>Detail History Transaction</p>
                            <div className='bsa-history-box mb-3'>
                                <div className='bsa-history-box-card'>
                                    <div className='bsa-history-box-card-head'>
                                        <div className='d-flex align-items-center'>
                                            <img src={LogoSmartek} alt={"Smartek"} width={40} />
                                            <div className='ms-3'>
                                                <p className='mb-0 bsa-history-box-card-second'>Salary Advance</p>
                                                <p className='mb-0 bsa-history-box-card-first'>{dateFormat(data.data[0].createTime)}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='bsa-history-box-card-content'>
                                        <p className='mb-0 bsa-history-box-card-first'>Total Receiving</p>
                                        <p className='mb-0 bsa-history-box-card-second'>{currencyFormatWithPrefix(Number(amount))}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='bsa-history-detail p-3'>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <p className='bsa-history-detail-left font-12'>Receiving Account</p>
                                    <div>
                                        <p className='mb-0 bsa-history-detail-right font-13'>{data.bank_account}</p>
                                        <p className='bsa-history-detail-right font-13'>{data.bank_name}</p>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <p className='bsa-history-detail-left font-12'>Reguler Top Up</p>
                                    <p className='bsa-history-detail-right font-13'>Salary Advance</p>
                                </div>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <p className='bsa-history-detail-left font-12'>Your Salary Advance Amount</p>
                                    <p className='bsa-history-detail-right font-13'>{currencyFormatWithPrefix(Number(data.request_amt))}</p>
                                </div>
                                {
                                    data.fee.map((fees, feeId) => {
                                        return (
                                            <div key={feeId} className='d-flex align-items-center justify-content-between'>
                                                <p className='mb-0 bsa-history-detail-left font-12'>{fees.fee_label}</p>
                                                <p className='mb-0 bsa-history-detail-right font-13'>{currencyFormatWithPrefix(Number(fees.fee_amount))}</p>
                                            </div>
                                        )
                                    })
                                }
                                <hr />
                                {
                                    data.data[0].orderStatus === 'Failed' ?
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <p className='mb-0 bsa-history-detail-last-left font-12'>Status</p>
                                            <p className='mb-0 bsa-history-detail-last-right font-13'>{data.data[0].orderStatus}</p>
                                        </div>
                                    :
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <p className='mb-0 bsa-history-detail-last-left font-12'>Total Receiving</p>
                                            <p className='mb-0 bsa-history-detail-last-right font-13'>{currencyFormatWithPrefix(Number(amount))}</p>
                                        </div>
                                }
                                <div className='d-flex align-items-center justify-content-between'>
                                    <p className='mb-0 bsa-history-detail-left font-12'>Detail</p>
                                    <p className='mb-0 bsa-history-detail-left font-12'>{data.data[0].orderStatusDesc}</p>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
            }
        </Modal>
    )
}

export default ModalDetailHistory
import React from 'react'
import { FormControl, IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button, CloseButton } from 'react-bootstrap';
import { useDispatch } from 'react-redux'
import Password from '../../../../assets/img/password_confirm.png'
import { setRegularSaRequest, setSaRequest } from '../../../../store/inputSlice';

function PartRequestPasswordModal({ onHide, setPage, action, loading }) {
    
    const dispatch = useDispatch()
    
    const [values, setValues] = React.useState({
        showPassword: false,
    });

    const handlePassword = (e) => {
        dispatch(setSaRequest({ name: 'password', value: e.target.value}))
        dispatch(setRegularSaRequest({ name: 'password', value: e.target.value}))
    }

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = () => {
        if(action){
            action(() => {
                setPage(2)
            })
        }
    }

    return (
        <div className='bsa-change-password'>
            <div className='bsa-close-modal-btn' align="right">
                <CloseButton onClick={onHide} />
            </div>
            <div className='bsa-request'>
                <p className='bsa-request-title mb-3'>{'CONFIRM_PASSWORD_TTL_TXT'.tr()}</p>
                <div className='mb-3' align="center">
                    <img src={Password} alt="Password Confirm" width={"70%"} />
                </div>
                <p className="bsa-label-default mb-2">{"PASSWORD_TXT".tr()}</p>
                <FormControl variant="outlined" fullWidth>
                    <OutlinedInput
                        type={values.showPassword ? 'text' : 'password'}
                        name="bsaPassword"
                        placeholder={"ENTER_PASSWORD_CAPTION_TXT".tr()}
                        className="bsa-form-password"
                        onChange={handlePassword}
                        endAdornment={
                            <InputAdornment position="start">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </div>
            <Button disabled={loading} onClick={handleSubmit} variant='primary' className='w-100 mt-3 pt-2 pb-2 mt-5'>
                {"CONFIRM_TXT".tr()}
            </Button>
        </div>
    )
}

export default PartRequestPasswordModal
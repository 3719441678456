import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { addToast } from '../../../../store/toastSlice';
import { changePass, signOut } from '../../../../store/userSlice/service';

function PartConfirmation({ formData, setPage, onHide }) {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const handleSubmit = () => {
        const { currentPass, new_password } = formData
        setLoading(true)
        dispatch(changePass(currentPass, new_password, (data, err) => {
            if(data){
                dispatch(addToast({ title: 'PASSWORD_CHANGED_TXT'.tr(), message: "SET_PASSWORD_EMAIL_SUCCESS_DESC_TXT".tr() }))
                dispatch(signOut())
                onHide(false)
            }
            if(err){
                dispatch(addToast({ title: 'Error', message: err.message }))
                setPage(1)
            }
            setLoading(false)
        }))
    }

    return (
        <div>
            <p className='bsa-change-password-titlenew'>{"CONFIM_PASSWORD_CHANGE_TXT".tr()}</p>
            <p className='bsa-change-password-descnew'>{"CONFIM_PASSWORD_CHANGE_CAPTION_TXT".tr()}</p>
            <div className='row'>
                <div className='col-6'>
                    <Button disabled={loading} onClick={() => setPage(1)} variant='light' className='w-100 pt-2 pb-2'>
                        {"SA_REGULAR_NO_TXT".tr()}
                    </Button>
                </div>
                <div className='col-6'>
                    <Button disabled={loading} onClick={handleSubmit} variant='primary' className='w-100 pt-2 pb-2'>
                        {"SA_REGULAR_YES_TXT".tr()}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default PartConfirmation
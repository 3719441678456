import {
  Autocomplete,
  FormControl,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Modal, CloseButton } from "react-bootstrap";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { addAccount } from "../../../../store/serviceSlice/service";
import serviceSlice from "../../../../store/serviceSlice";
import ClearIcon from "@mui/icons-material/Clear";

function ModalBankAccount(props) {
  const dispatch = useDispatch();
  const { bank, emoney } = useSelector(serviceSlice.selector.bank);
  const [loading, setLoading] = useState(false);
  const [type, setType] = React.useState("");
  const [accountList, setAccountList] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [values, setValues] = useState({
    accountName: "",
    accountNumber: "",
    bank_code: "",
    bank_name: "",
  });

  const handleType = (event) => {
    setType(event.target.value);
  };

  const handleAccount = (_, value) => {
    if (type === "emoney") {
      let searchAccount = emoney.find((sa) => sa.bank_name === value);
      setValues({
        ...values,
        bank_code: searchAccount.bank_code,
        bank_name: searchAccount.bank_name,
      });
    } else if (type === "bank") {
      let searchAccount = bank.find((sa) => sa.bank_name === value);
      setValues({
        ...values,
        bank_code: searchAccount.bank_code,
        bank_name: searchAccount.bank_name,
      });
    }
  };

  const handleChangeValues = (type, value) => {
    setValues({
      ...values,
      [type]: value,
    });
  };

  const handleAddAccount = async () => {
    const result = await dispatch(
      addAccount(
        {
          bank_code: values.bank_code,
          bank_name: values.bank_name,
          emp_acc_name: values.accountName,
          emp_acc_number: values.accountNumber,
        },
        setLoading
      )
    );
    if (result) {
      props.onHide();
    }
  };

  useEffect(() => {
    // console.log(type);
    if (type === "emoney") {
      // console.log('emoney <---');
      setAccountList(emoney);
    } else if (type === "bank") {
      // console.log('bank <---');
      setAccountList(bank);
    }
    setValues({
      ...values,
      bank_code: "",
      bank_name: "",
    });
    /* eslint-disable */
  }, [type, emoney, bank, setAccountList]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="p-4">
        <div align="right">
          <CloseButton onClick={props.onHide} />
        </div>
        <div className="bsa-bank-account">
          <p className="bsa-bank-account-title mb-3">
            {"DISBURSE_ADD_ACC_TTL_TXT".tr()}
          </p>
          <p className="bsa-label-default mb-2">
            {"DISBURSE_ACC_TYPE_TXT".tr()}
          </p>
          <FormControl fullWidth className="mb-3">
            <Select
              displayEmpty
              IconComponent={KeyboardArrowDown}
              value={type}
              onChange={handleType}
              className={type === "" ? "bsa-unselect-mui" : ""}
            >
              <MenuItem disabled={true} value={""}>
                {"DISBURSE_ACC_TYPE_CAPTION_TXT".tr()}
              </MenuItem>
              <MenuItem value={"bank"}>{"Bank".tr()}</MenuItem>
              <MenuItem value={"emoney"}>{"Emoney".tr()}</MenuItem>
            </Select>
          </FormControl>
          {/* {type === "bank" && (
            <>
              <p className="bsa-label-default mb-2">
                {"DISBURSE_ACC_BANK_COUNTRY_TXT".tr()}
              </p>
              <FormControl fullWidth className="mb-3">
                <Select
                  displayEmpty
                  IconComponent={KeyboardArrowDown}
                  value={""}
                  onChange={handleType}
                  className={"" === "" ? "bsa-unselect-mui" : ""}
                >
                  <MenuItem disabled={true} value={""}>
                    {"DISBURSE_ACC_BANK_COUNTRY_CAPTION_TXT".tr()}
                  </MenuItem>
                  <MenuItem value={"my"}>{"MYANMAR_TXT".tr()}</MenuItem>
                  <MenuItem value={"th"}>{"THAILAND_TXT".tr()}</MenuItem>
                  <MenuItem value={"kh"}>{"CAMBODIA_TXT".tr()}</MenuItem>
                </Select>
              </FormControl>
            </>
          )} */}

          <p className="bsa-label-default mb-2">
            {"DISBURSE_ACC_TTL_TXT".tr()}
          </p>
          <FormControl fullWidth className="mb-3">
            <Autocomplete
              // popupIcon={KeyboardArrowDown}
              id="combo-box-demo"
              options={accountList.map((accList) => accList.bank_name)}
              value={values.bank_name}
              className={"bsa-unselect-mui"}
              onChange={handleAccount}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={
                    isFocused || values.bank_name
                      ? null
                      : "DISBURSE_ACC_BANK_CAPTION_TXT".tr()
                  }
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  InputProps={{
                    ...params.InputProps,
                    style: { fontSize: 12, fontWeight: "400" },
                    disableUnderline: true,
                    endAdornment: values.bank_name ? (
                      <div>
                        <IconButton
                          onClick={() => {
                            setValues({
                              ...values,
                              bank_code: "",
                              bank_name: "",
                            });
                          }}
                          size="small"
                        >
                          <ClearIcon />
                        </IconButton>
                        <KeyboardArrowDown />
                      </div>
                    ) : (
                      <div>
                        <KeyboardArrowDown />
                      </div>
                    ),
                    // endAdornment: (
                    //   <InputAdornment position="start">
                    //     <FlightTakeoffIcon style={{ color: "#ECB72C" }} />
                    //   </InputAdornment>
                    // ),
                  }}
                  // InputProps={{
                  //   endAdornment: <KeyboardArrowDown />,
                  // }}
                />
              )}
            />
          </FormControl>
          {/* <FormControl fullWidth className="mb-3">
            <Select
              displayEmpty
              IconComponent={KeyboardArrowDown}
              value={values.bank_code}
              onChange={handleAccount}
              className={values.bank_code === "" ? "bsa-unselect-mui" : ""}
            >
              <MenuItem disabled={true} value={""}>
                {"DISBURSE_ACC_BANK_CAPTION_TXT".tr()}
              </MenuItem>
              {accountList.map((singleAccount) => {
                return (
                  <MenuItem
                    key={singleAccount.bank_code}
                    value={singleAccount.bank_code}
                  >
                    {singleAccount.bank_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl> */}
          <p className="bsa-label-default mb-2">
            {"DISBURSE_ACC_NAME_TXT".tr()}
          </p>
          <FormControl variant="outlined" fullWidth>
            <OutlinedInput
              type="text"
              name="bsaAccountName"
              placeholder={"DISBURSE_ACC_NAME_CAPTION_TXT".tr()}
              className="mb-3"
              value={values.accountName}
              onChange={(e) =>
                handleChangeValues("accountName", e.target.value)
              }
            />
          </FormControl>
          <p className="bsa-label-default mb-2">
            {"DISBURSE_ACC_NUMBER_TXT".tr()}
          </p>
          <FormControl variant="outlined" fullWidth>
            <OutlinedInput
              type="text"
              name="bsaAccountNum"
              placeholder={"DISBURSE_ACC_NUMBER_CAPTION_TXT".tr()}
              className="mb-3"
              value={values.accountNumber}
              onChange={(e) => {
                const newValue = e.target.value.replace(/\D/g, "");
                handleChangeValues("accountNumber", newValue);
              }}
            />
          </FormControl>
          <Button
            disabled={loading}
            onClick={handleAddAccount}
            variant="primary"
            className="w-100 mt-3 pt-2 pb-2 mt-5"
          >
            {"SAVE_TXT".tr()}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalBankAccount;

// Need to use the React-specific entry point to allow generating React hooks
import {
  clearUserData,
  setAccessToken,
  setAuthorization,
  setCompanyToken,
  setLoading,
  setLoadingUserInfo,
  setUserData,
  setUserInfo,
} from ".";
import { axiosFailedMiddleware, baseAxios } from "../configs";
import { setInputData, setRegularSaRequest, setSaRequest } from "../inputSlice";
import { setActiveAccount } from "../serviceSlice";
import { addToast } from "../toastSlice";

export const getAccessToken = () => {
  return (dispatch, getState) => {
    try {
      if (!localStorage.getItem("bsa-act")) {
        dispatch(setLoading(true));
        return baseAxios
          .post("/v2/auth/token", {
            company_code: "PIKKAT",
            // UAT
            // client_id: "vL6jfeaxGk8pKrrM7uhH",
            // client_secret: "ao14XY4gY8YGjI8VMZb9",
            //   PROD
            client_id: "Y2fRAmGeuJwYrw2QNJJjurPaTpkDmw",
            client_secret: "M3SA3dua8Wea18WAMuabuCnfDZHDrT",
          })
          .then(({ data }) => {
            dispatch(setAccessToken(data.access_token));
          });
      } else {
        dispatch(setAccessToken(localStorage.getItem("bsa-act")));
      }
    } catch (err) {
      // console.log(err.message)
    } finally {
      dispatch(setLoading(false));
    }
  };
};

// getAccessToken example result
//
// {
//   "access_token": "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJvYXV0aF90b2tlbiIsImNvbXBhbnlDb2RlIjoiQ09NUDAxIiwiaWF0IjoxNjU5MzI4MTUzLCJleHAiOjE2NTk0MTQ1NTN9.CmBpNye69whsrZ0hDU6xKjxT6HdfRV-uMS0e6m7j6_xGTDgN4otIqMC0LdtRbfKKhEhbewNpGITHUMsw585S1Q",
//   "expires_in": "2022-08-02T04:29:13.192+00:00",
//   "token_type": "Bearer",
//   "code": "0",
//   "message": "Success"
// }

export const getAuthToken = () => {
  return (dispatch, getState) => {
    try {
      dispatch(setAuthorization(localStorage.getItem("bsa-ath")));
    } catch (err) {
      // console.log(err.message)
    }
  };
};

export const signInUser = ({ employee_code, mobile_phone, password }) => {
  return async (dispatch, getState) => {
    dispatch(setLoadingUserInfo(true));
    return await baseAxios
      .post(
        "/v2/api/auth/signin",
        { employee_code, mobile_phone, password },
        {
          headers: {
            Authorization: "Bearer " + getState().userStore.accessToken,
            Company: getState().userStore.company_code,
          },
        }
      )
      .then(({ data }) => {
        try {
          let parsed = btoa(JSON.stringify(data.employee_info));
          localStorage.setItem("bsa-info", parsed);
          localStorage.setItem("bsa-ath", data.access_token);
          localStorage.setItem("bsa-ecc", data.parent_company_code);
          localStorage.setItem("bsa-ask", "true")
          dispatch(setUserInfo(data.employee_info));
          dispatch(
            setInputData({
              name: "mobile_phone",
              value: data.employee_info.mobile_phone,
            })
          );
          dispatch(
            setSaRequest({
              name: "employee_code",
              value: data.employee_info.employee_code,
            })
          );
          dispatch(
            setRegularSaRequest({
              name: "employee_id",
              value: data.employee_info.employee_id,
            })
          );
          dispatch(setCompanyToken(data.parent_company_code));
          // dispatch(setSaRequest({ name: 'account_id', value: data.employee_info.employee_id}))
          dispatch(setAccessToken(data.access_token));
          return data;
        } catch (err) {
          // console.log(err)
          return false;
        }
      })
      .catch(
        axiosFailedMiddleware((err) => {
          dispatch(addToast({ title: "Error", message: err.message }));
        }, dispatch)
      )
      .finally(() => {
        dispatch(setLoadingUserInfo(false));
      });
  };
};

export const signOut = () => {
  return async (dispatch, getState) => {
    dispatch(clearUserData());
    localStorage.removeItem("bsa-act");
    localStorage.removeItem("bsa-info");
    localStorage.removeItem("bsa-ath");
    localStorage.removeItem("bsa-ecc");
  };
};

export const getStoredData = () => {
  return async (dispatch) => {
    if (localStorage.getItem("bsa-info")) {
      let data = JSON.parse(atob(localStorage.getItem("bsa-info")));
      let companyCode = localStorage.getItem("bsa-ecc");
      dispatch(setCompanyToken(companyCode));
      dispatch(setUserInfo(data));
      dispatch(
        setSaRequest({ name: "employee_code", value: data.employee_code })
      );
      // dispatch(setSaRequest({ name: 'account_id', value: data.employee_id}))
      dispatch(setInputData({ name: "employee_id", value: data.employee_id }));
      dispatch(
        setRegularSaRequest({ name: "employee_id", value: data.employee_id })
      );
      dispatch(
        setInputData({ name: "employee_code", value: data.employee_code })
      );
      dispatch(
        setInputData({ name: "mobile_phone", value: data.mobile_phone })
      );
      dispatch(
        setInputData({
          name: "is_password_change",
          value: data.is_password_change,
        })
      );
    }
  };
};

// signin example result
//
// {
//     "code": "0",
//     "message": "Success",
//     "employee_info": {
//         "employee_id": 1140,
//         "full_name": "Hosea Tirtajaya",
//         "employee_code": "TESTUSER001",
//         "email": "hoseatirtajaya@gmail.com",
//         "company_name": "PT Beever Indonesia",
//         "mobile_phone": "088984487275",
//         "is_password_change": 1,
//         "is_email_confirm": 0,
//         "is_otp_confirm": 0
//     },
//     "access_token": "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJvYXV0aF90b2tlbiIsImNvbXBhbnlDb2RlIjoiQ09NUDAxIiwiZW1wbG95ZWVfaWQiOjExNDAsImlhdCI6MTY1OTMzNDc2NiwiZXhwIjoxNjU5NDIxMTY2fQ.48mRtmWQZGXxD60zIWC_pjNfvS-tGWCetunVr2NbJpgFPgx6wLi-yLkOblxFCcQocXr7Bc5y2CYVrgqd8mjwTg",
//     "expires_in": "2022-08-02T06:19:26.432+00:00",
//     "token_type": "Bearer",
//     "parent_company_code": "COMP01"
// }

export const getUserData = () => {
  return (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      return baseAxios
        .post(
          "/v2/api/info/sa",
          {
            employee_code: getState().userStore.userInfo.employee_code,
          },
          {
            headers: {
              Authorization: "Bearer " + getState().userStore.authorization,
              Company: getState().userStore.company_code,
            },
          }
        )
        .then(({ data }) => {
          if (data.code === "0") {
            console.log("data", data);
            dispatch(setUserData(data));
            if (data.bank_account.length) {
              if (localStorage.getItem("bsa-pai")) {
                const searchAccount = data.bank_account.find(
                  (ba) =>
                    ba.account_id.toString() === localStorage.getItem("bsa-pai")
                );
                if (searchAccount) {
                  dispatch(setActiveAccount(searchAccount));
                  dispatch(
                    setSaRequest({
                      name: "account_id",
                      value: searchAccount.account_id,
                    })
                  );
                  dispatch(
                    setRegularSaRequest({
                      name: "account_id",
                      value: searchAccount.account_id,
                    })
                  );
                } else {
                  localStorage.removeItem("bsa-pai");
                  dispatch(setSaRequest({ name: "account_id", value: null }));
                  dispatch(
                    setRegularSaRequest({ name: "account_id", value: null })
                  );
                  dispatch(setActiveAccount(null));
                }
              }
            } else {
              localStorage.removeItem("bsa-pai");
              dispatch(setSaRequest({ name: "account_id", value: null }));
              dispatch(
                setRegularSaRequest({ name: "account_id", value: null })
              );
              dispatch(setActiveAccount(null));
            }
          }
        })
        .catch(axiosFailedMiddleware(() => {}, dispatch));
    } catch (err) {
      // console.log(err.message)
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const changePass = (oldPass, newPass, cb) => {
  return (dispatch, getState) => {
    try {
      return baseAxios
        .post(
          "/v2/api/password/change",
          {
            employee_code: getState().userStore.userInfo.employee_code,
            password: oldPass,
            new_password: newPass,
          },
          {
            headers: {
              Authorization: "Bearer " + getState().userStore.authorization,
              Company: getState().userStore.company_code,
            },
          }
        )
        .then(({ data }) => {
          if (cb) {
            return cb(data, null);
          } else {
            return data;
          }
        })
        .catch((err) => {
          dispatch(addToast({ title: "Error", message: err.message }));
          if (cb) {
            return cb(null, err);
          } else {
            return err;
          }
        });
    } catch (err) {
      return false;
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const resetPass = (employee_code, mobile_phone, cb) => {
  return (dispatch, getState) => {
    try {
      return baseAxios
        .post(
          "/v2/api/password/forgot",
          { employee_code, mobile_phone },
          {
            headers: {
              Authorization: "Bearer " + getState().userStore.accessToken,
              Company: getState().userStore.company_code,
            },
          }
        )
        .then(({ data }) => {
          if (cb) {
            return cb(data, null);
          } else {
            return data;
          }
        })
        .catch((err) => {
          dispatch(addToast({ title: "Error", message: err.message }));
          if (cb) {
            return cb(null, err);
          } else {
            return err;
          }
        });
    } catch (err) {
      return false;
    } finally {
      dispatch(setLoading(false));
    }
  };
};

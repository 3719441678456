import * as React from "react";
// import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FormControl, InputAdornment, OutlinedInput } from "@mui/material";
import { PhoneAndroid, Person, Password } from "@mui/icons-material";
import { useSelector, useDispatch } from 'react-redux'

import userSlice from "../../../../store/userSlice";
import { signInUser } from "../../../../store/userSlice/service";
import ModalChangePassword from "./modalChangePassword";
// import Swal from "sweetalert2";

export default function FormSignIn({ show, onHide, setPage }) {
    const [statusPassword] = React.useState(false);
    const dispatch = useDispatch()
    // const navigate = useNavigate()
    const { loading } = useSelector(userSlice.selector.userInfo)
	const [values, setValues] = React.useState({
    //     bsaPhoneNumber: "089520769739",
    // bsaCode: "B0010",
    // bsaPassword: "Gyhbhn0838",
	});
    
    // bsaPhoneNumber: "089520769739",
    // bsaCode: "IRFAN",
    // bsaPassword: "Gyhbhn0838",

    // bsaPhoneNumber: "085697625379",
    // bsaCode: "ASD8",
    // bsaPassword: "Beeverid123456",

	const handleChange = (event) => {
		setValues({
			...values,
			[event.target.name]: event.target.value,
		});
	};

    const submitForm = async(e) => {
        e.preventDefault();
        // const result = 
        await dispatch(signInUser({
            employee_code: values.bsaCode,
            mobile_phone: values.bsaPhoneNumber,
            password: values.bsaPassword,
        }))
        if(statusPassword) {
            // if(result && result.code === '0'){
            //     navigate('/dashboard')
            //     // navigate('/dashboard', { replace: true })
            // }
        } 
        // else {
        //     setModalShowPassword(true);
        // }
    }

    return (
        <form onSubmit={submitForm} className="bsa-form-signin mb-4">
            <ModalChangePassword 
                show={show} 
                onHide={() => onHide(false)}
                bsaCode={values.bsaCode}
                bsaPhoneNumber={values.bsaPhoneNumber}
                bsaPassword={values.bsaPassword}
            />
            <p className='text-primary fw-bolder fs-6 mt-2'>
                {'SIGN IN'.tr()}
            </p>
            <div className="row">
                <div className="col-12 mb-3">
                    <p className="bsa-text-label-form mb-2">{"PHONE_NUMBER_TXT".tr()}</p>
                    <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                            value={values.bsaPhoneNumber}
                            type="text" 
                            onChange={handleChange}
                            name="bsaPhoneNumber" 
                            placeholder={"PHONE_NUMBER_CAPTION_TXT".tr()}
                            startAdornment={
                                <InputAdornment position="start">
                                    <PhoneAndroid />
                                </InputAdornment>
                            }                            
                        />
                    </FormControl>
                </div>
                <div className="col-12 mb-3">
                    <p className="bsa-text-label-form mb-2">{"EMP_ID_TXT".tr()}</p>
                    <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                            value={values.bsaCode}
                            type="text"
                            onChange={handleChange}
                            name="bsaCode" 
                            placeholder={"EMPLOYEE_ID_CAPTION_TXT".tr()}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Person />
                                </InputAdornment>
                            }                            
                        />
                    </FormControl>
                </div>
                <div className="col-12 mb-3">
                    <p className="bsa-text-label-form mb-2">{"PASSWORD_TXT".tr()}</p>
                    <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                            value={values.bsaPassword}
                            type="password" 
                            onChange={handleChange}
                            name="bsaPassword" 
                            placeholder={"PASSWORD_CAPTION_TXT".tr()}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Password />
                                </InputAdornment>
                            }                            
                        />
                    </FormControl>
                </div>
            </div>
            <div className="mt-3">
                <Button disabled={loading} variant="primary" className="w-100 pt-2 pb-2" type="submit">
                    {"SIGN IN".tr()}
                </Button>
                {/* <Link to={"/dashboard"} className="btn btn-primary w-100 pt-2 pb-2">
                    Sign In
                </Link> */}
            </div>
            <p className="mt-2">
                <Button variant="link" className="bsa-text-forgot-password p-0" onClick={() => setPage(2)}>
                    {"FORGOT_PASSWORD_TXT".tr()}
                </Button>
            </p>
        </form>
	);
}

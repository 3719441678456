// import { Button } from 'bootstrap'
import React from "react";
import { useSelector } from "react-redux";
import SuccessPic from "../../../../assets/img/success.png";
import saRequestSlice from "../../../../store/sarequestSlice";
import serviceSlice from "../../../../store/serviceSlice";
import { useCurrency } from "../../../hooks/currency";

function PartRequestSuccessModal({ onHide }) {
  const { currencyFormat } = useCurrency();
  const { emp_acc_number, bank_name } = useSelector(
    serviceSlice.selector.activeAccount
  );
  const { net_amount } = useSelector(saRequestSlice.selector.requestFee);

  return (
    <div className="bsa-request">
      <p align="center" className="mb-5">
        <img src={SuccessPic} width={200} alt={"Success Page"} />
      </p>
      <p className="bsa-request-head mb-2">{"REQUEST_SUCCESSFULL_TXT".tr()}</p>
      <p className="bsa-request-small mb-4">
        {"REQUEST_SUCCESSFULL_DESC_TXT".tr()}
      </p>
      <div className="row ps-3 pe-3">
        <div className="col-12 col-md-6">
          <p className="bsa-request-small text-start mb-2">
            {"DISBURSE_ACC_NUMBER_CAPTION_TXT".tr()}
          </p>
        </div>
        <div className="col-12 col-md-6">
          <p className="bsa-request-small text-end mb-2">
            {emp_acc_number} - {bank_name}
          </p>
        </div>
      </div>
      <div className="row ps-3 pe-3">
        <div className="col-12 col-md-6">
          <p className="bsa-request-small text-start mb-2">
            {"SA_TOTAL_RECEIVE_TXT".tr()}
          </p>
        </div>
        <div className="col-12 col-md-6">
          <p className="bsa-request-small text-end mb-2">
            IDR {currencyFormat(Number(net_amount))}
          </p>
        </div>
      </div>
      {/* <Button onClick={onHide} variant='primary' className='w-100 mt-3 pt-2 pb-2 mt-5'>
                {"OK_TXT".tr()}
            </Button> */}
      {/* <Link to={"/dashboard"} className='btn btn-primary w-100 mt-3 pt-2 pb-2 mt-5'>
                OK
            </Link> */}
    </div>
  );
}

export default PartRequestSuccessModal;

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Button } from "react-bootstrap";
import RequestStepPersonal from "./components/requestStepPersonal";
import RequestStepEmergency from "./components/requestStepEmergency";
import RequestStepAddress from "./components/requestStepAddress";
import RequestStepWorkInfo from "./components/requestStepWorkInfo";
import RequestStepProofIdentity from "./components/requestStepProofIdentity";
import RequestStepFinishPage from "./components/requestStepFinish";
import ModalPassword from "./components/modalPassword";
import { useDispatch, useSelector } from "react-redux";
import { checkKycInput, postKyc } from "../../../store/inputSlice/service";
import { Link, useNavigate } from "react-router-dom";
import inputSlice from "../../../store/inputSlice";
import { addToast } from "../../../store/toastSlice";
import LogoWhite from "../../../assets/img/logo_smartek_white.png";
import userSlice from "../../../store/userSlice";
// import LogoWhite from "../../../assets/img/logo_beever_white_landscape.png";

export default function RequestPageIndex() {
  const steps = [
    "UPLOAD_PHOTO_HEADING_TXT".tr(),
    "PERSONAL_TXT".tr(),
    "EMERGENCY_TXT".tr(),
    "ADDRESS_TXT".tr(),
    "WORK_INFO_TXT".tr(),
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { request_amt, account_id } = useSelector(
    inputSlice.selector.saRequest
  );

  const [activeStep, setActiveStep] = useState(0);
  const [modalPassword, setModalPassword] = useState(false);
  const [filesLeft, setFileLeft] = useState(null);
  const [filesRight, setFileRight] = useState(null);
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const { flag_information } = useSelector(userSlice.selector.userData);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSend = (cb) => {
    if (dispatch(checkKycInput(setActiveStep, setModalPassword))) {
      setModalPassword(true);
    } else {
      setAgreementChecked(false);
    }
  };

  const submitKyc = (cb) => {
    if (dispatch(checkKycInput(setActiveStep, setModalPassword))) {
      let files = { id: null, selfie: null };
      if (filesLeft) {
        files.id = filesLeft;
      }
      if (filesRight) {
        files.selfie = filesRight;
      }
      setLoading(true);
      dispatch(
        postKyc(navigate, files, () => {
          setModalPassword(false);
          setLoading(false);
          if (cb) {
            cb();
          }
        })
      ).then(() => {
        setLoading(false);
      });
    }
  };

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "rgb(3, 166, 207)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "rgb(3, 166, 207)",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  const SmartekStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "rgb(4, 92, 155)",
    }),
    "& .SmartekStepIcon-completedIcon": {
      color: "rgb(4, 92, 155)",
      zIndex: 1,
      fontSize: 25,
    },
    "& .SmartekStepIcon-circle": {
      width: 20,
      height: 20,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  }));

  function SmartekStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <SmartekStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="SmartekStepIcon-completedIcon" />
        ) : (
          <div className="SmartekStepIcon-topcircle">
            <div className="SmartekStepIcon-circle" />
          </div>
        )}
      </SmartekStepIconRoot>
    );
  }

  SmartekStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
  };

  const StepPageData = ({ step }) => {
    let stepPage;

    if (step === 1) {
      stepPage = (
        <RequestStepProofIdentity
          filesLeft={filesLeft}
          setFileLeft={setFileLeft}
          filesRight={filesRight}
          setFileRight={setFileRight}
        />
      );
    } else if (step === 2) {
      stepPage = <RequestStepPersonal />;
    } else if (step === 3) {
      stepPage = <RequestStepEmergency />;
    } else if (step === 4) {
      stepPage = <RequestStepAddress />;
    } else if (step === 5) {
      stepPage = <RequestStepWorkInfo />;
    }

    return stepPage;
  };

  useEffect(() => {
    if (flag_information) {
      navigate("/dashboard");
    }
    // if (!request_amt) {
    //   navigate("/dashboard");
    //   return () => {};
    // }
    // if (!account_id) {
    //   dispatch(
    //     addToast({
    //       title: "DISBURSE_ACC_NUMBER_TXT".tr(),
    //       message: "SA_REQUIRED_ACC_CAPTION_TXT".tr(),
    //     })
    //   );
    //   navigate("/dashboard");
    //   return () => {};
    // }
    /* eslint-disable */
  }, []);

  return (
    <Box
      className={`bsa-step-registration ${
        activeStep !== steps.length
          ? "d-flex justify-content-center flex-column"
          : ""
      }`}
    >
      <p className="mb-4 d-block d-md-none" align="center">
        <Link to={"/dashboard"} alt="Smartek">
          <img src={LogoWhite} alt={"Smartek"} width={140} />
        </Link>
      </p>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<QontoConnector />}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps} StepIconComponent={SmartekStepIcon}>
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {/* {activeStep === steps.length ? (
        <React.Fragment>
          <ModalPassword
            loading={loading}
            action={submitKyc}
            show={modalPassword}
            onHide={() => setModalPassword(false)}
          />
          <RequestStepFinishPage
            agreementChecked={agreementChecked}
            setAgreementChecked={setAgreementChecked}
          />
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              variant="secondary"
              onClick={handleBack}
              sx={{ mr: 1 }}
              className="ps-5 pe-5 pt-2 pb-2"
            >
              {"BACK_TXT".tr()}
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              disabled={!agreementChecked}
              onClick={handleSend}
              className="ps-5 pe-5 pt-2 pb-2"
            >
              {"SEND_TXT".tr()}
            </Button>
          </Box>
        </React.Fragment>
      ) : ( */}
      <React.Fragment>
        <ModalPassword
          loading={loading}
          action={submitKyc}
          show={modalPassword}
          onHide={() => setModalPassword(false)}
        />
        <StepPageData step={activeStep + 1} />
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          {activeStep === 0 ? (
            <Button
              variant="secondary"
              href={"/dashboard"}
              sx={{ mr: 1 }}
              className="ps-5 pe-5 pt-2 pb-2"
            >
              {"BACK_TXT".tr()}
            </Button>
          ) : (
            <Button
              variant="secondary"
              onClick={() => handleBack()}
              sx={{ mr: 1 }}
              className="ps-5 pe-5 pt-2 pb-2"
            >
              {"BACK_TXT".tr()}
            </Button>
          )}
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            onClick={activeStep === steps.length - 1 ? handleSend : handleNext}
            className="ps-5 pe-5 pt-2 pb-2"
          >
            {activeStep === steps.length - 1
              ? "FINISH_TXT".tr()
              : "NEXT_TXT".tr()}
          </Button>
        </Box>
      </React.Fragment>
      {/* )} */}
    </Box>
  );
}

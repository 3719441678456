import React from 'react'
import { FormControl, OutlinedInput } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Button } from 'react-bootstrap';
import EmailConfirm from '../../../../assets/img/img_set_email.png';

function PartEmailModal({ setPage, onHide }) {
    const [values, setValues] = React.useState({
        email : '',
    });

    const handleChange = (name, value) => {
        setValues({
            ...values,
            [name] : value
        })
    }

    const handleSubmit = () => {
        setPage(5);
    }

    return (
        <div className='bsa-change-password'>
            <div className='bsa-close-modal-btn' align="left">
                <Button variant='light' className='bg-white border-0 p-0' onClick={() => setPage(1)}>
                    <ArrowBackIosNewIcon className='fill-muted' />
                </Button>
            </div>
            <p className='bsa-change-password-title mb-3'>Change Email</p>
            <p align="center"><img src={EmailConfirm} alt={"Email"} width={150} /></p>
            <p className="bsa-label-default mb-2">New Email</p>
            <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                    value={values.email}
                    name="bsaEmail"
                    placeholder={"Your New Email"}
                    className="bsa-form-password"
                    onChange={(e) => handleChange('email', e.target.value)}
                />
            </FormControl>
            <Button onClick={handleSubmit} variant='primary' className='w-100 mt-3 pt-2 pb-2 mt-5'>
                Change Email
            </Button>
        </div>
    )
}

export default PartEmailModal
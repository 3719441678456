import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// import SideBackground from '../../../assets/img/SideBackground.png';
import SideBackground from '../../../assets/img/SideBackground_smartek.png';
import Logo from '../../../assets/img/logo_smartek.png';
// import Logo from '../../../assets/img/beever_exchange.png';
import FormSignIn from './components/formSignIn';
import LanguageSelector from '../../layouts/language';
import userSlice from '../../../store/userSlice';
import { getStoredData } from '../../../store/userSlice/service';
import FormForgotPassword from './components/formForgotPassword';

function SignIn() {
    const [page, setPage] = useState(1);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { accessToken, userInfo: { employee_id, is_password_change } } = useSelector(userSlice.selector.all)
    const [modalFirstChangePassword, setModalFirstChangePassword] = useState(false);

    useEffect(() => {
        if (accessToken) {
            if (employee_id === 0) {
                dispatch(getStoredData())
            } else {
                if(!is_password_change){
                    setModalFirstChangePassword(true)
                } else {
                    navigate('/dashboard')
                }
            }
        }
    }, [navigate, dispatch, accessToken, employee_id, is_password_change])

    let MovePage;

    if (page === 2) {
        MovePage = <FormForgotPassword setPage={setPage} />
    } else {
        MovePage = <FormSignIn show={modalFirstChangePassword} onHide={setModalFirstChangePassword} setPage={setPage} />
    }

    return (
        <div className='bsa-signin'>
            <div className='bsa-signin-cover'>
                <div className='bsa-signin-cover-container'>
                    <div className='row'>
                        <div className='col-12 col-md-6'>
                            <div className='pt-2' align='center'>
                                <img src={Logo} alt='Logo' height={40} />
                            </div>
                            <div className='bsa-signin-cover-container-space'>
                                {MovePage}
                                <div className='mt-2 ps-2 pe-2'>
                                    <div className='row'>
                                        <div className='col-6 col-md-6' align='left'>
                                            <div className='d-flex align-items-center'>
                                                <LanguageSelector />
                                            </div>
                                        </div>
                                        <div className='col-6 col-md-6' align='right'>
                                            <p className='bsa-text-web-version mb-0'>
                                                Web Version 1.0.0
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 d-none d-md-flex'>
                            <div className='d-flex align-items-center justify-content-center'>
                                <img src={SideBackground} alt="Side Background" width={'100%'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignIn
import { createSlice } from "@reduxjs/toolkit"

const initialRequestFee = {
  amount: 0,
  total_fee: 0,
  fee: [], // { fee_name: '', fee_label: '', fee_amount: 0}
  net_amount: 0,
  loading: true,
}

const saRequestSlice = createSlice({
  name: 'saRequestStore',
  initialState: {
    requestFee: initialRequestFee,
    requestHistory: {
      data: [],
      loading: true
    },
    regularHistory : {
      data: [],
      loading: true
    }
  },
  reducers: {
    setSaRequestFee: (state, action) => {
      return {
        ...state,
        requestFee: { ...action.payload, loading: false},
      }
    },
    clearSaRequestFee: (state, action) => {
      return {
        ...state,
        requestFee: initialRequestFee,
      }
    },
    setSaRequestFeeLoading: (state, action) => {
      return {
        ...state,
        requestFee: { ...state.requestFee, loading: action.payload},
      }
    },
    setSaHistory: (state, action) => {
      return {
        ...state,
        requestHistory: { data: action.payload, loading: false},
      }
    },
    setSaHistoryLoading: (state, action) => {
      return {
        ...state,
        requestHistory: { ...state.requestHistory, loading: action.payload},
      }
    },
    setRegularSaHistory: (state, action) => {
      return {
        ...state,
        regularHistory: { data: action.payload, loading: false},
      }
    },
    setRegularSaHistoryLoading: (state, action) => {
      return {
        ...state,
        regularHistory: { ...state.regularHistory, loading: action.payload},
      }
    },
  },
})

export const {
  setSaRequestFee, setSaRequestFeeLoading, setSaHistory, setSaHistoryLoading, clearSaRequestFee, setRegularSaHistory, setRegularSaHistoryLoading
} = saRequestSlice.actions

saRequestSlice.selector = {
  all: (state) => state[saRequestSlice.name],
  requestFee: (state) => state[saRequestSlice.name].requestFee,
  requestHistory: (state) => state[saRequestSlice.name].requestHistory,
  regularHistory: (state) => state[saRequestSlice.name].regularHistory,
}

export default saRequestSlice
import React, { useEffect } from "react";
import { CloseButton, Tabs, Tab, Button } from "react-bootstrap";
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import LogoSmartek from "../../../../assets/img/logo_2.png";
// import LogoBeever from "../../../../assets/img/logo.png";
import saRequestSlice from "../../../../store/sarequestSlice";
import { useCurrency } from "../../../hooks/currency";
import {
  getRegularSAHistory,
  getSAHistory,
} from "../../../../store/sarequestSlice/service";

function PartModalHistory({
  onHide,
  setPage,
  setDetailId,
  openRequestDetail,
  isShop,
}) {
  const {
    currencyFormat,
    currencyFormatWithPrefix,
    currencyFormatBahtWithPrefix,
    dateFormat,
  } = useCurrency();
  const dispatch = useDispatch();
  const {
    requestHistory: { data: requestHistory },
    regularHistory: { data: regularHistory },
  } = useSelector(saRequestSlice.selector.all);


  useEffect(() => {
    dispatch(getSAHistory());
    dispatch(getRegularSAHistory());
  }, [dispatch]);

  return (
    <div className="bsa-history">
      <div align="right">
        <CloseButton onClick={onHide} />
      </div>
      <p className="bsa-history-title">{"TRX_HISTORY_TTL_TXT".tr()}</p>
      {requestHistory
        .filter((rq) =>
          isShop ? rq.productid === "03" : rq.productid !== "03"
        )
        .map((rh, rhid) => {
          const { createtime, loanamount, orderstatus, referenceid } = rh;
          return (
            <div
              key={rhid}
              // onClick={() => {onHide(); openRequestDetail(referenceid)}}
              className="bsa-history-card"
            >
              <div className="bsa-history-card-left">
                <p className="mb-0 bsa-history-card-left-first">
                  {/* IDR {currencyFormat(Number(loanamount))} */}
                  {currencyFormat(Number(loanamount))}
                </p>
                <p className="mb-0 bsa-history-card-left-second">
                  {createtime}
                </p>
              </div>
              <div className="bsa-history-card-right">
                <p className="mb-0 bsa-history-card-right-text">
                  {orderstatus}
                </p>
                {/* <HighlightOffIcon className='fill-failed' /> */}
                {/* <CheckCircleOutlineIcon className='fill-success' /> */}
              </div>
            </div>
          );
        })}
      {/* <Tabs
        defaultActiveKey="salary-advance"
        className="bsa-tabs-custom mb-3"
        fill
      >
        <Tab eventKey="salary-advance" title={"B2C_TITLE_TXT".tr()}>
          {requestHistory.map((rh, rhid) => {
            const { createtime, loanamount, orderstatus, referenceid } = rh;
            return (
              <div
                key={rhid}
                onClick={() => {onHide(); openRequestDetail(referenceid)}}
                className="bsa-history-card"
              >
                <div className="bsa-history-card-left">
                  <p className="mb-0 bsa-history-card-left-first">
                    IDR {currencyFormat(Number(loanamount))}
                  </p>
                  <p className="mb-0 bsa-history-card-left-second">
                    {createtime}
                  </p>
                </div>
                <div className="bsa-history-card-right">
                  <p className="mb-0 bsa-history-card-right-text">
                    {orderstatus}
                  </p>
                  <HighlightOffIcon className='fill-failed' />
                  <CheckCircleOutlineIcon className='fill-success' />
                </div>
              </div>
            );
          })}
        </Tab>
        <Tab eventKey="regular-topup" title={"SA_REGULAR_TOPUP_TXT".tr()}>
          <div className="row">
            <div className="col-12 col-md-6">
              <FormControl fullWidth className="mb-3">
                <Select
                  displayEmpty
                  IconComponent={ChevronRightIcon}
                  className={"bsa-unselect-mui"}
                  value={""}
                >
                  <MenuItem disabled={true} value={""}>
                    All Status
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6">
              <FormControl fullWidth className="mb-3">
                <Select
                  displayEmpty
                  IconComponent={ChevronRightIcon}
                  className={"bsa-unselect-mui"}
                  value={""}
                >
                  <MenuItem disabled={true} value={""}>
                    All Date
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          {regularHistory.map((regularData, rgid) => {
            const {
              product_type,
              request_amount,
              created_date,
              disburse_date,
              regular_sys_sa_id,
            } = regularData;
            return (
              <div className="bsa-history-box">
                <div key={rgid} className="bsa-history-box-card">
                  <div className="bsa-history-box-card-head">
                    <div className="d-flex align-items-center">
                      <img src={LogoBeever} alt={"Beever"} width={40} />
                      <div className="ms-3">
                        <p className="mb-0 bsa-history-box-card-second">
                          {product_type}
                        </p>
                        <p className="mb-0 bsa-history-box-card-first">
                          {dateFormat(created_date)}
                        </p>
                      </div>
                    </div>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setPage(2);
                        setDetailId(regular_sys_sa_id);
                      }}
                    >
                      DETAIL
                    </Button>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="bsa-history-box-card-content">
                      <p className="mb-0 bsa-history-box-card-first">
                        Disburse Date
                      </p>
                      <p className="mb-0 bsa-history-box-card-second">
                        {disburse_date}
                      </p>
                    </div>
                    <div className="bsa-history-box-card-content">
                      <p className="mb-0 bsa-history-box-card-first">
                        Request Amount
                      </p>
                      <p className="mb-0 bsa-history-box-card-second">
                        {currencyFormatWithPrefix(Number(request_amount))}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Tab>
      </Tabs> */}
    </div>
  );
}

export default PartModalHistory;

import { createSlice } from "@reduxjs/toolkit";

const langaugeSlice = createSlice({
  name: "languageStore",
  initialState: {
    // active : 'th-TH',
    active: "id-ID",
    // active: "en-US",
    // active : 'my-MM',
    languages: [
      { name: "English", value: "en-US" },
      { name: "Indonesia", value: "id-ID" },
      // { name: "Thai", value: "th-TH" },
      // { name: "Burmese", value: "my-MM" },
    ],
    translations: {
      "": {
        "th-TH": "",
        "en-US": "",
        "my-MM": "",
      },
      SA_NUMBER_TXT: {
        "th-TH": "หมายเลข SA",
        "en-US": "SA Number",
        "id-ID": "Nomor SA",
        "my-MM": "SA နံပါတ်",
      },
      AGREEMENT_TXT: {
        "th-TH": "ลงนามข้อตกลง",
        "en-US": "Agreement Signed",
        "id-ID": "Perjanjian Ditandatangani",
        "my-MM": "လက်မှတ်ရေးထိုးခဲ့သည်။",
      },
      AGREEMENT_DOC_TXT: {
        "th-TH": "เอกสารข้อตกลง",
        "en-US": "Agreement Doc",
        "id-ID": "Perjanjian Dok",
        "my-MM": "သဘောတူညီချက်စာရွက်စာတမ်း",
      },
      REASON_TXT: {
        "th-TH": "เหตุผล",
        "en-US": "Reason",
        "id-ID": "Alasan",
        "my-MM": "အကြောင်းပြချက်",
      },
      PDF_CLICK_FULL_TXT: {
        "th-TH": "คลิกเพื่อดูหน้าเต็ม",
        "en-US": "Click to view full page",
        "id-ID": "Klik untuk melihat halaman penuh",
        "my-MM": "စာမျက်နှာအပြည့်အစုံကိုကြည့်ရန်နှိပ်ပါ",
      },
      SEND_TXT: {
        "th-TH": "ส่ง",
        "en-US": "Send",
        "id-ID": "Kirim",
        "my-MM": "ပေးပို့",
      },
      CONFIM_PASSWORD_CHANGE_CAPTION_TXT: {
        "th-TH":
          "การเปลี่ยนแปลงนี้ไม่สามารถยกเลิกได้โปรดตรวจสอบฟิลด์ก่อนที่คุณจะส่งการดำเนินการนี้",
        "en-US":
          "This changes cannot be undone, please check the field before you submit this action.",
        "id-ID":
          "Perubahan ini tidak dapat dibatalkan, harap periksa kolom sebelum Anda mengirimkannya",
        "my-MM":
          "ဤအပြောင်းအလဲများကိုမဖျက်သိမ်းနိုင်ပါ, ကျေးဇူးပြု. ဤလုပ်ဆောင်ချက်ကိုမတင်မီကွက်လပ်ကိုစစ်ဆေးပါ",
      },
      CONFIM_PASSWORD_CHANGE_TXT: {
        "th-TH": "แน่ใจหรือว่าต้องการเปลี่ยน?",
        "en-US": "Are you sure you want to change?",
        "id-ID": "Apakah anda yakin ingin melakukan perubahan?",
        "my-MM": "သင်ပြောင်းလဲချင်တာသေချာလား",
      },
      FIRST_CHANGE_PASSWORD_TXT: {
        "th-TH": "คุณต้องเปลี่ยนรหัสผ่านและอีเมลก่อน",
        "en-US": "You must change password and email first",
        "id-ID": "Anda harus mengubah password dan email terlebih dahulu",
        "my-MM": "သင်စကားဝှက်နှင့်အီးမေးလ်ကိုပထမ ဦး ဆုံးပြောင်းလဲရမည်",
      },
      CONFIRM_DELETE_ACCOUNT_TXT: {
        "th-TH": "ใช่ ลบบัญชี",
        "en-US": "Yes, delete account",
        "id-ID": "Ya, hapus akun",
        "my-MM": "ဟုတ်ကဲ့၊ အကောင့်ဖျက်ပါ။",
      },
      KYC_GENERIC_REQURIED_ERROR_TXT: {
        "th-TH": "ที่จำเป็น",
        "en-US": "Required",
        "id-ID": "Diperlukan",
        "my-MM": "လိုအပ်သည်။",
      },
      Logout: {
        "th-TH": "ออกจากระบบ",
        "en-US": "Logout",
        "id-ID": "Keluar",
        "my-MM": "ဝင်",
      },
      "SIGN IN": {
        "th-TH": "เข้าสู่ระบบ",
        "en-US": "SIGN IN",
        "id-ID": "MASUK",
        "my-MM": "ဆိုင်းအင်လုပ်ခြင်း",
      },
      "Sign In": {
        "th-TH": "เข้าสู่ระบบ",
        "en-US": "Sign In",
        "id-ID": "Masuk",
        "my-MM": "ဆိုင်းအင်လုပ်ခြင်း",
      },
      LATEST_TRANSACTION_TXT: {
        "th-TH": "ธุรกรรมล่าสุด",
        "en-US": "Latest Transaction",
        "id-ID": "Transaksi Terbaru",
        "my-MM": "နောက်ဆုံးပေါ်ငွေပေးငွေယူ",
      },
      DISBURSE_AMOUNT_TXT: {
        "th-TH": "จำนวนเงิน",
        "en-US": "Disbursement Amount",
        "id-ID": "Jumlah Pencairan",
        "my-MM": "ငွေပေးချေမှုပမာဏ",
      },
      ACTION_TXT: {
        "th-TH": "การกระทำ",
        "en-US": "Action",
        "id-ID": "Aksi",
        "my-MM": "လှုပ်ရှားခြင်း",
      },
      LANGUAGE_TXT: {
        "th-TH": "ภาษา",
        "en-US": "Language",
        "id-ID": "Bahasa",
        "my-MM": "",
      },
      SIGN_IN_TXT: {
        "th-TH": "เข้า",
        "id-ID": "Masuk",
        "my-MM": "",
      },
      PHONE_NUMBER_TXT: {
        "th-TH": "หมายเลขโทรศัพท์",
        "en-US": "Phone Number",
        "id-ID": "Nomor Telepon",
        "my-MM": "ဖုန်းနံပါတ်",
      },
      EMP_ID_TXT: {
        "th-TH": "รหัสพนักงานหรือชื่อผู้ใช้",
        "en-US": "Employee Code or Username",
        "id-ID": "Kode Karyawan atau Nama Pengguna",
        "my-MM": "ဝန်ထမ်းကုဒ်သို့မဟုတ်အသုံးပြုသူအမည်",
      },
      PASSWORD_TXT: {
        "th-TH": "รหัสผ่าน",
        "en-US": "Password",
        "id-ID": "Password",
        "my-MM": "လှျို့ဝှက်စကား",
      },
      PHONE_NUMBER_CAPTION_TXT: {
        "th-TH": "หมายเลขโทรศัพท์ของคุณ",
        "en-US": "Your Phone Number",
        "id-ID": "Nomor Telepon Anda",
        "my-MM": "သင့်ဖုန်းနံပါတ်",
      },
      EMPLOYEE_ID_CAPTION_TXT: {
        "th-TH": "เช่น. 1238839",
        "en-US": "e.g 1238839",
        "id-ID": "mis. 1238839",
        "my-MM": "",
      },
      PASSWORD_CAPTION_TXT: {
        "th-TH": "ป้อนรหัสผ่านของคุณ",
        "en-US": "Enter Your Password",
        "id-ID": "Masukkan Password",
        "my-MM": "သင်၏စကားဝှက်ကိုရိုက်ထည့်ပါ",
      },
      FORGOT_PASSWORD_TXT: {
        "th-TH": "ลืมรหัสผ่าน?",
        "en-US": "Forgot Password?",
        "id-ID": "Lupa Password?",
        "my-MM": "စကားဝှက်ကိုမေ့နေပါသလား?",
      },
      PHONE_NUMBER_EMPTY_ERROR_TXT: {
        "th-TH": "ต้องมีหมายเลขโทรศัพท์!",
        "en-US": "Phone Number is Required !",
        "id-ID": "Nomor Telepon Diperlukan !",
        "my-MM": "ဖုန်းနံပါတ်လိုအပ်သည်။",
      },
      EMPLOYEE_ID_EMPTY_ERROR_TXT: {
        "th-TH": "จำเป็นต้องมีหมายเลขประจำตัวพนักงาน!",
        "en-US": "Employee Identity Number is Required !",
        "id-ID": "Nomor Identitas Karyawan Diperlukan !",
        "my-MM": "ဝန်ထမ်းမှတ်ပုံတင်နံပါတ်လိုအပ်သည်!",
      },
      PASSWORD_MIN_LENGTH_ERROR_TXT: {
        "th-TH": "รหัสผ่านต้องมีอักขระอย่างน้อย 8 ตัว",
        "en-US": "Password must have at least 8 characters",
        "id-ID": "Password harus memiliki minimal 8 karakter",
        "my-MM": "စကားဝှက်မှာအနည်းဆုံးစာလုံး 8 လုံးရှိရမည်",
      },
      FORGOT_PASSWORD_CAPTION_TXT: {
        "th-TH": "ลืมรหัสผ่าน",
        "en-US": "Forgot Password",
        "id-ID": "Lupa Password",
        "my-MM": "စကားဝှက်ကိုမေ့နေပါသလား",
      },
      RESET_PASSWORD_CAPTION_TXT: {
        "th-TH": "รีเซ็ตรหัสผ่าน",
        "en-US": "Reset Password",
        "id-ID": "Atur Ulang Password",
        "my-MM": "လျှို့ဝှတ်နံပါတ်အားမူလအတိုင်းပြန်လုပ်သည်",
      },
      BACK_SIGN_IN_CAPTION_TXT: {
        "th-TH": "กลับไปลงชื่อเข้าใช้",
        "en-US": "Back to Sign in",
        "id-ID": "Kembali untuk Masuk",
        "my-MM": "ပြန်ဆိုင်းဘုတ်",
      },
      RESET_PASSWORD_CHECK_EMAIL_TXT: {
        "th-TH": "โปรดตรวจสอบอีเมลของคุณเพื่อรีเซ็ตรหัสผ่านของคุณ",
        "en-US": "Please check your email to reset your password",
        "id-ID":
          "Silakan periksa email Anda untuk mengatur ulang Password Anda",
        "my-MM": "သင်၏စကားဝှက်ကိုပြန်လည်စတင်ရန်သင်၏အီးမေးလ်ကိုစစ်ဆေးပါ",
      },
      WELCOME_BACK_TXT: {
        "th-TH": "สวัสดี,",
        "en-US": "Hello",
        "id-ID": "Halo",
        "my-MM": "ဟယ်လို",
      },
      HEADER_SA_AMOUNT_TXT: {
        "th-TH": "จำนวนเงินเดือนล่วงหน้าของคุณ",
        "en-US": "Your Salary Advance Amount",
        "id-ID": "Jumlah Salary Advance Anda",
        "my-MM": "သင့်ရဲ့လစာတိုးမြှင့်ပမာဏ",
      },
      SA_REQUEST_AMOUNT_TXT: {
        "th-TH": "ขอจำนวนเงิน",
        "en-US": "Request Amount",
        "id-ID": "Jumlah Permintaan",
        "my-MM": "တောင်းဆိုမှုပမာဏ",
      },
      SA_REQUEST_AMOUNT_CAPTION_TXT: {
        "th-TH": "เช่น. 4,000,000",
        "en-US": "e.g 4,000,000",
        "id-ID": "mis 4,000,000",
        "my-MM": "e.g 4,000,000",
      },
      SA_TOTAL_FEE_TXT: {
        "th-TH": "ค่าธรรมเนียมทั้งหมด",
        "en-US": "Total Fee",
        "id-ID": "Jumlah Biaya",
        "my-MM": "စုစုပေါင်းအခကြေးငွေ",
      },
      SA_RECEIVE_ACC_TXT: {
        "th-TH": "รับบัญชี",
        "en-US": "Receiving Account",
        "id-ID": "Rekening Penerimaan",
        "my-MM": "အကောင့်လက်ခံခြင်း",
      },
      SELECT_BANK_ACC_TXT: {
        "th-TH": "เลือกบัญชี",
        "en-US": "Select Account",
        "id-ID": "Pilih Akun",
        "my-MM": "အကောင့်ကိုရွေးပါ",
      },
      SA_REQUEST_TXT: {
        "th-TH": "ขอ",
        "en-US": "Request",
        "id-ID": "Permintaan",
        "my-MM": "တောင်းဆိုချက်ကို",
      },
      SA_REQUEST_B2C_TXT: {
        "th-TH": "การส่ง Salary Advance",
        "en-US": "Request Salary Advance",
        "id-ID": "Pengajuan Salary Advance",
        "my-MM": "",
      },
      B2C_TITLE_TXT: {
        "th-TH": "Salary Advance",
        "en-US": "Salary Advance",
        "id-ID": "Salary Advance",
        "my-MM": "လစာကြိုတင်မဲ",
      },
      DFA_INSURANCE_ICO_TXT: {
        "th-TH": "ประกันภัย",
        "en-US": "Insurance",
        "id-ID": "Asuransi",
        "my-MM": "",
      },
      DFA_INVEST_ICO_TXT: {
        "th-TH": "ประกันภัย",
        "en-US": "Investment",
        "id-ID": "Investasi",
        "my-MM": "",
      },
      DFA_LOAN_ICO_TXT: {
        "th-TH": "เงินกู้",
        "en-US": "Loan",
        "id-ID": "Pinjaman",
        "my-MM": "",
      },
      DFA_OTHERS_ICO_TXT: {
        "th-TH": "อื่นๆ",
        "en-US": "Others",
        "id-ID": "Lainnya",
        "my-MM": "",
      },
      MENU_HOME_ICO_TXT: {
        "th-TH": "หน้าแรก",
        "en-US": "Home",
        "id-ID": "Beranda",
        "my-MM": "",
      },
      MENU_HOME_TRX_TXT: {
        "th-TH": "ธุรกรรม",
        "en-US": "Transaction",
        "id-ID": "Transaksi",
        "my-MM": "",
      },
      MENU_HOME_ACC_TXT: {
        "th-TH": "บัญชี",
        "en-US": "Account",
        "id-ID": "Akun",
        "my-MM": "",
      },
      MENU_HOME_PROFILE_TXT: {
        "th-TH": "ประวัติโดยย่อ",
        "en-US": "Profile",
        "id-ID": "Profil",
        "my-MM": "ဘေးတိုက်ပုံ",
      },
      TRX_STATUS_FAILED_TXT: {
        "th-TH": "ล้มเหลว",
        "en-US": "Failed",
        "id-ID": "Gagal",
        "my-MM": "",
      },
      TRX_STATUS_PROCESS_TXT: {
        "th-TH": "ในกระบวนการ",
        "en-US": "In Process",
        "id-ID": "Dalam Proses",
        "my-MM": "",
      },
      TRX_STATUS_DELIVERED_TXT: {
        "th-TH": "ส่งมอบแล้ว",
        "en-US": "Delivered",
        "id-ID": "Berhasil",
        "my-MM": "",
      },
      TRX_HISTORY_TTL_TXT: {
        "th-TH": "ประวัติการทำรายการ",
        "en-US": "Transaction History",
        "id-ID": "Riwayat Transaksi",
        "my-MM": "ငွေပေးငွေယူသမိုင်း",
      },
      TRX_STATUS_DESC_ONG_VERIFY_TXT: {
        "th-TH": "ในกระบวนการตรวจสอบ",
        "en-US": "Ongoing Verification Process",
        "id-ID": "Dalam Proses Verifikasi",
        "my-MM": "",
      },
      TRX_STATUS_DESC_ONG_DISBURSE_TXT: {
        "th-TH": "อยู่ในกระบวนการหลอมละลาย",
        "en-US": "Ongoing Disbursement Process",
        "id-ID": "Dalam Proses Pencairan",
        "my-MM": "",
      },
      TRX_STATUS_DESC_REJECTED_TXT: {
        "th-TH": "ล้มเหลว",
        "en-US": "Rejected",
        "id-ID": "Gagal",
        "my-MM": "",
      },
      TRX_STATUS_DESC_FAILED_DISBURSE_TXT: {
        "th-TH": "การถอนเงินล้มเหลว โปรดตรวจสอบบัญชีผู้รับอีกครั้ง",
        "en-US": "Disbursement Failure. Please Check Destination Account",
        "id-ID": "Pencairan Gagal. Silahkan Cek Kembali Akun Penerima",
        "my-MM": "",
      },
      TRX_STATUS_DESC_COMPLETE_TXT: {
        "th-TH": "การทำธุรกรรมเสร็จสมบูรณ์",
        "en-US": "Transaction is Complete",
        "id-ID": "Transaksi Sudah Berhasil",
        "my-MM": "",
      },
      TRX_DETAIL_TTL_TXT: {
        "th-TH": "รายละเอียดการทำธุรกรรม",
        "en-US": "Detail Transaction",
        "id-ID": "Detil Transaksi",
        "my-MM": "",
      },
      TRX_DETAIL_REQUEST_DATE: {
        "th-TH": "วันที่ของคำขอ",
        "en-US": "Request Date",
        "id-ID": "Tanggal Permintaan",
        "my-MM": "အခေြအနေ",
      },
      STATUS_TXT: {
        "th-TH": "สถานะ",
        "en-US": "Status",
        "id-ID": "Status",
        "my-MM": "အဆင့်အတန်း",
      },
      DESC_TXT: {
        "th-TH": "คำอธิบาย",
        "en-US": "Description",
        "id-ID": "Deskripsi",
        "my-MM": "",
      },
      SA_TRANSFER_FEE_TXT: {
        "th-TH": "ค่าธรรมเนียมการโอน",
        "en-US": "Transfer Charge",
        "id-ID": "Biaya Transfer",
        "my-MM": "",
      },
      SA_MEMBERSHIP_FEE_TXT: {
        "th-TH": "ค่าสมาชิก",
        "en-US": "Membership Fee",
        "id-ID": "Biaya Keanggotaan",
        "my-MM": "",
      },
      SA_REGISTRATION_FEE_TXT: {
        "th-TH": "ค่าลงทะเบียน",
        "en-US": "Registration Fee",
        "id-ID": "Biaya Registrasi",
        "my-MM": "",
      },
      SA_SYSTEM_USAGE_FEE_TXT: {
        "th-TH": "ค่าใช้จ่ายในการใช้ระบบ",
        "en-US": "System Usage App",
        "id-ID": "Biaya Penggunaan Sistem",
        "my-MM": "",
      },
      SA_TOTAL_RECEIVE_TXT: {
        "th-TH": "รับทั้งหมด",
        "en-US": "Total Receiving",
        "id-ID": "Jumlah Penerimaan",
        "my-MM": "စုစုပေါင်းလက်ခံရရှိ",
      },
      DISBURSE_ACC_TTL_TXT: {
        "th-TH": "บัญชี",
        "en-US": "Account",
        "id-ID": "Akun",
        "my-MM": "ငေွစာရင်း",
      },
      DISBURSE_ADD_ACC_TTL_TXT: {
        "th-TH": "เพิ่มบัญชีใหม่",
        "en-US": "Add New Account",
        "id-ID": "Tambah Akun Baru",
        "my-MM": "အကောင့်အသစ်ထည့်ပါ",
      },
      SAVE_TXT: {
        "th-TH": "บันทึก",
        "en-US": "Save",
        "id-ID": "Simpan",
        "my-MM": "ကယ်ဆယ်",
      },
      DISBURSE_ACC_TYPE_TXT: {
        "th-TH": "พิมพ์",
        "en-US": "Type",
        "id-ID": "Tipe",
        "my-MM": "ပုံနှိပ်စာ",
      },
      DISBURSE_ACC_TYPE_CAPTION_TXT: {
        "th-TH": "เลือกพิมพ์",
        "en-US": "Select Type",
        "id-ID": "Pilih Tipe",
        "my-MM": "အမျိုးအစားကိုရွေးချယ်ပါ",
      },
      DISBURSE_ACC_TYPE_EMPTY_ERROR_TXT: {
        "th-TH": "ต้องการประเภทบัญชี!",
        "en-US": "Account Type is Required!",
        "id-ID": "Tipe Akun Diperlukan!",
        "my-MM": "",
      },
      DISBURSE_ACTIVE_BANK_TXT: {
        "th-TH": "บัญชีของคุณ",
        "en-US": "Your Account",
        "id-ID": "Akun Anda",
        "my-MM": "သင့်အကောင့်",
      },
      DISBURSE_ACC_BANK_MANAGE_TXT: {
        "th-TH": "จัดการบัญชีของคุณ",
        "en-US": "Manage Your Account",
        "id-ID": "Kelola Akun Anda",
        "my-MM": "သင့်အကောင့်ကိုစီမံပါ",
      },
      DISBURSE_ACC_BANK_TXT: {
        "th-TH": "บัญชี",
        "en-US": "Account",
        "id-ID": "Akun",
        "my-MM": "ငေွစာရင်း",
      },
      DISBURSE_ACC_BANK_COUNTRY_TXT: {
        "th-TH": "ประเทศ",
        "en-US": "Country",
        "id-ID": "Negara",
        "my-MM": "တိုင်းပြည်",
      },
      DISBURSE_ACC_BANK_COUNTRY_CAPTION_TXT: {
        "th-TH": "เลือกประเทศ",
        "en-US": "Select Country",
        "id-ID": "Pilih Negara",
        "my-MM": "နိုင်ငံကိုရွေးချယ်ပါ",
      },
      DISBURSE_ACC_BANK_CAPTION_TXT: {
        "th-TH": "เลือกบัญชีของคุณ",
        "en-US": "Select Your Account",
        "id-ID": "Pilih Akun Anda",
        "my-MM": "သင်၏အကောင့်ကိုရွေးချယ်ပါ",
      },
      DISBURSE_ACC_NUMBER_TXT: {
        "th-TH": "หมายเลขบัญชี",
        "en-US": "Account Number",
        "id-ID": "Nomor Akun",
        "my-MM": "အကောင့်နံပါတ်",
      },
      DELETE_TXT: {
        "th-TH": "ลบ",
        "en-US": "Delete",
        "id-ID": "Hapus",
        "my-MM": "ဖျက်",
      },
      DISBURSE_ACC_NAME_TXT: {
        "th-TH": "ชื่อบัญชี",
        "en-US": "Account Name",
        "id-ID": "Nama Akun",
        "my-MM": "အကောင့်နာမည်",
      },
      DISBURSE_ACC_NAME_CAPTION_TXT: {
        "th-TH": "ชื่อบัญชีของคุณ",
        "en-US": "Your Account Name",
        "id-ID": "Nama Akun Anda",
        "my-MM": "သင့်အကောင့်အမည်",
      },
      DISBURSE_ACC_NAME_ID_EMPTY_ERROR_TXT: {
        "th-TH": "ต้องการชื่อบัญชี!",
        "en-US": "Account Name is Required!",
        "id-ID": "Nama Akun Diperlukan!",
        "my-MM": "",
      },
      DISBURSE_ACC_NUMBER_CAPTION_TXT: {
        "th-TH": "หมายเลขบัญชีของคุณ",
        "en-US": "Your Account Number",
        "id-ID": "Nomor Rekening Anda",
        "my-MM": "သင့်အကောင့်နံပါတ်",
      },
      DISBURSE_ACC_NUMBER_ID_EMPTY_ERROR_TXT: {
        "th-TH": "ต้องการหมายเลขบัญชี!",
        "en-US": "Account Number is Required!",
        "id-ID": "Nomor Rekening Diperlukan!",
        "my-MM": "",
      },
      PROFILE_TTL_TXT: {
        "th-TH": "โปรไฟล์",
        "en-US": "Profile",
        "id-ID": "Profil",
        "my-MM": "",
      },
      FULL_NAME_TXT: {
        "th-TH": "ชื่อเต็ม",
        "en-US": "Full Name",
        "id-ID": "Nama Lengkap",
        "my-MM": "နာမည်အပြည့်အစုံ",
      },
      FULL_NAME_CAPTION_TXT: {
        "th-TH": "ชื่อเต็มของคุณ",
        "en-US": "Your Full Name",
        "id-ID": "Nama Lengkap Anda",
        "my-MM": "သင့်နာမည်အပြည့်အစုံ",
      },
      COMPANY_TXT: {
        "th-TH": "บริษัท",
        "en-US": "Company",
        "id-ID": "Perusahaan",
        "my-MM": "ကုမ္ပဏီ",
      },
      CHANGE_PASSWORD_TXT: {
        "th-TH": "เปลี่ยนรหัสผ่าน",
        "en-US": "Change Password",
        "id-ID": "Ganti Password",
        "my-MM": "",
      },
      USER_MANUAL_TXT: {
        "th-TH": "คู่มือการใช้งาน",
        "en-US": "User Manual",
        "id-ID": "Panduan Penggunaan",
        "my-MM": "",
      },
      FAQ_TXT: {
        "th-TH": "คำถามที่พบบ่อย",
        "en-US": "FAQ",
        "id-ID": "Pertanyaan yang Sering Diajukan",
        "my-MM": "",
      },
      CONTACT_US_TXT: {
        "th-TH": "ติดต่อเรา",
        "en-US": "Contact Us",
        "id-ID": "Hubungi Kami",
        "my-MM": "",
      },
      SIGN_OUT_TXT: {
        "th-TH": "ออกจากระบบ",
        "en-US": "Sign Out",
        "id-ID": "Keluar",
        "my-MM": "",
      },
      CURRENT_PASSWORD_TXT: {
        "th-TH": "รหัสผ่านปัจจุบัน",
        "en-US": "Current Password",
        "id-ID": "Password Saat Ini",
        "my-MM": "လက်ရှိစကားဝှက်",
      },
      CURRENT_PASSWORD_CAPTION_TXT: {
        "th-TH": "รหัสผ่านปัจจุบันของคุณ",
        "en-US": "Your Current Password",
        "id-ID": "Password Anda Saat ini",
        "my-MM": "သင်၏လက်ရှိစကားဝှက်",
      },
      NEW_PASSWORD_TXT: {
        "th-TH": "รหัสผ่านใหม่",
        "en-US": "New Password",
        "id-ID": "Password Baru",
        "my-MM": "စကားဝှက်အသစ်",
      },
      NEW_PASSWORD_CAPTION_TXT: {
        "th-TH": "รหัสผ่านใหม่ของคุณ",
        "en-US": "Your New Password",
        "id-ID": "Password Baru Anda",
        "my-MM": "သင်၏စကားဝှက်အသစ်",
      },
      CONFIRM_PASSWORD_TXT: {
        "th-TH": "ยืนยันรหัสผ่านใหม่ ",
        "en-US": "Confirm New Password",
        "id-ID": "Konfirmasi Password Anda yang Baru",
        "my-MM": "စကားဝှက်အသစ်ကိုအတည်ပြုပါ",
      },
      CONFIRM_PASSWORD_CAPTION_TXT: {
        "th-TH": "ป้อนรหัสผ่านของคุณ",
        "en-US": "Enter Your Password",
        "id-ID": "Masukkan Password Anda",
        "my-MM": "သင်၏စကားဝှက်ကိုရိုက်ထည့်ပါ",
      },
      PASSWORD_EMPTY_ERROR_TXT: {
        "th-TH": "ต้องการรหัสผ่าน!",
        "en-US": "Password is Required !",
        "id-ID": "Password Diperlukan!",
        "my-MM": "",
      },
      SA_CONFIRM_REQUEST_TTL_TXT: {
        "th-TH": "คำขอยืนยัน",
        "en-US": "Confirmation Request",
        "id-ID": "Konfirmasi Permintaan",
        "my-MM": "အတည်ပြုချက်တောင်းဆိုမှု",
      },
      SA_CONFIRM_PASSWORD_TTL_TXT: {
        "th-TH": "ยืนยันรหัสผ่าน",
        "en-US": "Confirmation Password",
        "id-ID": "Konfirmasi Password",
        "my-MM": "",
      },
      SA_CONFIRM_DETAIL_TTL_TXT: {
        "th-TH": "คำขอรายละเอียด",
        "en-US": "Detail Request",
        "id-ID": "Detil Permintaan",
        "my-MM": "အသေးစိတ်တောင်းဆိုမှု",
      },
      RECEIVING_TXT: {
        "th-TH": "กำลังรับ",
        "en-US": "Receiving",
        "id-ID": "Sedang Diterima",
        "my-MM": "",
      },
      TNC_TTL_TXT: {
        "th-TH": "ข้อกำหนดและเงื่อนไข",
        "en-US": "Terms and Conditions",
        "id-ID": "Sedang Diterima",
        "my-MM": "စည်းကမ်းနှင့်သတ်မှတ်ချက်များ",
      },
      AGREE_TNC_TXT: {
        "th-TH": "ฉันเห็นด้วยกับข้อกำหนดและเงื่อนไขนี้",
        "en-US": "I agree with this terms and conditions",
        "id-ID": "Saya Setuju Dengan Syarat & Ketentuan",
        "my-MM": "ငါဒီစည်းကမ်းချက်များနှင့်အခြေအနေများနှင့်အတူသဘောတူသည်",
      },
      AGREE_TNC_EMPTY_ERROR_TXT: {
        "th-TH": "คุณต้องยอมรับก่อนที่จะดำเนินการต่อ",
        "en-US": "You must agree before proceeding",
        "id-ID": "Anda Harus Setuju Sebelum Melanjutkan",
        "my-MM": "",
      },
      AGREE_02_TNC_EMPTY_ERROR_TXT: {
        "th-TH": "คุณต้องยอมรับข้อกำหนดและเงื่อนไข",
        "en-US": "You must agree to the terms and conditions",
        "id-ID": "Anda Harus Setuju Dengan Syarat & Ketentuan",
        "my-MM": "",
      },
      NEW_CONFIRM_PASSWORD_TXT: {
        "th-TH": "กรุณายืนยันรหัสผ่านใหม่ของคุณ",
        "en-US": "Confirmation Password",
        "id-ID": "Konfirmasi Password Anda yang Baru",
        "my-MM": "",
      },
      ENTER_PASSWORD_CAPTION_TXT: {
        "th-TH": "ป้อนรหัสผ่านของคุณ",
        "en-US": "Enter Your Password",
        "id-ID": "Masukkan Password Anda",
        "my-MM": "သင်၏စကားဝှက်ကိုရိုက်ထည့်ပါ",
      },
      SUBMIT_TXT: {
        "th-TH": "ส่ง",
        "en-US": "Submit",
        "id-ID": "Kirim",
        "my-MM": "",
      },
      REQUEST_SUCCESSFULL_TXT: {
        "th-TH": "ความสำเร็จในการยืนยัน!",
        "en-US": "Confirmation Success",
        "id-ID": "Konfirmasi Telah Berhasil!",
        "my-MM": "အတည်ပြုချက်ကိုအောင်မြင်မှု",
      },
      REQUEST_SUCCESSFULL_DESC_TXT: {
        "th-TH": "คำขอของคุณถูกส่งเรียบร้อยแล้ว",
        "en-US": "Your request has been successfully submitted",
        "id-ID": "Permintaan Anda Sudah Berhasil di Kirim",
        "my-MM": "သင်၏တောင်းဆိုမှုကိုအောင်မြင်စွာတင်ပြခဲ့သည်",
      },
      OK_TXT: {
        "th-TH": "ตกลง",
        "en-US": "OK",
        "id-ID": "OK",
        "my-MM": "အဆင်ပြေလား",
      },
      SET_PASSWORD_EMAIL_TTL_TXT: {
        "th-TH": "ตั้งรหัสผ่านและอีเมล์",
        "en-US": "Set Password & Email",
        "id-ID": "Atur Password & E-mail",
        "my-MM": "",
      },
      SET_PASSWORD_EMAIL_DESC_TXT: {
        "th-TH": "โปรดกรอกข้อมูลให้ครบถ้วนก่อนดำเนินการต่อ",
        "en-US": "Please complete your details to continue",
        "id-ID": "Silahkan Lengkapi Terlebih Dahulu Sebelum Melanjutkan",
        "my-MM": "",
      },
      EMAIL_TXT: {
        "th-TH": "ที่อยู่อีเมล",
        "en-US": "Email Address",
        "id-ID": "Alamat E-mail",
        "my-MM": "အီးမေးလ်လိပ်စာ",
      },
      EMAIL_CAPTION_TXT: {
        "th-TH": "ที่อยู่อีเมลของคุณ",
        "en-US": "Your email address",
        "id-ID": "Alamat E-mail Anda",
        "my-MM": "သင့်အီးမေးလ်လိပ်စာ",
      },
      SUCCESS_TXT: {
        "th-TH": "ความสำเร็จ",
        "en-US": "Success",
        "id-ID": "Sukses",
        "my-MM": "အောင်မြင်ခြင်း",
      },
      SET_PASSWORD_EMAIL_SUCCESS_DESC_TXT: {
        "th-TH":
          "รหัสผ่านของคุณได้รับการเปลี่ยนแล้วสำเร็จ โปรดตรวจสอบอีเมลของคุณเพื่อยืนยันอีเมล",
        "en-US":
          "Your password has been changed successfully and Please check your email for email confirmation",
        "id-ID":
          "Password Anda Sudah Berhasil diubah! Silahkan Cek Kembali E-mail Anda Untuk Mengkonfirmasi E-mail Anda",
        "my-MM":
          "သင်၏စကားဝှက်ကိုအောင်မြင်စွာပြောင်းလဲပြီးအီးမေးလ်အတည်ပြုခြင်းအတွက်သင်၏အီးမေးလ်ကိုစစ်ဆေးပါ",
      },
      LOGIN_TXT: {
        "th-TH": "เข้าสู่ระบบ",
        "en-US": "Login",
        "id-ID": "Masuk",
        "my-MM": "လော့ဂ်အင်",
      },
      UPLOAD_PHOTO_TTL_TXT: {
        "th-TH": "การลงทะเบียน",
        "en-US": "Registration",
        "id-ID": "Registrasi",
        "my-MM": "",
      },
      UPLOAD_PHOTO_HEADING_TXT: {
        "th-TH": "หลักฐานประจำตัว",
        "en-US": "Proof of Identity",
        "id-ID": "Bukti Identitas",
        "my-MM": "ဝိသေသလက္ခဏာအထောက်အထား",
      },
      UPLOAD_PHOTO_DESC_TXT: {
        "th-TH":
          "เพื่อการลงทะเบียนเสร็จสมบูรณ์ โปรดอัพโหลดสำเนาบัตรประชาชนพร้อมรูปเซลฟี่ที่ชัดเจนเพื่อยืนยันตัวตน",
        "en-US":
          "In order to completed your registration. Please upload a copy of your identity with a clear selfie photo to proof the document holder",
        "id-ID":
          "Untuk Dapat Menyelesaikan Pendaftaran. Harap Unggah Salinan Identitas Anda Dengan Foto Selfie yang Jelas Untuk Membuktikan Pemegang Dokumen",
        "my-MM":
          "သင်၏မှတ်ပုံတင်ခြင်းပြီးဆုံးရန်အတွက်။ ကျေးဇူးပြု. စာရွက်စာတမ်းကိုင်ဆောင်သူအားသက်သေပြရန်ရှင်းလင်းသော Selfie ဓာတ်ပုံဖြင့်သင်၏ဝိသေသလက္ခဏာမိတ္တူကိုတင်ပါ",
      },
      UPLOAD_PHOTO_CAPTION_TXT: {
        "th-TH": "อัปโหลดหลักฐานการยืนยันตัวตน",
        "en-US": "Upload Proof of Identity",
        "id-ID": "Unggah Bukti Identitas",
        "my-MM": "ဝိသေသလက္ခဏာ၏သက်သေအထောက်အထားတင်",
      },
      UPLOAD_SELFIE_CAPTION_TXT: {
        "th-TH": "อัปโหลดรูปเซลฟี่พร้อมบัตรประชาชน",
        "en-US": "Upload Selfie with Identity",
        "id-ID": "Ambil Foto Selfie Anda Dengan Identitas",
        "my-MM": "ကိုယ်ပိုင်ကဒ်ပြားဖြင့် Selfie ကိုယူပါ",
      },
      PICK_PHOTO: {
        "th-TH": "เลือกไฟล์",
        "en-US": "Choose File",
        "id-ID": "Pilih File",
        "my-MM": "ဖိုင်ရွေးပါ",
      },
      TAKE_PHOTO_TXT: {
        "th-TH": "ถ่ายรูป",
        "en-US": "Take a photo",
        "id-ID": "Ambil Gambar",
        "my-MM": "ဓာတ်ပုံရိုက်ပါ",
      },
      TAKE_SELFIE_TXT: {
        "th-TH": "ถ่ายรูปเซลฟี่",
        "en-US": "Take a selfie",
        "id-ID": "Ambil Foto Selfie",
        "my-MM": "",
      },
      CONFIRM_TXT: {
        "th-TH": "ยืนยัน",
        "en-US": "Confirm",
        "id-ID": "Konfirmasi",
        "my-MM": "အတည်ပြု",
      },
      UPLOAD_PHOTO_ID_EMPTY_ERROR_TXT: {
        "th-TH": "การอัพโหลดหลักฐานการยืนยันตัวตนเป็นขั้นตอนที่ต้องทำ!",
        "en-US": "Upload a Proof Identity is Required!",
        "id-ID": "Unggah Bukti Identitas Diperlukan!",
        "my-MM": "",
      },
      UPLOAD_SELFIE_ID_EMPTY_ERROR_TXT: {
        "th-TH": "การอัปโหลดรูปเซลฟี่พร้อมบัตรประชาชนเป็นขั้นตอนที่ต้องทำ!",
        "en-US": "Upload a Selfie With Identity is Required!",
        "id-ID": "Unggah Foto Selfie Anda Dengan Identitas Diperlukan!",
        "my-MM": "",
      },
      UPLOAD_ERROR_TTL_TXT: {
        "th-TH": "ต้องการ!",
        "en-US": "Required!",
        "id-ID": "Diperlukan!",
        "my-MM": "",
      },
      KYC_TTL_TXT: {
        "th-TH": "การลงทะเบียน",
        "en-US": "Registration",
        "id-ID": "Registrasi",
        "my-MM": "",
      },
      PERSONAL_TXT: {
        "th-TH": "ส่วนตัว",
        "en-US": "Personal",
        "id-ID": "Personal",
        "my-MM": "မိမိပုဂ္ဂိုလ်ရေး",
      },
      EMERGENCY_TXT: {
        "th-TH": "ภาวะฉุกเฉิน",
        "en-US": "Emergency",
        "id-ID": "Kontak Darurat",
        "my-MM": "လတ်လတ်လောလောပေါ်လသောအရေး",
      },
      ADDRESS_TXT: {
        "th-TH": "ที่อยู่",
        "en-US": "Address",
        "id-ID": "Alamat",
        "my-MM": "စကားပေြာဟော",
      },
      KYC_ADDRESS_ERROR_TTL_TXT: {
        "th-TH": "ที่อยู่จำเป็นต้องระบุ!",
        "en-US": "Address is Required!",
        "id-ID": "Alamat Diperlukan!",
        "my-MM": "",
      },
      WORK_INFO_TXT: {
        "th-TH": "ข้อมูลการทำงาน",
        "en-US": "Work Info",
        "id-ID": "Informasi Pekerjaan",
        "my-MM": "အလုပ်အချက်အလက်",
      },
      KYC_NAME_CAPTION_TXT: {
        "th-TH": "ชื่อเต็มของคุณ",
        "en-US": "Your Full Name",
        "id-ID": "Nama Lengkap Anda",
        "my-MM": "",
      },
      KYC_NAME_ERROR_TTL_TXT: {
        "th-TH": "ต้องการชื่อเต็ม!",
        "en-US": "Full Name Required!",
        "id-ID": "Nama Lengkap Diperlukan!",
        "my-MM": "",
      },
      ID_NUMBER_TXT: {
        "th-TH": "หมายเลขประจำตัว",
        "en-US": "Identity Number",
        "id-ID": "Nomor Identitas",
        "my-MM": "အမှတ်အသားနံပါတ်",
      },
      KYC_ID_NUMBER_CAPTION_TXT: {
        "th-TH": "หมายเลขประจำตัวของคุณ",
        "en-US": "Your Identity Number",
        "id-ID": "Nomor Identitas Anda",
        "my-MM": "သင့်ရဲ့ဝိသေသလက္ခဏာနံပါတ်",
      },
      KYC_ID_NUMBER_ERROR_TTL_TXT_01: {
        "th-TH": "จำเป็นต้องมีหมายเลขประจำตัว!",
        "en-US": "Identity Number is Required !",
        "id-ID": "Nomor Identitas Diperlukan !",
        "my-MM": "အထောက်အထားနံပါတ်လိုအပ်သည်!",
      },
      KYC_ID_NUMBER_ERROR_TTL_TXT_02: {
        "th-TH": "หมายเลขประจำตัวต้องมี 16 หลัก!",
        "en-US": "Identity Number must have 16 digits !",
        "id-ID": "Nomor Identitas Harus 16 Angka!",
        "my-MM": "အထောက်အထားနံပါတ်သည်ဂဏန်း 16 လုံးရှိရမည်။",
      },
      KYC_EMAIL_ERROR_TTL_TXT_01: {
        "th-TH": "จำเป็นต้องมีที่อยู่อีเมล!",
        "en-US": "Email Address is Required!",
        "id-ID": "Alamat Email Diperlukan!",
        "my-MM": "အီးမေးလ်လိပ်စာလိုအပ်သည်။",
      },
      KYC_EMAIL_ERROR_TTL_TXT_02: {
        "th-TH": "ดูเหมือนว่าไม่ใช่ที่อยู่อีเมล์",
        "en-US": "That doesn't look like an email address",
        "id-ID": "Tidak Terlihat Seperti Alamat Email",
        "my-MM": "ဒါကအီးမေးလ်လိပ်စာနဲ့မတူဘူး",
      },
      KYC_PHONE_ERROR_TTL_TXT: {
        "th-TH": "จำเป็นต้องระบุหมายเลขโทรศัพท์!",
        "en-US": "Phone Number is Required!",
        "id-ID": "Nomor Telepon Diperlukan!",
        "my-MM": "",
      },
      DOB_TXT: {
        "th-TH": "วันเกิด",
        "en-US": "Date of Birth",
        "id-ID": "Tanggal Lahir",
        "my-MM": "မွေးရက်",
      },
      KYC_DOB_CAPTION_TXT: {
        "th-TH": "วันเกิดของคุณ",
        "en-US": "Your Date of Birth",
        "id-ID": "Tanggal Lahir Anda",
        "my-MM": "",
      },
      KYC_DOB_ERROR_TTL_TXT: {
        "th-TH": "จำเป็นต้องระบุวันเกิด!",
        "en-US": "Date of Birth is Required!",
        "id-ID": "Tanggal Lahir Diperlukan!",
        "my-MM": "သင်၏မွေးနေ့",
      },
      BIRTHPLACE_TXT: {
        "th-TH": "สถานที่เกิด",
        "en-US": "Place of Birth",
        "id-ID": "Tempat Lahir",
        "my-MM": "မွေးရပ်ဒေသ",
      },
      KYC_BIRTHPLACE_CAPTION_TXT: {
        "th-TH": "เช่น. จาการ์ตา",
        "en-US": "e.g Jakarta",
        "id-ID": "mis. Jakarta",
        "my-MM": "e.g Jakarta",
      },
      GENDER_TXT: {
        "th-TH": "เพศ",
        "en-US": "Gender",
        "id-ID": "Jenis Kelamin",
        "my-MM": "ကျားကန်",
      },
      GENDER_CAPTION_TXT: {
        "th-TH": "เลือกเพศ",
        "en-US": "Select Gender",
        "id-ID": "Pilih Jenis Kelamin",
        "my-MM": "ကျား, မရွေးချယ်ပါ",
      },
      GENDER_MALE_TXT: {
        "th-TH": "ชาย",
        "en-US": "MALE",
        "id-ID": "Pria",
        "my-MM": "ယောကျ်ားဖြစ်သော",
      },
      GENDER_FEMALE_TXT: {
        "th-TH": "หญิง",
        "en-US": "FEMALE",
        "id-ID": "Wanita",
        "my-MM": "မိန်းမ",
      },
      KYC_GENDER_ERROR_TTL_TXT: {
        "th-TH": "ต้องใช้เพศ!",
        "en-US": "Gender is Required!",
        "id-ID": "Jenis Kelamin Diperlukan!",
        "my-MM": "ကျား, မရေးရာလိုအပ်သည်။  ",
      },
      RELIGION_TXT: {
        "th-TH": "ศาสนา",
        "en-US": "Religion",
        "id-ID": "Agama",
        "my-MM": "ဘာသာ",
      },
      KYC_RELIGION_ERROR_TTL_TXT: {
        "th-TH": "จำเป็นต้องระบุศาสนา!",
        "en-US": "Religion is Required!",
        "id-ID": "Agama Diperlukan!",
        "my-MM": "",
      },
      RELIGION_CAPTION_TXT: {
        "th-TH": "เลือกศาสนา",
        "en-US": "Select Religion",
        "id-ID": "Pilih Agama",
        "my-MM": "ဘာသာတရားကိုရွေးချယ်ပါ",
      },
      RELIGION_01_TXT: {
        "th-TH": "ชาวมุสลิม",
        "en-US": "MOSLEM",
        "id-ID": "Islam",
        "my-MM": "မောဗလီ",
      },
      RELIGION_02_TXT: {
        "th-TH": "คริสเตียน",
        "en-US": "CHRISTIAN",
        "id-ID": "Kristen",
        "my-MM": "ခရစ်ယန်ဘာသာဝင်",
      },
      RELIGION_03_TXT: {
        "th-TH": "คาทอลิก",
        "en-US": "CATHOLIC",
        "id-ID": "Katolik",
        "my-MM": "ကက်သလစ်ဘာသာဝင်",
      },
      RELIGION_04_TXT: {
        "th-TH": "ฮินดู",
        "en-US": "HINDU",
        "id-ID": "Hindu",
        "my-MM": "ဟိန္ဒူဘာသာနှင့်ဆိုင်သော",
      },
      RELIGION_05_TXT: {
        "th-TH": "บัดฮา",
        "en-US": "BUDHA",
        "id-ID": "Budha",
        "my-MM": "ဂုဏ",
      },
      RELIGION_06_TXT: {
        "th-TH": "ความสับสน",
        "en-US": "CONFUSIUS",
        "id-ID": "Kong Hu Cu",
        "my-MM": "ရှုပ်ကိန်းများ",
      },
      RELIGION_07_TXT: {
        "th-TH": "คนอื่น",
        "en-US": "OTHERS",
        "id-ID": "Lainnya",
        "my-MM": "အခြားသူများ",
      },
      MARITAL_TXT: {
        "th-TH": "สถานภาพการสมรส",
        "en-US": "Marital Status",
        "id-ID": "Status Pernikahan",
        "my-MM": "အိမ်ထောင်ရေးအခြေအနေ",
      },
      MARITAL_CAPTION_TXT: {
        "th-TH": "เลือกสถานภาพสมรส",
        "en-US": "Select Marital Status",
        "id-ID": "Pilih Status Pernikahan",
        "my-MM": "အိမ်ထောင်ရေးအခြေအနေကိုရွေးချယ်ပါ",
      },
      KYC_MARITAL_ERROR_TTL_TXT: {
        "th-TH": "ต้องมีสถานภาพสมรส!",
        "en-US": "Marital Status is Required !",
        "id-ID": "Status Pernikahan Diperlukan !",
        "my-MM": "အိမ်ထောင်ရေးအခြေအနေလိုအပ်ပါသည်။",
      },
      MARITALSTATUS_01_TXT: {
        "th-TH": "เดี่ยว",
        "en-US": "SINGLE",
        "id-ID": "Lajang",
        "my-MM": "တယောက်တည်းသော",
      },
      MARITALSTATUS_02_TXT: {
        "th-TH": "แต่งงานแล้ว",
        "en-US": "MARRIED",
        "id-ID": "Nikah",
        "my-MM": "အိမ်ထောင်ပြီးသော",
      },
      MARITALSTATUS_03_TXT: {
        "th-TH": "ที่หย่าร้าง",
        "en-US": "DIVORCED",
        "id-ID": "Cerai Hidup",
        "my-MM": "နောက်ကျ",
      },
      MARITALSTATUS_04_TXT: {
        "th-TH": "เป็นม่าย",
        "en-US": "WIDOWED",
        "id-ID": "Cerai Mati",
        "my-MM": "မုဆိုးမ",
      },
      MARITALSTATUS_05_TXT: {
        "th-TH": "คนอื่น",
        "en-US": "OTHERS",
        "id-ID": "Lainnya",
        "my-MM": "အခြားသူများ",
      },
      PRIMARY_CONTACT_TXT: {
        "th-TH": "การติดต่อเบื้องต้น",
        "en-US": "Primary Contact",
        "id-ID": "Kontak Utama",
        "my-MM": "အဓိကဆက်သွယ်ရန်",
      },
      SECONDARY_CONTACT_TXT: {
        "th-TH": "ติดต่อรอง",
        "en-US": "Secondary Contact",
        "id-ID": "Kontak Lainnya",
        "my-MM": "အလယ်တန်းအဆက်အသွယ်",
      },
      RELATIONSHIP_TXT: {
        "th-TH": "ความสัมพันธ์",
        "en-US": "Relationship",
        "id-ID": "Hubungan",
        "my-MM": "ဆေွမျိုးသားချင်းဆက်ဆံရေး",
      },
      RELATIONSHIP_CAPTION_TXT: {
        "th-TH": "เลือกความสัมพันธ์",
        "en-US": "Select Relationship",
        "id-ID": "Pilih Hubungan",
        "my-MM": "ဆက်ဆံရေးကိုရွေးချယ်ပါ",
      },
      RELATIONSHIP_ERROR_TXT: {
        "th-TH": "จำเป็นต้องมีความสัมพันธ์!",
        "en-US": "Relationship is Required !",
        "id-ID": "Hubungan Diperlukan !",
        "my-MM": "ဆက်ဆံရေးလိုအပ်ပါသည်!",
      },
      RELATIONSHIP_01_TXT: {
        "th-TH": "คู่สมรส",
        "en-US": "SPOUSE",
        "id-ID": "Istri/Suami",
        "my-MM": "ဇနီး",
      },
      RELATIONSHIP_02_TXT: {
        "th-TH": "ผู้ปกครอง",
        "en-US": "PARENTS",
        "id-ID": "Orang Tua Kandung",
        "my-MM": "မိဘများ",
      },
      RELATIONSHIP_03_TXT: {
        "th-TH": "เด็ก",
        "en-US": "CHILDREN",
        "id-ID": "Anak Kandung",
        "my-MM": "ကလေးများ",
      },
      RELATIONSHIP_04_TXT: {
        "th-TH": "พี่ชายน้องสาว",
        "en-US": "BROTHER/SISTER",
        "id-ID": "Kakak/Adik Kandung",
        "my-MM": "အစ်ကိုအစ်မ",
      },
      RELATIONSHIP_05_TXT: {
        "th-TH": "พี่น้อง",
        "en-US": "SIBLINGS",
        "id-ID": "Saudara",
        "my-MM": "မောင်နှမ",
      },
      RELATIONSHIP_06_TXT: {
        "th-TH": "เพื่อน",
        "en-US": "FRIENDS",
        "id-ID": "Teman Dekat",
        "my-MM": "မိတ်ဆေွး",
      },
      RELATIONSHIP_07_TXT: {
        "th-TH": "เพื่อนร่วมงาน",
        "en-US": "COLLEAGUES",
        "id-ID": "Kolega",
        "my-MM": "လုပ်ဖော်ကိုင်ဖက်များ",
      },
      RELATIONSHIP_08_TXT: {
        "th-TH": "คนอื่น",
        "en-US": "OTHERS",
        "id-ID": "Lainnya",
        "my-MM": "အခြားသူများ",
      },
      RESIDENT_STATUS_TXT: {
        "th-TH": "สถานะที่อยู่อาศัย",
        "en-US": "Residential Status",
        "id-ID": "Status Tempat Tinggal",
        "my-MM": "လူနေအိမ်အဆင့်အတန်း",
      },
      RESIDENT_STATUS_CAPTION_TXT: {
        "th-TH": "เลือกสถานะที่อยู่อาศัย",
        "en-US": "Select Residential Status",
        "id-ID": "Pilih Status Tempat Tinggal",
        "my-MM": "လူနေအိမ်အဆင့်အတန်းကိုရွေးချယ်ပါ",
      },
      RESIDENT_STATUS_ERROR_TTL_TXT: {
        "th-TH": "ต้องใช้สถานะที่อยู่อาศัย!",
        "en-US": "Residential Status is Required!",
        "id-ID": "Status Tempat Tinggal Diperlukan!",
        "my-MM": "လူနေအိမ်အဆင့်အတန်းလိုအပ်သည်!",
      },
      RESIDENTSTATUS_01_TXT: {
        "th-TH": "เป็นเจ้าของ",
        "en-US": "OWN",
        "id-ID": "Rumah Pribadi",
        "my-MM": "ပိုင်ဆိုင်",
      },
      RESIDENTSTATUS_02_TXT: {
        "th-TH": "เช่า",
        "en-US": "RENT",
        "id-ID": "Rumah Sewa",
        "my-MM": "ငှါးခ",
      },
      RESIDENTSTATUS_03_TXT: {
        "th-TH": "เช่าระยะสั้น",
        "en-US": "SHORT_RENT",
        "id-ID": "Rumah Kontrakan",
        "my-MM": "တိုတောင်းသော",
      },
      RESIDENTSTATUS_04_TXT: {
        "th-TH": "ตระกูล",
        "en-US": "FAMILY",
        "id-ID": "Rumah Milik Keluarga",
        "my-MM": "မိသားစု",
      },
      RESIDENTSTATUS_05_TXT: {
        "th-TH": "จำนอง",
        "en-US": "MORTGAGE",
        "id-ID": "Rumah Dalam Angsuran",
        "my-MM": "ပေါင်နှံစာချုပ်",
      },
      RESIDENTSTATUS_06_TXT: {
        "th-TH": "บริษัท",
        "en-US": "COMPANY",
        "id-ID": "Rumah milik Perusahaan",
        "my-MM": "ကုမ္ပဏီ",
      },
      RESIDENTSTATUS_07_TXT: {
        "th-TH": "คนอื่น",
        "en-US": "OTHERS",
        "id-ID": "Lainnya",
        "my-MM": "အခြားသူများ",
      },
      ADDRESS_HOME_TXT: {
        "th-TH": "ที่อยู่",
        "en-US": "Address",
        "id-ID": "Alamat",
        "my-MM": "စကားပေြာဟော",
      },
      ADDRESS_CAPTION_TXT: {
        "th-TH": "ที่อยู่ของคุณ",
        "en-US": "Your Address",
        "id-ID": "Alamat Anda",
        "my-MM": "သင်၏လိပ်စာ",
      },
      PROVINCE_TXT: {
        "th-TH": "จังหวัด",
        "en-US": "Province",
        "id-ID": "Provinsi",
        "my-MM": "နယ်ပယ်",
      },
      PROVINCE_CAPTION_TXT: {
        "th-TH": "เลือกจังหวัดของคุณ",
        "en-US": "Select Your Province",
        "id-ID": "Pilih Provinsi Anda",
        "my-MM": "သင်၏ပြည်နယ်ကိုရွေးချယ်ပါ",
      },
      PROVINCE_ERROR_TTL_TXT: {
        "th-TH": "จำเป็นต้องมีจังหวัด!",
        "en-US": "Province is Required !",
        "id-ID": "Provinsi Diperlukan !",
        "my-MM": "ပြည်နယ်လိုအပ်ပါတယ်!",
      },
      CITY_TXT: {
        "th-TH": "เมือง",
        "en-US": "City",
        "id-ID": "Kota",
        "my-MM": "မြို့",
      },
      CITY_CAPTION_TXT: {
        "th-TH": "เมืองของคุณ",
        "en-US": "Your City",
        "id-ID": "Pilih Kota Anda",
        "my-MM": "မင်းရဲ့မြို့တော်",
      },
      CITY_ERROR_TTL_TXT: {
        "th-TH": "จำเป็นต้องระบุเมือง!",
        "en-US": "City is Required!",
        "id-ID": "Kota Diperlukan!",
        "my-MM": "မြို့လိုအပ်သည်!",
      },
      DISTRICT_TXT: {
        "th-TH": "เขต",
        "en-US": "District",
        "id-ID": "Kecamatan",
        "my-MM": "နယ်",
      },
      DISTRICT_CAPTION_TXT: {
        "th-TH": "เขตของคุณ",
        "en-US": "Your District",
        "id-ID": "Pilih Kecamatan Anda",
        "my-MM": "သင့်ခရိုင်",
      },
      DISTRICT_ERROR_TXT: {
        "th-TH": "จำเป็นต้องระบุอำเภอ!",
        "en-US": "District Required!",
        "id-ID": "Kecamatan Diperlukan!",
        "my-MM": "ခရိုင်လိုအပ်!",
      },
      VILLAGE_TXT: {
        "th-TH": "หมู่บ้าน",
        "en-US": "Village",
        "id-ID": "Kelurahan",
        "my-MM": "ရှာ",
      },
      VILLAGE_CAPTION_TXT: {
        "th-TH": "หมู่บ้านของคุณ",
        "en-US": "Your Village",
        "id-ID": "Pilih Kelurahan Anda",
        "my-MM": "မင်းရဲ့ရွာ",
      },
      VILLAGE_ERROR_TXT: {
        "th-TH": "จำเป็นต้องระบุหมู่บ้าน!",
        "en-US": "Village is Required!",
        "id-ID": "Kelurahan Diperlukan!",
        "my-MM": "ကျေးရွာလိုအပ်ပါတယ်",
      },
      POSTCODE_TXT: {
        "th-TH": "รหัสไปรษณีย์",
        "en-US": "Postal Code",
        "id-ID": "Kode Pos",
        "my-MM": "စာတိုက်သင်္ကေတ",
      },
      POSTCODE_CAPTION_TXT: {
        "th-TH": "รหัสไปรษณีย์ของคุณ",
        "en-US": "Your Postal Code",
        "id-ID": "Kode Pos Anda",
        "my-MM": "သင်၏စာတိုက်ကုဒ်",
      },
      POSTCODE_ERROR_TXT: {
        "th-TH": "ต้องใช้รหัสไปรษณีย์!",
        "en-US": "Postal Code is Required !",
        "id-ID": "Kode Pos Diperlukan !",
        "my-MM": "စာတိုက်ကုဒ်လိုအပ်သည်!",
      },
      POSTCODE_NUMBER_ERROR_TXT: {
        "th-TH": "รหัสไปรษณีย์สูงสุด 7 หลัก!",
        "en-US": "Postal Code maximum 7 digits !",
        "id-ID": "Kode Pos maksimal 7 angka !",
        "my-MM": "စာတိုက်သင်္ကေတအများဆုံးဂဏန်း 7 လုံး!",
      },
      RT_TXT: {
        "th-TH": "ปิดกั้น",
        "en-US": "Block",
        "id-ID": "RT",
        "my-MM": "ပိတ်ဆို့",
      },
      RT_CAPTION_TXT: {
        "th-TH": "บล็อกของคุณ",
        "en-US": "Your Block",
        "id-ID": "Nomor RT Anda",
        "my-MM": "သင့်ရဲ့ပိတ်ပင်တားဆီးမှု",
      },
      RT_ERROR_TXT: {
        "th-TH": "จำเป็นต้องมี RT!",
        "en-US": "RT is Required !",
        "id-ID": "Nomor RT Diperlukan !",
        "my-MM": "RT လိုအပ်သည်။",
      },
      RW_TXT: {
        "th-TH": "ตัวเลข",
        "en-US": "Number",
        "id-ID": "RW",
        "my-MM": "ဂဏန်း",
      },
      RW_CAPTION_TXT: {
        "th-TH": "หมายเลขของคุณ",
        "en-US": "Your Number",
        "id-ID": "Nomor RW Anda",
        "my-MM": "မင်းရဲ့နံပါတ်",
      },
      RW_ERROR_TXT: {
        "th-TH": "ต้องการ rw!",
        "en-US": "RW Required !",
        "id-ID": "Nomor RW Diperlukan !",
        "my-MM": "RW လိုအပ်သည်!",
      },
      HOME_PHONE_NUMBER_TXT: {
        "th-TH": "เบอร์โทรศัพท์บ้าน",
        "en-US": "Home Phone Number",
        "id-ID": "Nomor Telepon Rumah",
        "my-MM": "အိမ်ဖုန်းနံပါတ်",
      },
      HOME_PHONE_NUMBER_CAPTION_TXT: {
        "th-TH": "หมายเลขโทรศัพท์บ้านของคุณ",
        "en-US": "Your Home Phone Number",
        "id-ID": "Nomor Telepon Rumah Anda",
        "my-MM": "သင့်အိမ်ဖုန်းနံပါတ်",
      },
      HOME_PHONE_NUMBER_ERROR_TTL_TXT: {
        "th-TH": "ต้องมีหมายเลขโทรศัพท์บ้าน!",
        "en-US": "Home Phone Number is Required !",
        "id-ID": "Nomor Telepon Rumah Diperlukan !",
        "my-MM": "အိမ်ဖုန်းနံပါတ်လိုအပ်သည်။",
      },
      LAST_EDUCATION_TXT: {
        "th-TH": "ระดับการศึกษาครั้งสุดท้าย",
        "en-US": "Last Education Level",
        "id-ID": "Pendidikan Terakhir",
        "my-MM": "နောက်ဆုံးပညာရေးအဆင့်",
      },
      LAST_EDUCATION_CAPTION_TXT: {
        "th-TH": "เลือกการศึกษา",
        "en-US": "Select Education",
        "id-ID": "Pilih Pendidikan",
        "my-MM": "ပညာရေးကိုရွေးချယ်ပါ",
      },
      LAST_EDUCATION_ERROR_TTL_TXT: {
        "th-TH": "ระดับการศึกษาครั้งสุดท้ายต้องใช้!",
        "en-US": "Last Education Level Required !",
        "id-ID": "Pendidikan Terakhir Diperlukan !",
        "my-MM": "နောက်ဆုံးပညာရေးအဆင့်လိုအပ်သည်!",
      },
      EDUCATION_01_TXT: {
        "th-TH": "ไม่เข้าโรงเรียน",
        "en-US": "NOT ATTENDING SCHOOL",
        "id-ID": "Tidak Menghadiri Sekolah",
        "my-MM": "ကျောင်းမတက်ခြင်း",
      },
      EDUCATION_02_TXT: {
        "th-TH": "โรงเรียนประถมศึกษา",
        "en-US": "ELEMENTARY SCHOOL",
        "id-ID": "Sekolah Dasar",
        "my-MM": "မူလတန်းကျောင်း",
      },
      EDUCATION_03_TXT: {
        "th-TH": "มัธยมศึกษาตอนต้น",
        "en-US": "JUNIOR HIGH SCHOOL",
        "id-ID": "Sekolah Menengah Pertama",
        "my-MM": "အငယ်တန်းအထက်တန်းကျောင်း",
      },
      EDUCATION_04_TXT: {
        "th-TH": "มัธยมปลาย",
        "en-US": "SENIOR HIGH SCHOOL",
        "id-ID": "Sekolah Menengah Atas",
        "my-MM": "အကြီးတန်းအထက်တန်းကျောင်း",
      },
      EDUCATION_05_TXT: {
        "th-TH": "วิทยาลัยเทคนิค",
        "en-US": "TECHNICAL COLLEGE",
        "id-ID": "Perguruan tinggi teknik",
        "my-MM": "နည်းပညာကောလိပ်",
      },
      EDUCATION_06_TXT: {
        "th-TH": "ประกาศนียบัตร 1",
        "en-US": "DIPLOMA 1",
        "id-ID": "DIPLOMA 1",
        "my-MM": "ဒီပလိုမာ 1",
      },
      EDUCATION_07_TXT: {
        "th-TH": "ประกาศนียบัตร 2",
        "en-US": "DIPLOMA 2",
        "id-ID": "DIPLOMA 2",
        "my-MM": "ဒီပလိုမာ 2",
      },
      EDUCATION_08_TXT: {
        "th-TH": "อนุปริญญา 3",
        "en-US": "DIPLOMA 3",
        "id-ID": "DIPLOMA 3",
        "my-MM": "ဒီပလိုမာ 3",
      },
      EDUCATION_09_TXT: {
        "th-TH": "ปริญญาตรี",
        "en-US": "BACHELOR DEGREE",
        "id-ID": "Gelar Sarjana",
        "my-MM": "တက္ကသိုလ်ဘွဲ့",
      },
      EDUCATION_10_TXT: {
        "th-TH": "ปริญญาโท",
        "en-US": "MASTER DEGREE",
        "id-ID": "Gelar Master",
        "my-MM": "မဟာဒီဂရီ",
      },
      EDUCATION_11_TXT: {
        "th-TH": "ปริญญาแพทย์",
        "en-US": "DOCTOR DEGREE",
        "id-ID": "Gelar Doktor",
        "my-MM": "ဆရာဝန်ဘွဲ့",
      },
      JOB_TYPE_TXT: {
        "th-TH": "ประเภทงาน",
        "en-US": "Job Type",
        "id-ID": "Jenis Pekerjaan",
        "my-MM": "အလုပ်အမျိုးအစား",
      },
      JOB_TYPE_CAPTION_TXT: {
        "th-TH": "เลือกประเภทงาน",
        "en-US": "Select Job Type",
        "id-ID": "Pilih Jenis Pekerjaan",
        "my-MM": "အလုပ်အမျိုးအစားကိုရွေးပါ",
      },
      JOB_TYPE_ERROR_TXT: {
        "th-TH": "ต้องใช้ประเภทงาน!",
        "en-US": "Job Type is Required !",
        "id-ID": "Jenis Pekerjaan Diperlukan !",
        "my-MM": "အလုပ်အမျိုးအစားလိုအပ်သည်။",
      },
      JOBTYPE_01_TXT: {
        "th-TH": "ผู้ประกอบการ",
        "en-US": "Entrepreneur",
        "id-ID": "Wirausaha",
        "my-MM": "ဆီးယနာသားကိုယ်စားလှယ်လုပ်ငန်းရှင်",
      },
      JOBTYPE_02_TXT: {
        "th-TH": "พนักงาน (ส่วนตัว)",
        "en-US": "Employee(private)",
        "id-ID": "Wiraswasta, Sektor Privat",
        "my-MM": "0 န်ထမ်း (ပုဂ္ဂလိက)",
      },
      JOBTYPE_03_TXT: {
        "th-TH": "พนักงาน (สาธารณะ)",
        "en-US": "Employee(public)",
        "id-ID": "Wiraswasta",
        "my-MM": "ဝန်ထမ်း (အများပြည်သူ)",
      },
      JOBTYPE_04_TXT: {
        "th-TH": "มืออาชีพ",
        "en-US": "Professional",
        "id-ID": "Profesional",
        "my-MM": "အလုပ်တတ်မေြာက်သူ",
      },
      JOBTYPE_05_TXT: {
        "th-TH": "พลเรือน",
        "en-US": "Civil",
        "id-ID": "Penduduk Sipil",
        "my-MM": "မြို့",
      },
      JOBTYPE_06_TXT: {
        "th-TH": "นักเรียน",
        "en-US": "Student",
        "id-ID": "Pelajar",
        "my-MM": "ကေျာင်းသူ",
      },
      JOBTYPE_07_TXT: {
        "th-TH": "ว่างงาน",
        "en-US": "Unemployed",
        "id-ID": "Penganggur",
        "my-MM": "အလုပ်လက်မဲ့သော",
      },
      JOBTYPE_08_TXT: {
        "th-TH": "พนักงานกิตติมศักดิ์ / เอาท์ซอร์ส",
        "en-US": "Honorary / Outsourcing employee",
        "id-ID": "Karyawan honorer/outsourcing",
        "my-MM": "ဂုဏ်ထူးဆောင် / outsourcing ဝန်ထမ်း",
      },
      JOB_POSITION_TXT: {
        "th-TH": "ตำแหน่งงาน",
        "en-US": "Job Position",
        "id-ID": "Posisi Pekerjaan",
        "my-MM": "အလုပ်အနေအထား",
      },
      JOB_POSITION_CAPTION_TXT: {
        "th-TH": "เลือกตำแหน่งงาน",
        "en-US": "Select Job Position",
        "id-ID": "Pilih Posisi Pekerjaan",
        "my-MM": "အလုပ်အနေအထားကိုရွေးပါ",
      },
      JOB_POSITION_ERROR_TXT: {
        "th-TH": "จำเป็นต้องมีตำแหน่งงาน!",
        "en-US": "Job Position is Required !",
        "id-ID": "Posisi Pekerjaan Diperlukan !",
        "my-MM": "အလုပ်အနေအထားလိုအပ်သည်!",
      },
      JOBPOSITION_01_TXT: {
        "th-TH": "หัวหน้างาน",
        "en-US": "Supervisor",
        "id-ID": "Pengawas",
        "my-MM": "ကြီးကြပ်သူ",
      },
      JOBPOSITION_02_TXT: {
        "th-TH": "ผู้อำนวยการ",
        "en-US": "Director",
        "id-ID": "Direktur",
        "my-MM": "ညွှန်ကြားရေးမှူး",
      },
      JOBPOSITION_03_TXT: {
        "th-TH": "ผู้จัดการ",
        "en-US": "Manager",
        "id-ID": "Manajer",
        "my-MM": "မန်နေဂျာ",
      },
      JOBPOSITION_04_TXT: {
        "th-TH": "พนักงาน",
        "en-US": "Staff",
        "id-ID": "Staf",
        "my-MM": "အရာရှိစု",
      },
      JOBPOSITION_05_TXT: {
        "th-TH": "คนอื่น",
        "en-US": "Others",
        "id-ID": "Lainnya",
        "my-MM": "အခြားသူများ",
      },
      WORK_SINCE_TXT: {
        "th-TH": "ทำงานตั้งแต่",
        "en-US": "Work Since",
        "id-ID": "Bekerja Sejak",
        "my-MM": "ကတည်းကအလုပ်လုပ်ကြသည်",
      },
      WORK_SINCE_CAPTION_TXT: {
        "th-TH": "งานของคุณตั้งแต่",
        "en-US": "Your Work Since",
        "id-ID": "Anda Bekerja Sejak",
        "my-MM": "ကတည်းကသင်၏အလုပ်",
      },
      WORK_SINCE_ERROR_TXT: {
        "th-TH": "ต้องระบุเริ่มทำงานเมื่อ!",
        "en-US": "Work Since is Required!",
        "id-ID": "Bekerja Sejak Diperlukan!",
        "my-MM": "ကတည်းကအလုပ်လုပ်ကိုင်ရန်လိုအပ်သည်!",
      },
      TAX_ID_TXT: {
        "th-TH": "หมายเลขภาษี",
        "en-US": "Tax Number",
        "id-ID": "Nomor Pokok Wajib Pajak (NPWP)",
        "my-MM": "အခွန်ကုဒ်နံပါတ်",
      },
      TAX_ID_CAPTION_TXT: {
        "th-TH": "หมายเลขภาษีของคุณ",
        "en-US": "Your Tax Number",
        "id-ID": "Nomor Pokok Wajib Pajak (NPWP) Anda",
        "my-MM": "သင့်အခွန်နံပါတ်",
      },
      TAX_ID_ERROR_TXT: {
        "th-TH": "ต้องการหมายเลขภาษี!",
        "en-US": "NPWP is Required !",
        "id-ID": "Nomor Pokok Wajib Pajak (NPWP) Diperlukan!",
        "my-MM": "NPWP လိုအပ်သည်။",
      },
      INCOME_TXT: {
        "th-TH": "รายได้",
        "en-US": "Income",
        "id-ID": "Pemasukan",
        "my-MM": "ဝင်ငေွ",
      },
      INCOME_CAPTION_TXT: {
        "th-TH": "รายได้ของคุณ",
        "en-US": "Your Income",
        "id-ID": "Pemasuka Anda",
        "my-MM": "သင့်ရဲ့ဝင်ငွေ",
      },
      INCOME_ERROR_TXT: {
        "th-TH": "ต้องมีรายได้!",
        "en-US": "Income is Required !",
        "id-ID": "Pemasukan Diperlukan!",
        "my-MM": "ဝင်ငွေလိုအပ်ပါသည်!",
      },
      JOBINDUSTRY_01_TXT: {
        "th-TH": "เกษตรกรรม",
        "en-US": "Agriculture",
        "id-ID": "Agrikultur",
        "my-MM": "လယ်ယာစိုက်ပျိုးရေး",
      },
      JOBINDUSTRY_02_TXT: {
        "th-TH": "อาคาร",
        "en-US": "Building",
        "id-ID": "Sipil/Konstruksi Bangunan",
        "my-MM": "အဆောက်အဉီး",
      },
      JOBINDUSTRY_03_TXT: {
        "th-TH": "การสื่อสาร",
        "en-US": "Communication",
        "id-ID": "Sarana Komunikasi",
        "my-MM": "ဆက်သွယ်ရေး",
      },
      JOBINDUSTRY_04_TXT: {
        "th-TH": "ไฟฟ้าก๊าซและน้ำ",
        "en-US": "Electricity, gas, and water",
        "id-ID": "Bidang Listrik/Gas/Air",
        "my-MM": "လျှပ်စစ်, ဓာတ်ငွေ့နှင့်ရေ",
      },
      JOBINDUSTRY_05_TXT: {
        "th-TH": "การเงิน",
        "en-US": "Finance",
        "id-ID": "Akuntansi Umum/Keuangan",
        "my-MM": "ငေွကေြးထောက်ပံ့",
      },
      JOBINDUSTRY_06_TXT: {
        "th-TH": "ราชการ",
        "en-US": "Government service",
        "id-ID": "Kepemerintahan",
        "my-MM": "အစိုးရဝန်ဆောင်မှု",
      },
      JOBINDUSTRY_07_TXT: {
        "th-TH": "ตำรวจแห่งชาติอินโดนีเซีย",
        "en-US": "Indonesia national police",
        "id-ID": "Pelayanan Kemasyarakatan",
        "my-MM": "အင်ဒိုနီးရှားအမျိုးသားရဲ",
      },
      JOBINDUSTRY_08_TXT: {
        "th-TH": "บริษัท ทนายความ",
        "en-US": "Lawyer Firm",
        "id-ID": "Hukum/Legal",
        "my-MM": "ရှေ့နေကုမ္ပဏီ",
      },
      JOBINDUSTRY_09_TXT: {
        "th-TH": "สื่อภาพยนตร์",
        "en-US": "Media, Film",
        "id-ID": "Seni/Media",
        "my-MM": "မီဒီယာ, ရုပ်ရှင်",
      },
      JOBINDUSTRY_10_TXT: {
        "th-TH": "ทหาร",
        "en-US": "Military",
        "id-ID": "Keamanan/Angkatan Bersenjata",
        "my-MM": "လက်နက်ကိုင်အင်အားစု",
      },
      JOBINDUSTRY_11_TXT: {
        "th-TH": "การขุดและขุด",
        "en-US": "Mining and excavation",
        "id-ID": "Pertambangan",
        "my-MM": "သတ္တုတူးဖော်ရေးနှင့်တူးဖော်ရေး",
      },
      JOBINDUSTRY_12_TXT: {
        "th-TH": "น้ำมันและก๊าซ",
        "en-US": "Oil and gas",
        "id-ID": "Minyak dan Gas Bumi",
        "my-MM": "ရေနံနှင့်သဘာဝဓာတ်ငွေ့",
      },
      JOBINDUSTRY_13_TXT: {
        "th-TH": "บริการส่วนตัว",
        "en-US": "Private services",
        "id-ID": "Pelayanan Pelanggan",
        "my-MM": "ပုဂ္ဂလိကဝန်ဆောင်မှုများ",
      },
      JOBINDUSTRY_14_TXT: {
        "th-TH": "การค้าโรงแรมและร้านอาหาร",
        "en-US": "Trade, hotel, and restaurant",
        "id-ID": "Hotel/Pariwisata",
        "my-MM": "ကုန်သွယ်ရေး, ဟိုတယ်နှင့်စားသောက်ဆိုင်",
      },
      JOBINDUSTRY_15_TXT: {
        "th-TH": "การขนส่ง",
        "en-US": "Transportation",
        "id-ID": "Jasa Transportasi",
        "my-MM": "တင်ဆောင်ခြင်း",
      },
      JOBINDUSTRY_16_TXT: {
        "th-TH": "อื่นๆ",
        "en-US": "Others",
        "id-ID": "Lainnya",
        "my-MM": "အခြားသူများ",
      },
      BACK_TXT: {
        "th-TH": "กลับ",
        "en-US": "Back",
        "id-ID": "Kembali",
        "my-MM": "ပြန်..",
      },
      NEXT_TXT: {
        "th-TH": "ต่อไป",
        "en-US": "Next",
        "id-ID": "Selanjutnya",
        "my-MM": "နောက်တစ်ခု",
      },
      FINISH_TXT: {
        "th-TH": "เสร็จ",
        "en-US": "Finish",
        "id-ID": "Selesai",
        "my-MM": "အပြီးသတ်",
      },
      FAQ_01_TXT: {
        "th-TH": "Smartek คืออะไร?",
        "en-US": "What is Smartek?",
        "id-ID": "Apa itu Smartek?",
        "my-MM": "ဘီလာပါလား",
      },
      FAQ_01_CAPTION_TXT: {
        "th-TH":
          "Smartek เป็นโซลูชันทางการเงินของพนักงานที่ได้รับประโยชน์ซึ่งช่วยให้พนักงานของคุณสามารถตัดสินใจวันจ่ายเงินเดือนของตัวเองในวิธีที่เร็วและสะดวกที่สุดเท่าที่จะเป็นไปได้ซึ่งช่วยให้พนักงานของคุณได้รับเงินเดือนของพวกเขาได้อย่างราบรื่นมากขึ้นทุกที่ทุกเวลาและทุกที่!",
        "en-US":
          "Smartek is your employee benefit financial solution, that allows your employees to decide their own payday in the fastest and most convenient way possible that allows your employees to earn their Salary more seamlessly Anytime, Anywhere & Anyhow!",
        "id-ID":
          "Smartek adalah solusi keuangan tunjangan karyawan Anda, yang memungkinkan karyawan Anda menentukan hari gajian mereka sendiri dengan cara tercepat dan paling nyaman yang memungkinkan karyawan Anda memperoleh Gaji mereka lebih mudah Kapanpun, Dimanapun & Bagaimanapun!",
        "my-MM":
          "Smartek သင်၏ 0 န်ထမ်းအကျိုးအတွက်အကျိုးခံစားခွင့်ရှိသောဘဏ္ financial ာရေးဖြေရှင်းချက်သည်သင်၏ 0 န်ထမ်းများအားအမြန်ဆုံးနှင့်အဆင်ပြေဆုံးနည်းလမ်းဖြင့်၎င်းတို့၏ 0 န်ထမ်းများကိုပိုမိုချောမွေ့စွာရရှိရန်ခွင့်ပြုသည်။",
      },
      FAQ_02_TXT: {
        "th-TH": "Smartek ทำงานอย่างไร?",
        "en-US": "How does Smartek work?",
        "id-ID": "Bagaimana cara kerja Smartek?",
        "my-MM": "ဘယ်လိုအလုပ်လဲ?",
      },
      FAQ_02_CAPTION_TXT: {
        "th-TH":
          "เป็นหุ้นส่วนกับนายจ้างเพื่อช่วยให้ บริษัท มีความเข้าใจที่ดีขึ้นเกี่ยวกับสุขภาพทางการเงินของพนักงาน เมื่อนายจ้างตกลงที่จะเสนอให้พนักงานของพวกเขาเป็นผลประโยชน์ของพนักงานพนักงานสามารถเข้าถึงได้รับ แต่เงินเดือนที่ไม่ได้รับค่าจ้างผ่านแอพ Smartek",
        "en-US":
          "Smartek partners with employers to help the company have a better understanding of their employee’s financial wellness. Once an employer agrees to offer Smartek to their employees as an employee benefit, employees can access their earned but unpaid Salary via the Smartek app.",
        "id-ID":
          "Smartek bermitra dengan pemberi kerja untuk membantu perusahaan memiliki pemahaman yang lebih baik tentang kesehatan finansial karyawan mereka. Setelah pemberi kerja setuju untuk menawarkan Smartek kepada karyawannya sebagai tunjangan karyawan, karyawan dapat mengakses Gaji yang diperoleh tetapi belum dibayar melalui aplikasi Smartek.",
        "my-MM":
          "ကုမ္ပဏီအားကုမ္ပဏီအားသူတို့၏ 0 န်ထမ်းများ၏ဘဏ္ financy ာရေးကျန်းမာမှုကိုပိုမိုနားလည်ရန်ကူညီရန်အလုပ်ရှင်များနှင့်မိတ်ဖက်များပါ 0 င်သည်။ 0 န်ထမ်းများအကျိုးခံစားခွင့်အနေဖြင့်အလုပ်ရှင်သည် 0 န်ထမ်းများအား 0 န်ထမ်းများထံမှလူတိုင်းကမ်းလှမ်းရန်သဘောတူသည်နှင့်တပြိုင်နက် 0 န်ထမ်းများသည် 0 န်ထမ်းများထံမှ 0 န်ထမ်းများထံမှ 0 င်ငွေရရှိသောလစာကို 0 င်ရောက်နိုင်သည်။",
      },
      FAQ_03_TXT: {
        "th-TH": "มีค่าใช้จ่ายสำหรับการใช้แอป Smartek หรือไม่?",
        "en-US": "Is there any cost for using the Smartek app?",
        "id-ID": "Apakah ada biaya untuk menggunakan aplikasi Smartek?",
        "my-MM": "Smartek app ကိုအသုံးပြုရန်အတွက်ကုန်ကျစရိတ်ရှိပါသလား။",
      },
      FAQ_03_CAPTION_TXT: {
        "th-TH":
          "ไม่ไม่มีค่าใช้จ่ายใด ๆ ในการตั้งค่าหรือมอบให้กับพนักงานของคุณ",
        "en-US":
          "No, there is no cost at all to set up or give Smartek to your employees.",
        "id-ID":
          "Tidak, tidak ada biaya sama sekali untuk menyiapkan atau memberikan Smartek kepada karyawan Anda.",
        "my-MM":
          "မဟုတ်ပါ, သင့် 0 န်ထမ်းများအားမည်သည့်နေရာတွင်တည်ဆောက်ရန်သို့မဟုတ်ပေးရန်အတွက်လုံးဝကုန်ကျစရိတ်မရှိပါ။",
      },
      FAQ_04_TXT: {
        "th-TH": "ฉันจะคืนเงินเดือนล่วงหน้าได้อย่างไร?",
        "en-US": "How do I return my Salary in Advance?",
        "id-ID": "Bagaimana cara mengembalikan Gaji saya di Muka?",
        "my-MM": "ကျွန်ုပ်၏လစာကိုမည်သို့ကြိုတင်ပေးဆပ်ရမည်နည်း။",
      },
      FAQ_04_CAPTION_TXT: {
        "th-TH":
          "การชำระเงินจะถูกหักโดยระบบทันทีจากเงินเดือนของพนักงานทุกเดือน",
        "en-US":
          "Payments will be immediately deducted by system from the Employees’ salary every month.",
        "id-ID":
          "Pembayaran akan langsung dipotong oleh sistem dari gaji Karyawan setiap bulan.",
        "my-MM":
          "ငွေပေးချေမှုများကိုလစဉ်လစဉ် 0 န်ထမ်းများလစာမှလစဉ်စနစ်ဖြင့်နုတ်ယူလိမ့်မည်။",
      },
      FAQ_05_TXT: {
        "th-TH": "เราสามารถขอสินเชื่อจาก Smartek ได้หรือไม่?",
        "en-US": "Can we apply for a loan from Smartek?",
        "id-ID": "Bisakah kita mengajukan pinjaman dari Smartek?",
        "my-MM": "ကျွန်ုပ်တို့သည်ချေးငွေအတွက်လျှောက်ထားနိုင်ပါသလား။",
      },
      FAQ_05_CAPTION_TXT: {
        "th-TH":
          "ไม่ Smartek ไม่ใช่โซลูชันเครดิต ไม่มีการจัดจำหน่ายหลักทรัพย์ไม่มีค่าธรรมเนียมที่ซ่อนอยู่ไม่มีการสร้างหนี้และไม่มีการเรียกเก็บดอกเบี้ย",
        "en-US":
          "No. Smartek is not a credit solution. There is no underwriting, no hidden fees, no debt created, and no interest charged.",
        "id-ID":
          "Tidak. Smartek bukanlah solusi kredit. Tidak ada penjaminan, tidak ada biaya tersembunyi, tidak ada hutang yang dibuat, dan tidak ada bunga yang dibebankan.",
        "my-MM":
          "မဟုတ်ပါ။ Beat သည်ခရက်ဒစ်အဖြေမဟုတ်ပါ။ underwriting, ဝှက်ထားသောအခကြေးငွေများမရှိ, ကြွေးမြီမရှိပါ။",
      },
      FAQ_06_TXT: {
        "th-TH": "พนักงานได้รับเงินเดือนล่วงหน้าอย่างไร",
        "en-US": "How do employees receive their Salary in Advance?",
        "id-ID": "Bagaimana cara karyawan menerima Gaji mereka di Muka?",
        "my-MM": "0 န်ထမ်းများသည်လစာကိုမည်သို့ကြိုတင်ရရှိသနည်း။",
      },
      FAQ_06_CAPTION_TXT: {
        "th-TH":
          "เงินเดือนของคุณล่วงหน้าจะอยู่ในบัญชีธนาคารของคุณหรือช่องทางการชำระเงินใด ๆ ที่คุณเลือกภายใน 2-3 นาที",
        "en-US":
          "Your Salary in Advance will be in your bank account or any payment channel you choose within 2-3minutes.",
        "id-ID":
          "Gaji Anda akan ada di rekening bank Anda atau saluran pembayaran apa pun yang Anda pilih dalam 2-3 menit.",
        "my-MM":
          "သင်၏လစာသည်သင်၏ဘဏ်အကောင့်သို့မဟုတ်သင်ရွေးချယ်သောငွေပေးချေသည့်ရုပ်သံလိုင်းများတွင် 2-3 မိနစ်အတွင်းသင်ရွေးချယ်သည့်မည်သည့်ငွေပေးချေမှုလမ်းကြောင်းပေါ်တွင်ရှိလိမ့်မည်။",
      },
      FAQ_07_TXT: {
        "th-TH": "ใช้เวลานานแค่ไหนในการใช้แอพ Smartek?",
        "en-US": "How long does it take to implement the Smartek app?",
        "id-ID":
          "Berapa lama waktu yang dibutuhkan untuk mengimplementasikan aplikasi Smartek?",
        "my-MM": "Smartek အက်ပ်ကိုအကောင်အထည်ဖော်ရန်မည်မျှကြာသနည်း။",
      },
      FAQ_07_CAPTION_TXT: {
        "th-TH":
          "การแก้ปัญหาเงินเดือนล่วงหน้าของเราสามารถอยู่ได้ภายในระยะเวลาสูงสุด 48 ชั่วโมงในวันทำการ",
        "en-US":
          "Our Salary in advance solution could go live within max.48 hours in working days.",
        "id-ID":
          "Solusi Gaji di Muka dapat diterima dalam maksimal 48 jam di hari kerja.",
        "my-MM":
          "ကျွန်ုပ်တို့၏လစာကိုကြိုတင်အဖြေတွင်အလုပ်လုပ်ရန်အလုပ်ရက်အတွင်း Max.48 နာရီအတွင်းနေထိုင်နိုင်သည်။",
      },
      FAQ_08_TXT: {
        "th-TH": "",
        "en-US": "What devices are supported by the Smartek app?",
        "id-ID": "Perangkat apa yang didukung oleh aplikasi Smartek?",
        "my-MM": "",
      },
      FAQ_08_CAPTION_TXT: {
        "th-TH": "",
        "en-US":
          "Currently, we are only available to Android users only. iOS is still in development and we’ll be ready soon!",
        "id-ID":
          "Saat ini, kami hanya tersedia untuk pengguna Android saja. iOS masih dalam pengembangan dan kami akan segera siap!",
        "my-MM": "",
      },
      PDF_SWP_LEFT: {
        "th-TH": "ปัดไปทางซ้าย",
        "en-US": "Swipe Left",
        "id-ID": "Geser ke Kiri",
        "my-MM": "လက်ဝဲကိုပွတ်ဆွဲ",
      },
      PDF_SWP_RIGHT: {
        "th-TH": "ปัดขวา",
        "en-US": "Swipe Right",
        "id-ID": "Geser ke Kanan",
        "my-MM": "ညာဘက်ပွတ်ဆွဲ",
      },
      PDF_ZOOM: {
        "th-TH": "หยิกเพื่อซูม",
        "en-US": "Pinch to Zoom",
        "id-ID": "Cubit Untuk Memperbesar",
        "my-MM": "zoom မှ pinch",
      },
      KYC_MOTHER_NAME_TTL_TXT: {
        "th-TH": "แม่ชื่อ",
        "en-US": "Mother’s Name",
        "id-ID": "Nama Ibu Kandung",
        "my-MM": "မိခင်နာမည်",
      },
      KYC_MOTHER_NAME_CAPTION_TTL_TXT: {
        "th-TH": "ชื่อแม่ของคุณอ",
        "en-US": "Your Mother’s Name",
        "id-ID": "Nama Ibu Kandung Anda",
        "my-MM": "မင်းအမေနာမည်",
      },
      KYC_MOTHER_NAME_ERROR_TTL_TXT: {
        "th-TH": "ต้องใช้ชื่อแม่!",
        "en-US": "Mother’s Name is Required !",
        "id-ID": "Nama Ibu Kandung Diperlukan !",
        "my-MM": "မိခင်၏အမည်လိုအပ်သည်။",
      },
      KYC_DEPENDENTS_TTL_TXT: {
        "th-TH": "ผู้ติดตาม",
        "en-US": "Dependents",
        "id-ID": "Tanggungan",
        "my-MM": "မှီခိုသူများ",
      },
      KYC_DEPENDENTS_CAPTION_TTL_TXT: {
        "th-TH": "ผู้ติดตามของคุณ",
        "en-US": "Your Dependents",
        "id-ID": "Tanggungan Anda",
        "my-MM": "သင်၏မှီခိုသူများ",
      },
      KYC_DEPENDENTS_ERROR_TTL_TXT: {
        "th-TH": "ผู้ติดตามของคุณเป็นสิ่งจำเป็น!",
        "en-US": "Your Dependents is Required !",
        "id-ID": "Tanggungan Diperlukan !",
        "my-MM": "သင်၏မှီခိုသူများလိုအပ်သည်။",
      },
      CONFIRM_PASSWORD_TTL_TXT: {
        "th-TH": "รหัสผ่านยืนยัน",
        "en-US": "Konfirmasi Password",
        "id-ID": "Konfirmasi Password",
        "my-MM": "အတည်ပြုချက်စကားဝှက်",
      },
      CONFIRM_PASSWORD_CAPTION_TTL_TXT: {
        "th-TH": "รหัสผ่านยืนยันของคุณ",
        "en-US": "Konfirmasi Password Anda",
        "id-ID": "Konfirmasi Password Anda",
        "my-MM": "သင်၏အတည်ပြုချက်စကားဝှက်",
      },
      USER_MANUAL_TITLE_01: {
        "th-TH": "บทนำ",
        "en-US": "Introduction",
        "id-ID": "Perkenalan",
        "my-MM": "နိဒါန်း",
      },
      USER_MANUAL_TITLE_02: {
        "th-TH": "พนักงาน Salary Advances ทำงานอย่างไร?",
        "en-US": "How employee salary advances work",
        "id-ID": "Bagaimana Saldo Kasbon Bekerja",
        "my-MM": "",
      },
      USER_MANUAL_TITLE_03: {
        "th-TH": "ทันเวลา Salary Advance",
        "en-US": "Timely Salary Advance",
        "id-ID": "Gaji Tepat Waktu",
        "my-MM": "",
      },
      SOON_TTL_TEXT_01: {
        "th-TH": "คุณลักษณะนี้จะ ",
        "en-US": "This Feature Will ",
        "id-ID": "Fitur ini akan ",
        "my-MM": "",
      },
      SOON_TTL_TEXT_02: {
        "th-TH": "จะพร้อมใช้งานในเร็ว ๆ นี้",
        "en-US": "Available Soon",
        "id-ID": "Segera Tersedia",
        "my-MM": "",
      },
      SA_RECEIVE_DETAIL_ACC_TXT: {
        "th-TH": "กำลังรับรายละเอียด",
        "en-US": "Receiving Detail",
        "id-ID": "Receiving Detail",
        "my-MM": "",
      },
      SET_PASSWORD_SUCCESS_DESC_TXT: {
        "th-TH": "รหัสผ่านของคุณถูกเปลี่ยนเรียบร้อยแล้ว!",
        "en-US": "Your password has been changed successfully!",
        "id-ID": "Password Anda Sudah Berhasil diubah!",
        "my-MM": "",
      },
      SET_EMAIL_SUCCESS_DESC_TXT: {
        "th-TH": "โปรดตรวจสอบอีเมลของคุณอีกครั้งเพื่อยืนยันอีเมลของคุณ",
        "en-US": "Please check your email to confirm your email",
        "id-ID":
          "Silahkan Cek Kembali E-mail Anda Untuk Mengkonfirmasi E-mail Anda",
        "my-MM": "",
      },
      PASSWORD_CHANGED_TXT: {
        "th-TH": "เปลี่ยนรหัสผ่าน",
        "en-US": "Password Changed",
        "id-ID": "Password Berubah",
        "my-MM": "စကားဝှက်ကိုပြောင်းလဲသွားတယ်",
      },
      CANCEL_TXT: {
        "th-TH": "ยกเลิก",
        "en-US": "Cancel",
        "id-ID": "Batalkan",
        "my-MM": "ပျက်စေ",
      },
      CONTINUE_TXT: {
        "th-TH": "ดำเนินการต่อ",
        "en-US": "Continue",
        "id-ID": "Lanjutkan",
        "my-MM": "",
      },
      CONFIRMATION_TXT: {
        "th-TH": "ยืนยัน",
        "en-US": "Confirmation",
        "id-ID": "Konfirmasi",
        "my-MM": "",
      },
      CONFIRMATION_LOGOUT_TXT: {
        "th-TH": "คุณแน่ใจว่าต้องการออกจากระบบ?",
        "en-US": "Are you sure you want to log out?",
        "id-ID": "Apakah Anda yakin ingin keluar?",
        "my-MM": "",
      },
      AVAILABLE_FEATURE_01_TXT: {
        "th-TH": "คุณลักษณะนี้จะ ",
        "en-US": "This Feature Will be ",
        "id-ID": "Fitur Ini Akan ",
        "my-MM": "",
      },
      AVAILABLE_FEATURE_02_TXT: {
        "th-TH": "สามารถใช้งานได้เร็ว ๆ นี้",
        "en-US": "Available Soon",
        "id-ID": "Segera Rilis",
        "my-MM": "",
      },
      Failed: {
        "th-TH": "ล้มเหลว",
        "en-US": "Failed",
        "id-ID": "Gagal",
        "my-MM": "",
      },
      "In Process": {
        "th-TH": "ในกระบวนการ",
        "en-US": "In Process",
        "id-ID": "Dalam Proses",
        "my-MM": "",
      },
      Delivered: {
        "th-TH": "ส่งถึงแล้ว",
        "en-US": "Delivered",
        "id-ID": "Terkirim",
        "my-MM": "",
      },
      PASSWORD_MATCH_ERROR_TXT: {
        "th-TH": "รหัสผ่านสองรายการต้องตรงกัน",
        "en-US": "The two passwords must match",
        "id-ID": "Kedua Password Harus Sama",
        "my-MM": "",
      },
      CURRENT_PASSWORD_ERROR_TXT: {
        "th-TH": "ต้องระบุรหัสผ่านปัจจุบัน!",
        "en-US": "Current Password is Required!",
        "id-ID": "Password saat ini Diperlukan!",
        "my-MM": "",
      },
      NEW_PASSWORD_ERROR_TXT: {
        "th-TH": "ต้องระบุรหัสผ่านใหม่!",
        "en-US": "New Password is required!",
        "id-ID": "Password Baru Diperlukan!",
        "my-MM": "",
      },
      NEW_PASSWORD_MIN_CHAR_ERROR_TXT: {
        "th-TH": "รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร!",
        "en-US": "Passwords must have at least 8 characters!",
        "id-ID": "Passwords harus memiliki setidaknya 8 karakter!",
        "my-MM": "",
      },
      NEW_PASSWORD_UPPERCASE_ERROR_TXT: {
        "th-TH": "รหัสผ่านต้องมีตัวอักษรพิมพ์ใหญ่อย่างน้อย 1 ตัวอักษร!",
        "en-US": "Passwords must have at least one uppercase character!",
        "id-ID": "Passwords harus memiliki setidaknya 1 huruf besar!",
        "my-MM": "",
      },
      NEW_PASSWORD_LOWERCASE_ERROR_TXT: {
        "th-TH": "รหัสผ่านต้องมีตัวอักษรพิมพ์เล็กอย่างน้อย 1 ตัวอักษร!",
        "en-US": "Passwords must have at least one lowercase character!",
        "id-ID": "Passwords harus memiliki setidaknya 1 huruf kecil!",
        "my-MM": "",
      },
      NEW_PASSWORD_NUMBER_ERROR_TXT: {
        "th-TH": "รหัสผ่านต้องมีตัวเลขอย่างน้อย 1 ตัวอักษร!",
        "en-US": "Passwords must have at least one number!",
        "id-ID": "Passwords harus memiliki setidaknya 1 angka!",
        "my-MM": "",
      },
      NO_INTERNET_TTL_TXT: {
        "th-TH": "ไม่มีการเชื่อมต่ออินเทอร์เน็ต",
        "en-US": "No Internet Connection",
        "id-ID": "Tidak ada koneksi internet",
        "my-MM": "",
      },
      NO_INTERNET_CAPTION_TTL_TXT: {
        "th-TH": "โปรดตรวจสอบการเชื่อมต่อของคุณอีกครั้ง หรือเชื่อมต่อกับ Wi-Fi",
        "en-US": "Please check your connection again, or connect to Wi-Fi",
        "id-ID":
          "Silakan periksa koneksi internet Anda, atau hubungkan lagi ke Wi-Fi",
        "my-MM": "",
      },
      NO_INTERNET_BTN_HOME: {
        "th-TH": "กลับสู่หน้าหลัก",
        "en-US": "Back to home screen",
        "id-ID": "Kembali ke halaman home",
        "my-MM": "",
      },
      NO_INTERNET_BTN_LOGIN: {
        "th-TH": "กลับสู่หน้าจอเข้าสู่ระบบ",
        "en-US": "Back to login screen",
        "id-ID": "Kembali ke halaman login",
        "my-MM": "",
      },
      TAB_LIFE: {
        "th-TH": "โซล",
        "en-US": "Soul",
        "id-ID": "Jiwa",
        "my-MM": "",
      },
      TAB_HEALTH: {
        "th-TH": "สุขภาพ",
        "en-US": "Health",
        "id-ID": "Kesehatan",
        "my-MM": "",
      },
      PRODUCT_BY: {
        "th-TH": "สินค้าโดย",
        "en-US": "Product by",
        "id-ID": "Produk dari",
        "my-MM": "",
      },
      START_FROM: {
        "th-TH": "เริ่มต้นจาก",
        "en-US": "Start from",
        "id-ID": "Mulai dari",
        "my-MM": "",
      },
      PER_MONTH: {
        "th-TH": "/เดือน",
        "en-US": "/month",
        "id-ID": "/bulan",
        "my-MM": "",
      },
      PER_YEAR: {
        "th-TH": "/ปี",
        "en-US": "/year",
        "id-ID": "/tahun",
        "my-MM": "",
      },
      SEE_BROCHURE: {
        "th-TH": "ข้อมูลสรุปผลรวมสินค้าและบริการ",
        "en-US": "Summary Info Products & Services",
        "id-ID": "Ringkasan Informasi Produk dan Layanan",
        "my-MM": "",
      },
      BENEFIT: {
        "th-TH": "ประโยชน์",
        "en-US": "Benefit",
        "id-ID": "Manfaat",
        "my-MM": "",
      },
      ADDITIONAL_BENEFIT: {
        "th-TH": "ประโยชน์เพิ่มเติม",
        "en-US": "Additional Benefit",
        "id-ID": "Manfaat Tambahan",
        "my-MM": "",
      },
      INSURANCE_CONFIRM_TXT: {
        "th-TH": "ยืนยัน",
        "en-US": "Confirm",
        "id-ID": "Konfirmasi",
        "my-MM": "",
      },
      ERROR_REQUIRED_TXT: {
        "th-TH": "โปรดกรอกข้อมูลด้านบน",
        "en-US": "Please fill in the information above",
        "id-ID": "Mohon mengisi informasi di atas",
        "my-MM": "",
      },
      PROTECTION_FOR_TXT: {
        "th-TH": "การป้องกันสำหรับ",
        "en-US": "Protection for",
        "id-ID": "Perlindungan Untuk",
        "my-MM": "",
      },
      PROTECTION_FOR_LABEL_TXT: {
        "th-TH": "การเป็นสมาชิกประกันภัยสำหรับ",
        "en-US": "Insurance membership for",
        "id-ID": "Kepesertaan asuransi untuk",
        "my-MM": "",
      },
      SELF_TXT: {
        "th-TH": "ตนเอง",
        "en-US": "Self",
        "id-ID": "Diri Sendiri",
        "my-MM": "",
      },
      MOTHER_TXT: {
        "th-TH": "แม่",
        "en-US": "Mother",
        "id-ID": "Orang Tua Ibu",
        "my-MM": "",
      },
      FATHER_TXT: {
        "th-TH": "พ่อ",
        "en-US": "Father",
        "id-ID": "Orang Tua Bapak",
        "my-MM": "",
      },
      WIFE_TXT: {
        "th-TH": "ภรรยา",
        "en-US": "Wife",
        "id-ID": "Istri",
        "my-MM": "",
      },
      HUSBAND_TXT: {
        "th-TH": "สามี",
        "en-US": "Husband",
        "id-ID": "Suami",
        "my-MM": "",
      },
      BROTHER_TXT: {
        "th-TH": "พี่ชาย",
        "en-US": "Brother",
        "id-ID": "Saudara Laki-laki",
        "my-MM": "",
      },
      SISTER_TXT: {
        "th-TH": "พี่สาวน้องสาว",
        "en-US": "Sister",
        "id-ID": "Saudara Perempuan",
        "my-MM": "",
      },
      SON_TXT: {
        "th-TH": "ลูกชาย",
        "en-US": "Son",
        "id-ID": "Anak Laki-laki",
        "my-MM": "",
      },
      DAUGHTER_TXT: {
        "th-TH": "ลูกสาว",
        "en-US": "Daughter",
        "id-ID": "Anak Perempuan",
        "my-MM": "",
      },
      INSURANCE_PLAN_TXT: {
        "th-TH": "แผน",
        "en-US": "PLAN",
        "id-ID": "PLAN",
        "my-MM": "",
      },
      INSURANCE_PREMI_COST: {
        "th-TH": "ค่าธรรมเนียมพรีเมียม",
        "en-US": "Premi Cost",
        "id-ID": "Biaya Premi",
        "my-MM": "",
      },
      SELECT_PLAN: {
        "th-TH": "เลือกแผน",
        "en-US": "Choose Plan",
        "id-ID": "Pilih Plan",
        "my-MM": "",
      },
      INSURANCE_PURCHASE_TXT: {
        "th-TH": "ซื้อ",
        "en-US": "Purchase",
        "id-ID": "Beli",
        "my-MM": "",
      },
      ADD_CC_CAPTION_TXT: {
        "th-TH": "แน่ใจหรือว่าต้องการลบบัญชีนี้?",
        "en-US": "Are you sure you want to delete this account?",
        "id-ID": "Apakah Anda yakin ingin menghapus akun ini?",
        "my-MM": "ဒီအကောင့်ကိုသင်ဖျက်ချင်တာသေချာလား။",
      },
      ADD_CC_CAPTION_SUCCESS_TXT: {
        "th-TH": "บัญชีถูกลบ",
        "en-US": "Account Removed",
        "id-ID": "Akun Dihapus",
        "my-MM": "အကောင့်ကို ဖယ်ရှားခဲ့သည်။",
      },
      SA_REQUIRED_01_CAPTION_TXT: {
        "th-TH": "จำเป็นต้องระบุจำนวนที่ขอ!",
        "en-US": "Request Amount is Required!",
        "id-ID": "Permintaan Penarikan Diperlukan!",
        "my-MM": "",
      },
      SA_REQUIRED_02_CAPTION_TXT: {
        "th-TH": "จำนวนที่ขอต้องไม่น้อยกว่า ",
        "en-US": "Request Amount cannot be less than ",
        "id-ID": "Permintaan Penarikan tidak boleh kurang dari ",
        "my-MM": "",
      },
      SA_REQUIRED_03_CAPTION_TXT: {
        "th-TH": "จำนวนที่ขอต้องไม่มากกว่า",
        "en-US": "Request Amount cannot be greater than",
        "id-ID": "Permintaan Penarikan tidak boleh lebih besar dari",
        "my-MM": "",
      },
      SA_REQUIRED_04_CAPTION_TXT: {
        "th-TH": "จำนวนที่ขอต้องเป็นเลขคู่ของ 50,000",
        "en-US": "Request Amount must be multiple by 50,000",
        "id-ID": "Permintaan Penarikan harus kelipatan Rp50,000",
        "my-MM": "",
      },
      SA_REQUIRED_ACC_CAPTION_TXT: {
        "th-TH": "ต้องได้รับบัญชี!",
        "en-US": "Receiving Account is Required !",
        "id-ID": "Akun Penerima Diperlukan!",
        "my-MM": "အကောင့်လက်ခံရရှိရန်လိုအပ်သည်!",
      },
      SA_FEE_ERROR_CAPTION_TXT: {
        "th-TH": "ไม่สามารถเรียกข้อมูลค่าธรรมเนียมได้ โปรดลองอีกครั้ง",
        "en-US": "Cannot retrieve info fee, please try again",
        "id-ID": "Tidak dapat mengambil info biaya, silakan coba lagi",
        "my-MM": "အချက်အလက်အခကြေးငွေကို ရယူ၍မရပါ၊ ကျေးဇူးပြု၍ ထပ်စမ်းကြည့်ပါ။",
      },
      INSURED_TXT: {
        "th-TH": "ผู้ประกันตน",
        "en-US": "Insured",
        "id-ID": "tertanggung",
        "my-MM": "",
      },
      INSURANCE_PERSONAL_DATA_TXT: {
        "th-TH": "ข้อมูลส่วนตัว",
        "en-US": "Personal Data",
        "id-ID": "Data Pribadi",
        "my-MM": "",
      },
      INSURANCE_POLICYHOLDER_DATA_TXT: {
        "th-TH": "ข้อมูลผู้ถือกรมธรรม์",
        "en-US": "Policyholder Data",
        "id-ID": "Data Pemegang Polis",
        "my-MM": "",
      },
      INSURANCE_INSURED_DATA_TXT: {
        "th-TH": "ข้อมูลผู้ประกันตน",
        "en-US": "Insured Data",
        "id-ID": "Data Tertanggung",
        "my-MM": "",
      },
      INSURANCE_TITLE_TXT: {
        "th-TH": "ผู้ถือกรมธรรม์",
        "en-US": "Policy Holder",
        "id-ID": "Pemegang Polis",
        "my-MM": "",
      },
      FULL_NAME_INSURED_TXT: {
        "th-TH": "ชื่อเต็มของผู้เอาประกัน",
        "en-US": "Insured Full Name",
        "id-ID": "Nama Lengkap Tertanggung",
        "my-MM": "",
      },
      FULL_NAME__INSURED_ERROR_TTL_TX: {
        "th-TH": "ต้องระบุชื่อเต็มของผู้เอาประกัน!",
        "en-US": "Full Name Insured is Required!",
        "id-ID": "Nama Lengkap Tertanggung Diperlukan!",
        "my-MM": "",
      },
      ERROR_PAGE_TTL_TXT_01: {
        "th-TH": "อ๊ะ ขอโทษ",
        "en-US": "Oops Sorry",
        "id-ID": "Oops Maaf",
        "my-MM": "",
      },
      ERROR_PAGE_TTL_TXT_02: {
        "th-TH": "มีบางอย่างผิดพลาดไป...",
        "en-US": "Something has gone wrong...",
        "id-ID": "Ada yang salah...",
        "my-MM": "",
      },
      INSURANCE_PAYMENT_TXT: {
        "th-TH": "การชำระเงิน",
        "en-US": "Payment",
        "id-ID": "Pembayaran",
        "my-MM": "",
      },
      TAB_DETAIL_01_TLL_TX: {
        "th-TH": "รายละเอียดสินค้า",
        "en-US": "Product Details",
        "id-ID": "Detil Produk",
        "my-MM": "",
      },
      TAB_DETAIL_02_TLL_TX: {
        "th-TH": "คำถามที่พบบ่อย",
        "en-US": "FAQ",
        "id-ID": "FAQ",
        "my-MM": "",
      },
      REFRESH_TEXT: {
        "th-TH": "รีเฟรช",
        "en-US": "Refresh",
        "id-ID": "Refresh",
        "my-MM": "",
      },
      SEE_BROCHURE_TTL_TX: {
        "th-TH": "สรุปผลิตภัณฑ์และบริการ",
        "en-US": "Summary Product & Services",
        "id-ID": "Ringkasan Produk & Layanan",
        "my-MM": "",
      },
      SUM_ASSURED: {
        "th-TH": "จำนวนเงินเอาประกันภัย",
        "en-US": "Sum Assured",
        "id-ID": "Uang Pertanggungan",
        "my-MM": "",
      },
      MILLION: {
        "th-TH": "ล้าน",
        "en-US": "Million",
        "id-ID": "Juta",
        "my-MM": "",
      },
      FULL_NAME: {
        "th-TH": "ชื่อเต็ม",
        "en-US": "Full Name",
        "id-ID": "Nama Lengkap",
        "my-MM": "နာမည်အပြည့်အစုံ",
      },
      EMAIL: {
        "th-TH": "อีเมล",
        "en-US": "Email",
        "id-ID": "Email",
        "my-MM": "",
      },
      SA_REGULAR_TOPUP_TXT: {
        "th-TH": "เติมเต็ม",
        "en-US": "Regular Top Up",
        "id-ID": "Regular Top Up",
        "my-MM": "ပုံမှန်ထိပ်တန်းတက်",
      },
      SA_DISBURSMENT_DATE_TXT: {
        "th-TH": "วันที่เบิกจ่าย",
        "en-US": "Disbursement Date",
        "id-ID": "Disbursement Date",
        "my-MM": "ထုတ်ပေးသည့်ရက်စွဲ",
      },
      SA_REGULAR_ATTENTION_TXT: {
        "th-TH":
          "วันที่ชำระเงินครั้งถัดไปของคุณคือ [DISBURSEMENT_DATE] ของทุกเดือน",
        "en-US":
          "Your next disbursement date is [DISBURSEMENT_DATE] of every month",
        "id-ID":
          "Tanggal pencairan Anda berikutnya adalah tanggal [DISBURSEMENT_DATE] setiap bulan",
        "my-MM": "",
      },
      SA_TERMS_01_TXT: {
        "th-TH": "ขอบคุณที่ใช้ Salary Advance service โดย smartek.",
        "en-US": "Thank you for using Salary Advance service by smartek.",
        "id-ID":
          "Terima kasih telah menggunakan Salary Advance service dari Smartek.",
        "my-MM": "",
      },
      SA_TERMS_02_TXT: {
        "th-TH":
          "คุณได้เลือกผลิตภัณฑ์ Regular top-up Salary Advance ซึ่งจะถูกขออัตโนมัติทุกเดือนในวันที่",
        "en-US":
          "You have chosen Regular top-up Salary Adavance product which will be automaticly requested every month on the",
        "id-ID":
          "Anda telah memilih produk Regular top-up Salary Adavance yang mana akan otomatis dimintai setiap bulan di",
        "my-MM": "",
      },
      SA_TERMS_03_TXT: {
        "th-TH": "รายละเอียดผลิตภัณฑ์นี้สามารถอ่านได้ในหน้าประวัติการทำธุรกรรม",
        "en-US":
          "This product detail can be read in the transaction history page",
        "id-ID": "Detail produk ini dapat dibaca di halaman riwayat transaksi",
        "my-MM": "",
      },
      SA_TERMS_04_TXT: {
        "th-TH":
          "คุณต้องการดำเนินการของของคำขอสำหรับ Salary Advance regular top up?",
        "en-US":
          "Do you agree to continue the request for Salary Advance regular top up?",
        "id-ID":
          "Apakah anda setuju untuk melanjutkan permintaan untuk Salary Advance regular top up?",
        "my-MM": "",
      },
      SA_TERMS_TITLE_TXT: {
        "th-TH": "ข้อตกลงข้อกำหนดและเงื่อนไข",
        "en-US": "Terms and Conditions Agreement",
        "id-ID": "Terms and Conditions Agreement",
        "my-MM": "စည်းကမ်းချက်များနှင့်အခြေအနေများသဘောတူညီချက်",
      },
      SA_REGULAR_CHECK_TERMS_TXT: {
        "th-TH":
          "ฉันยินดีที่จะอนุญาตให้ระบบ Smartek ที่จะขอเป็นประจำ Salary Advance ในนามของฉัน",
        "en-US":
          "I agree to allow Smartek system to regularly request Salary Advance on my behalf",
        "id-ID":
          "Saya setuju untuk mengizinkan sistem Smartek untuk meminta secara teratur Salary Advance atas nama saya",
        "my-MM": "",
      },
      SA_REGULAR_YES_TXT: {
        "th-TH": "ใช่",
        "en-US": "Yes",
        "id-ID": "Ya",
        "my-MM": "ဟုတ်ကဲ့",
      },
      SA_REGULAR_NO_TXT: {
        "th-TH": "ไม่",
        "en-US": "No",
        "id-ID": "Tidak",
        "my-MM": "မဟုတ်",
      },
      REG_TOPUP_SA_TXT: {
        "th-TH": "Salary Advance",
        "en-US": "Salary Advance",
        "id-ID": "Salary Advance",
        "my-MM": "",
      },
      REG_TOPUP_INSURANCE_TXT: {
        "th-TH": "ประกันภัย",
        "en-US": "Insurance",
        "id-ID": "Insurance",
        "my-MM": "",
      },
      REG_TOPUP_INVESTMENT_TXT: {
        "th-TH": "การลงทุน",
        "en-US": "Investment",
        "id-ID": "Investment",
        "my-MM": "",
      },
      REG_TOPUP_BILL_TXT: {
        "th-TH": "บิล",
        "en-US": "Bill",
        "id-ID": "Bill",
        "my-MM": "",
      },
      REG_TOPUP_TOPUP_TXT: {
        "th-TH": "เติมเงิน",
        "en-US": "Top Up",
        "id-ID": "Top Up",
        "my-MM": "",
      },
      VERSION_TXT: {
        "th-TH": "เวอร์ชันของแอป 1.0.6",
        "en-US": "App Version 1.0.6",
        "id-ID": "App Versi 1.0.6",
        "my-MM": "",
      },
      ADD_ACCOUNT_SUCCESS_TXT: {
        "th-TH": "เพิ่มบัญชีแล้ว",
        "en-US": "Account added",
        "id-ID": "Akun ditambahkan",
        "my-MM": "အကောင့်ထည့်ထားသည်။",
      },
      // // // // // // // // // // //
      HOME_NB_1: {
        "th-TH": "โฮม",
        "en-US": "Home",
        "id-ID": "Beranda",
        "my-MM": "ပင်မစာမျက်နှာ",
      },
      HOME_NB_2: {
        "th-TH": "ฟีเจอร์",
        "en-US": "Feature",
        "id-ID": "Fitur",
        "my-MM": "လက္ခဏာ",
      },
      HOME_NB_3: {
        "th-TH": "บทช่วยสอน",
        "en-US": "Tutorials",
        "id-ID": "Tutorial",
        "my-MM": "အစမ်းအသုံးပြုပုံများ",
      },
      HOME_NB_4: {
        "th-TH": "ติดต่อ",
        "en-US": "Contact",
        "id-ID": "Kontak",
        "my-MM": "ဆက်သွယ်ရန်",
      },
      HOME_MT_1: {
        "th-TH": "วันจ่ายเงินเดือนของฉัน ฉันสามารถเลือกได้ด้วยตัวเอง",
        "en-US": "My payday I can decide by My Self",
        "id-ID": "My payday I can decide by My Self",
        "my-MM": "လစာထုတ်မည့်နေ့အားကိုယ်တိုင်ရွေးချယ်နိုင်မည်	",
      },
      HOME_MT_2: {
        "th-TH":
          "พัฒนากิจกรรมทางการเงินของคุณด้วย Smartek สำหรับการแก้ไขปัญหาทางการเงินด้วยนวัตกรรมใหม่ที่สามารถเลือกวันรับเงินเดือนได้อย่างสะดวกสบายและว่องไว",
        "en-US":
          "Improve your financial activities with Smartek. The new innovative financial solutions allow you to decide your own payday in the fastest and most convenient way possible.",
        "id-ID":
          "Improve your financial activities with Smartek. The new innovative financial solutions allow you to decide your own payday in the fastest and most convenient way possible.",
        "my-MM":
          "Smartek ဖြင့်သင်၏ဘဏ္ဍာရေးဆိုင်ရာလုပ်ဆောင်မှုများတိုးတက်ပါစေ။ ဆန်းသစ်သောငွေကြေးဆိုင်ရာဖြေရှင်းချက်များဖြင့်သင့်၏ကိုယ်ပိုင်လစာအတွက် သင့်အား အမြန်ဆုံးနှင့်အဆင်ပြေဆုံးနည်းလမ်းဖြင့်ဆုံးကူညီဖြေရှင်းပေးမည်။	",
      },
      HOME_GET_STARTED: {
        "th-TH": "เริ่มได้เลย",
        "en-US": "Get Started",
        "id-ID": "Memulai",
        "my-MM": "စတင်မည်",
      },
      HOME_LEARN_MORE: {
        "th-TH": "เพิ่มเติม",
        "en-US": "Learn More",
        "id-ID": "Pelajari Lebih Lanjut",
        "my-MM": "ဆက်လက်ကြည့်ရန်",
      },
      HOME_REGISTERED_USERS: {
        "th-TH": "ผู้สมัคร",
        "en-US": "Registered Users",
        "id-ID": "Pengguna Terdaftar",
        "my-MM": "မှတ်ပုံတင်ထားသောအသုံးပြုသူများ",
      },
      HOME_ACTIVE_USERS: {
        "th-TH": "ผู้ใช้งาน",
        "en-US": "Active Users",
        "id-ID": "Pengguna Aktif",
        "my-MM": "လက်ရှိအသုံးပြုသူများ",
      },
      HOME_NUMBER_OF: {
        "th-TH": "ยอดจำนวนการเบิก",
        "en-US": "Number of total drawdowns",
        "id-ID": "Jumlah total penarikan",
        "my-MM": "နှုတ်ထွက်မှုစုစုပေါင်းအရေအတွက်",
      },
      HOME_VALUE_OF: {
        "th-TH": "ปริมาณที่ให้ยืมรายเดือน",
        "en-US": "Value of monthly loans",
        "id-ID": "Nilai pinjaman bulanan",
        "my-MM": "လစဉ်ချေးငွေတန်ဖိုး",
      },
      HOME_AVERAGE_LOAN: {
        "th-TH": "ระยะเวลาเฉลี่ยนในการยืม",
        "en-US": "Average loan duration",
        "id-ID": "Durasi pinjaman rata-rata",
        "my-MM": "ပျမ်းမျှချေးငွေကာလ",
      },
      HOME_US4MN: {
        "th-TH": "US$ 4 Mn",
        "en-US": "US$ 4 Mn",
        "id-ID": "US$ 4 Mn",
        "my-MM": "BR",
      },
      HOME_15_DAYS: {
        "th-TH": "15 วัน",
        "en-US": "15 Days",
        "id-ID": "15 Hari",
        "my-MM": "15 Days",
      },
      HOME_VAULT_PROTECTION: {
        "th-TH": "เสมือนป้องกันด้วยห้องนิรภัย",
        "en-US": "Vault protection",
        "id-ID": "Vault protection",
        "my-MM": "ငွေကြေးကာကွယ်ရေး",
      },
      HOME_CALCULATE_PRECISE: {
        "th-TH":
          "คำนวณคะแนนเครดิต AI อย่างแม่นยำโดยอิงจากข้อมูลในอดีตด้วยการระบุประวัติของผู้ใช้ สภาพแวดล้อมของตลาด และโอกาสล้มละลาย เทคโนโลยี Block Chain ใช้สำหรับการตรวจจับและป้องกันการฉ้อโกง",
        "en-US":
          "Calculate precise AI credit score based on historical data on between user’s attribution, market environment and bankruptcy probability. Block Chain technology is applied for fraud detection and prevention.",
        "id-ID":
          "Calculate precise AI credit score based on historical data on between user’s attribution, market environment and bankruptcy probability. Block Chain technology is applied for fraud detection and prevention.",
        "my-MM":
          "အသုံးပြုသူထည့်သွင်းတွက်ချက်မှု၊ စျေးကွက်ပတ်ဝန်းကျင်နှင့် ဒေဝါလီခံနိုင်ခြေများကြားမှ သမိုင်းအချက်အလက်ပေါ်အခြေခံ၍ တိကျသော AI ခရက်ဒစ်ရမှတ်ကို တွက်ချက်ပါ။ Block Chain နည်းပညာကို လိမ်လည်မှုရှာဖွေခြင်းနှင့် ကာကွယ်ခြင်းအတွက် အသုံးချသည်။	",
      },
      HOME_AI_INSURANCE: {
        "th-TH": "ประกัน AI",
        "en-US": "AI Insurance",
        "id-ID": "AI Insurance",
        "my-MM": "အေအိုင်အာမခံ",
      },
      HOME_CALCULATING_PRECISE: {
        "th-TH":
          "การคำนวณด้วย AI premium based ที่แม่นยำโดยอิงจากข้อมูลในอดีตระหว่างการระบุแหล่งที่มาของผู้ใช้ สภาพแวดล้อมของตลาด และความน่าจะเป็นของเหตุการณ์ในชีวิต เทคโนโลยี Block Chain ใช้สำหรับการตรวจจับและป้องกันการฉ้อโกง",
        "en-US":
          "Calculating precise AI option premium based on historical data on between user’s attribution, market environment and life event probability, Block Chain technology is applied for fraud detection and prevention.",
        "id-ID":
          "Calculating precise AI option premium based on historical data on between user’s attribution, market environment and life event probability, Block Chain technology is applied for fraud detection and prevention.",
        "my-MM":
          "အသုံးပြုသူထည့်သွင်းတွက်ချက်မှု၊ စျေးကွက်ပတ်ဝန်းကျင်နှင့် ဘဝဖြစ်ရပ်များဖြစ်နိုင်ခြေများကြား သမိုင်းအချက်အလက်ပေါ်အခြေခံ၍ တိကျသော AI ရွေးချယ်မှုပရီမီယံကို တွက်ချက်ခြင်း Block Chain နည်းပညာကို လိမ်လည်မှုရှာဖွေခြင်းနှင့် တားဆီးခြင်းအတွက် အသုံးပြုပါသည်။	",
      },
      HOME_TARGETED_SEGMENT: {
        "th-TH": "เล็งโฆษณาที่กลุ่มลูกค้าเป้ามหาย",
        "en-US": "Targeted Segment Advertisement",
        "id-ID": "Targeted Segment Advertisement",
        "my-MM": "ပစ်မှတ်ထားသောကြော်ငြာအပိုင်းများ	",
      },
      HOME_PREDICT_INFLUENTIAL: {
        "th-TH":
          "ประเมิณหากลุ่มผู้ที่มีอิทธิพลและจัดการยิงโฆษณาที่ปรับแต่งมาอย่างเหมาะด้วยความแม่นยำ",
        "en-US":
          "Predict influential segments and provide them precise customized and personalized ads.",
        "id-ID":
          "Predict influential segments and provide them precise customized and personalized ads.",
        "my-MM":
          "ထိရောက်သောအပိုင်းများကို ခန့်မှန်းပြီး တိကျသော စိတ်ကြိုက်နှင့် စိတ်ကြိုက်ပြုလုပ်ထားသော ကြော်ငြာများကို လုပ်ဆောင်ပါ။	",
      },
      HOME_STRATEGIC: {
        "th-TH": "ที่ปรึกษา HR เชิงกลยุทธ์",
        "en-US": "Strategic HR Consulting",
        "id-ID": "Strategic HR Consulting",
        "my-MM": "ဗျူဟာမြောက်သောHR အတိုင်ပင်ခံ",
      },
      HOME_ENHANCE: {
        "th-TH":
          "เพิ่มอัตตราการรักษาพนักงานโดยการจ้างคนที่มีความภักดีสูงและปล่อยคนที่มีความภักดีน้อย หากลุ่มผู้สมัครที่เหมาะสมกับความต้องการของบริษัท เช่นการแนะนำผู้สมัครที่มีความสามารถในเอเชียที่มีความสามารถให้กับบริษัทญี่ปุ่นเป็นต้น",
        "en-US":
          "Enhance retention rate by hiring people with higher loyalty and eliminate ones with less loyalty, predict segment of candidates that match or mismatch to company’s job requirement as example introduction of talented Asian candidates to Japanese companies vice versa.",
        "id-ID":
          "Enhance retention rate by hiring people with higher loyalty and eliminate ones with less loyalty, predict segment of candidates that match or mismatch to company’s job requirement as example introduction of talented Asian candidates to Japanese companies vice versa.",
        "my-MM":
          "မြင့်မားသောသစ္စာရှိသူများကို ငှားရမ်းပြီး သစ္စာစောင့်သိမှုနည်းသူများကို ဖယ်ရှားခြင်းဖြင့် ထိန်းသိမ်းမှုနှုန်းကို မြှင့်တင်ပါ၊ ကုမ္ပဏီ၏အလုပ်လိုအပ်ချက်နှင့် ကိုက်ညီသော သို့မဟုတ် မကိုက်ညီသော ကိုယ်စားလှယ်လောင်းများ၏ အပိုင်းကို ဂျပန်ကုမ္ပဏီများသို့ အရည်အချင်းရှိသော အာရှကိုယ်စားလှယ်လောင်းများကို အပြန်အလှန်မိတ်ဆက်ခြင်း ဥပမာအနေဖြင့် ထားရှိမှုနှုန်းကို မြှင့်တင်ပါ။	",
      },
      HOME_GET_STARTED_IN: {
        "th-TH": "เริ่มเลยทันที",
        "en-US": "Get started in minutes",
        "id-ID": "Get started in minutes",
        "my-MM": "မိနစ်ပိုင်းအတွင်းစတင်လိုက်ပါ။",
      },
      HOME_EASY_WAY: {
        "th-TH": "วิธีการง่ายๆ ในการใช้แอพ Smartek",
        "en-US": "Easy way to use Smartek app",
        "id-ID": "Easy way to use Smartek app",
        "my-MM": "လွယ်ကူသောနည်းလမ်းများဖြင့်Smartek Appကိုအသုံးပြုလိုက်ပါ။	",
      },
      HOME_SIGN_IN: {
        "th-TH": "เข้าสู่ระบบ",
        "en-US": "Sign In",
        "id-ID": "Masuk",
        "my-MM": "အကောင့်မှ ဝင်မည်",
      },
      HOME_OPEN_Smartek: {
        "th-TH":
          "เปิดเว็บไซต์ Smartek บนมือถือหรือเดสก์ท็อป แล้วลงชื่อเข้าใช้ด้วยบัญชีของคุณ",
        "en-US":
          "Open Smartek website on mobile or desktop, and Sign-in with your account",
        "id-ID":
          "Open Smartek website on mobile or desktop, and Sign-in with your account",
        "my-MM":
          "Smartek ဝဘ်ဆိုက်ကို မိုဘိုင်း သို့မဟုတ် ကွန်ပျူတာမှတဆင့် သင့်၏အကောင့်ကိုဝင်ပါ။	",
      },
      HOME_REQUEST_AMOUNT: {
        "th-TH": "เลือกจำนวนที่ขอ",
        "en-US": "Request Amount",
        "id-ID": "Request Amount",
        "my-MM": "၂။ တောင်းဆိုလိုသည့်ပမာဏ",
      },
      HOME_SELECT_YOUR: {
        "th-TH": "เลือกจำนวนที่ขอจากนั้นเลือกบัญชีรับแล้วกดส่งคำขอ",
        "en-US":
          "Select your request amount then select your receiving account and tap request button",
        "id-ID":
          "Select your request amount then select your receiving account and tap request button",
        "my-MM":
          "သင့်၏တောင်းဆိုမှုပမာဏကို ရွေးချယ်ပြီးနောက် သင့်လက်ခံရရှိသည့်အကောင့်ကို ရွေးချယ်ပြီး တောင်းဆိုချက်ခလုတ်ကို နှိပ်ပါ။	",
      },
      HOME_CONFIRMATION: {
        "th-TH": "ยืนยัน",
        "en-US": "Confirmation",
        "id-ID": "Konfirmasi",
        "my-MM": "၃။ အတည်ပြုချက်",
      },
      HOME_CONFIRM_YOUR_REQUESTED: {
        "th-TH": "ยืนยันจำนวนที่ขอและกดส่ง",
        "en-US": "Confirm your requested amount and submit",
        "id-ID": "Confirm your requested amount and submit",
        "my-MM": "သင့်တောင်းဆိုထားသည့် ပမာဏကို အတည်ပြုပြီး ပေးပို့ပါ။	",
      },
      HOME_DONE: {
        "th-TH": "เสร็จสิ้น",
        "en-US": "Done",
        "id-ID": "Selesai",
        "my-MM": "၄။ လုပ်ဆောင်မည်။",
      },
      HOME_CONGRATULATIONS: {
        "th-TH": "ยินดีด้วยคำขอของคุณถูกส่งเรียบร้อยแล้ว",
        "en-US":
          "Congratulations, your request has been successfully submitted",
        "id-ID":
          "Congratulations, your request has been successfully submitted",
        "my-MM":
          "ဂုဏ်ယူပါသည်။ သင်၏တောင်းဆိုချက်ကို အောင်မြင်စွာ တင်သွင်းပြီးဖြစ်သည်။	",
      },
      HOME_PRODUCT: {
        "th-TH": "สินค้า",
        "en-US": "Product",
        "id-ID": "Product",
        "my-MM": "ထုတ်ကုန်ပစ္စည်း",
      },
      HOME_DOCUMENTATIONS: {
        "th-TH": "เอกสาร",
        "en-US": "Documentations",
        "id-ID": "Documentations",
        "my-MM": "စာတမ်းပြုစုခြင်း",
      },
      HOME_INTEGRATIONS: {
        "th-TH": "การผสาน",
        "en-US": "Integrations",
        "id-ID": "Integrations",
        "my-MM": "ပေါင်းစည်းခြင်း",
      },
      HOME_PRICING: {
        "th-TH": "ราคา",
        "en-US": "Pricing",
        "id-ID": "Pricing",
        "my-MM": "ဈေးနှုန်း",
      },
      HOME_API: {
        "th-TH": "API",
        "en-US": "API",
        "id-ID": "API",
        "my-MM": "API",
      },
      HOME_COMPANY: {
        "th-TH": "บริษัท",
        "en-US": "Company",
        "id-ID": "Company",
        "my-MM": "ကုမ္ပဏီ",
      },
      HOME_ABOUT_US: {
        "th-TH": "เกี่ยวกับเรา",
        "en-US": "About Us",
        "id-ID": "About Us",
        "my-MM": "ကျွန်ုပ်တို့အကြောင်း",
      },
      HOME_MEET_OUR_TEAM: {
        "th-TH": "ทีมของเรา",
        "en-US": "Meet our team",
        "id-ID": "Meet our team",
        "my-MM": "အဖွဲ့အစည်း",
      },
      HOME_CAREERS: {
        "th-TH": "เข้าร่วมกับเรา",
        "en-US": "Careers",
        "id-ID": "Careers",
        "my-MM": "အသက်မွေးဝမ်းကြောင်း",
      },
      HOME_SUPPORT: {
        "th-TH": "สนับสนุน",
        "en-US": "Support",
        "id-ID": "Support",
        "my-MM": "အထောက်အပံ့",
      },
      HOME_KNOWLEDGE: {
        "th-TH": "ฐานความรู้",
        "en-US": "Knowledge base",
        "id-ID": "Knowledge base",
        "my-MM": "အခြေခံအသိပညာ",
      },
      HOME_TUTORIALS: {
        "th-TH": "Tutorial",
        "en-US": "Tutorials",
        "id-ID": "Tutorial",
        "my-MM": "အစမ်းအသုံးပြုပုံများ",
      },
      HOME_DOCUMENTS: {
        "th-TH": "เอกสาร",
        "en-US": "Documents",
        "id-ID": "Documents",
        "my-MM": "စာရွက်စာတမ်းများ",
      },
      HOME_GUIDELINES: {
        "th-TH": "แนวทาง",
        "en-US": "Guidelines",
        "id-ID": "Guidelines",
        "my-MM": "အသုံးပြုပုံနည်းလမ်းများ",
      },
      HOME_CONTACT_US: {
        "th-TH": "ติดต่อเรา",
        "en-US": "Contact Us",
        "id-ID": "Contact Us",
        "my-MM": "ဆက်သွယ်ရန်",
      },
      HOME_EMAIL_CONTACT_US: {
        "th-TH": "corp@smartekinter.com",
        "en-US": "corp@smartekinter.com",
        "id-ID": "corp@smartekinter.com",
        "my-MM": "corp@smartekinter.com",
      },
      HOME_STAYUPTODATE: {
        "th-TH": "ติดตามสถานการณ์",
        "en-US": "Stay Up to date",
        "id-ID": "Stay Up to date",
        "my-MM": "ခေတ်မီအောင်နေထိုင်ပါ",
      },
      HOME_SUBSCRIBE: {
        "th-TH": "เข้าร่วมรับ newsletter ทุกๆสัปดาห์",
        "en-US": "Subscribe to every weekly read our newsletter.",
        "id-ID": "Subscribe to every weekly read our newsletter.",
        "my-MM": "ဤသတင်းကိုအပတ်စဉ်ဖတ်ရှုရန်စာရင်းသွင်းပါ	",
      },
      HOME_ENTER_EMAIL: {
        "th-TH": "ใส่อีเมล",
        "en-US": "Enter Email",
        "id-ID": "Masukkan Email",
        "my-MM": "အီးမေးလ်ရိုက်ရန်",
      },
      DB_PLEASE_ADD: {
        "th-TH": "กรุณาเพิ่มบัญชีธนาคารของคุณ",
        "en-US": "Please activate your account",
        "id-ID": "Silakan aktifkan akun Anda",
        "my-MM": "သင့်ဘဏ်အကောင့်ကို ထည့်ပါ။",
      },
      DB_BEFORE_YOU: {
        "th-TH": "ก่อนที่คุณจะสามารถดำเนินการต่อ ...",
        "en-US": "or add new account ...",
        "id-ID": "atau tambahkan akun baru...",
        "my-MM": "ဆက်မလုပ်နိုင်ခင်...",
      },
      DB_BANK: {
        "th-TH": "ธนาคาร",
        "en-US": "Bank",
        "id-ID": "Bank",
        "my-MM": "ဘဏ်",
      },
      DB_E_MONEY: {
        "th-TH": "เงินอิเล็กทรอนิกส์",
        "en-US": "e-Money",
        "id-ID": "e-Money",
        "my-MM": "e-ပိုက်ဆံ",
      },
      DB_NO_TRANSACTION: {
        "th-TH": "ไม่พบธุรกรรม",
        "en-US": "No transaction found",
        "id-ID": "Tidak ada transaksi yang ditemukan",
        "my-MM": "အရောင်းအဝယ်မတွေ့ပါ။",
      },
      DB_SEE_MORE: {
        "th-TH": "ดูเพิ่มเติม",
        "en-US": "See More",
        "id-ID": "Lihat Lebih Banyak",
        "my-MM": "ထပ်ပြပါ။",
      },
      NOT_KYC: {
        "th-TH": "อันดา เบลุม มะละกากัน KYC",
        "en-US": "You haven't done KYC yet",
        "id-ID": "Anda belum melakukan KYC",
        "my-MM": "KYC မပြီးသေးဘူး။",
      },
      NOT_KYC_ACT: {
        "th-TH": "KYC ตอนนี้",
        "en-US": "KYC Now",
        "id-ID": "KYC Sekarang",
        "my-MM": "kyc အခုပဲ။",
      },
      BTN_SHOP_TXT: {
        "th-TH": "ช้อปปิ้งที่ร้านขายของ",
        "en-US": "Shopping at the stall",
        "id-ID": "Belanja di warung",
        "my-MM": "ဆိုင်မှာ ဈေးဝယ်ပါ။",
      },
      CHOOSE_OPT: {
        "th-TH": "เลือกตัวเลือก",
        "en-US": "Choose Options",
        "id-ID": "Pilih Opsi",
        "my-MM": "ရွေးချယ်စရာများကို ရွေးပါ။",
      },
      // HEADER_SHOP_AMOUNT_TXT: {
      //   "th-TH": "จำนวนเงินยืม Salary Advance ของคุณ",
      //   "en-US": "Your Salary Advance amount",
      //   "id-ID": "Jumlah Salary Andvance Anda",
      //   "my-MM": "သင့် Salary Advance လွှဲပေးချေမှု ပမာဏ",
      // },
      HEADER_SHOP_AMOUNT_TXT: {
        "th-TH": "วงเงินที่ใช้ช้อปปิ้งของคุณ",
        "en-US": "Your Shopping Plafond",
        "id-ID": "Plafon Belanja Anda",
        "my-MM": "သင်၏စျေးဝယ်ကန့်သတ်ချက်",
      },
      MYANMAR_TXT: {
        "th-TH": "พม่า",
        "en-US": "Myanmar",
        "id-ID": "Myanmar",
        "my-MM": "မြန်မာ",
      },
      THAILAND_TXT: {
        "th-TH": "ประเทศไทย",
        "en-US": "Thailand",
        "id-ID": "Thailand",
        "my-MM": "ထိုင်း",
      },
      CAMBODIA_TXT: {
        "th-TH": "กัมพูชา",
        "en-US": "Cambodia",
        "id-ID": "Kamboja",
        "my-MM": "ကမ္ဘောဒီးယား",
      },
      TRANSACTION_SA_TXT: {
        "th-TH": "Salary Advance ธุรกรรม",
        "en-US": "Salary Advance Transaction ",
        "id-ID": "Transaksi Salary Advance",
        "my-MM": "Salary Advance အရောင်းအဝယ်များ",
      },
      TRANSACTION_SHOPPING_TXT: {
        "th-TH": "ธุรกรรมการช้อปปิ้ง",
        "en-US": "Shopping Transactions",
        "id-ID": "Transaksi Belanja",
        "my-MM": "စျေးဝယ်ငွေလွှဲမှုများ",
      },
      DETAIL_SHOP_PLAFOND: {
        "th-TH": "วงเงินการช้อปปิ้งของคุณคือวงเงินสูงสุดที่สามารถใช้เพื่อซื้อสินค้าบนแอปพลิเคชัน Pikkat ที่ผู้ให้บริการให้บริการ",
        "en-US": "Your shopping plafond is the maximum limit of funds that can be used to shop on the Pikkat application provided by the service provider",
        "id-ID": "Plafon belanja anda adalah batas maksimal dari dana yang bisa digunakan untuk berbelanja pada aplikasi Pikkat yang disediakan oleh penyedia layanan",
        "my-MM": "သင်၏ စျေးဝယ်ကန့်သတ်ချက်သည် ဝန်ဆောင်မှုပေးသူမှ ပံ့ပိုးပေးသည့် Pikkat အက်ပ်လီကေးရှင်းတွင် စျေးဝယ်ရန် အသုံးပြုနိုင်သည့် ငွေပမာဏ၏ အများဆုံးကန့်သတ်ချက်ဖြစ်သည်။",
      },
      KEY: {
        "th-TH": "TH",
        "en-US": "EN",
        "id-ID": "ID",
        "my-MM": "BR",
      },
    },
  },
  reducers: {
    setTranslations: (state, action) => {
      state.translations = action.payload;
    },
    setLanguage: (state, action) => {
      state.active = action.payload;
    },
  },
});

export const { setTranslations, setLanguage } = langaugeSlice.actions;

langaugeSlice.selector = {
  all: (state) => state[langaugeSlice.name],
  active: (state) => state[langaugeSlice.name].active,
  languages: (state) => state[langaugeSlice.name].languages,
};

export default langaugeSlice;

import React, { useEffect, useState } from "react";
import ModalBankAccount from "./modalBankAccount";
import ButtonMui from "@mui/material/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { RadioButtonUnchecked } from "@mui/icons-material";
import Card from "../../../../assets/img/card_account.png";
import ThaiFlag from "../../../../assets/svg/flags/thailand.svg";
import { useDispatch, useSelector } from "react-redux";
import serviceSlice, { setActiveAccount } from "../../../../store/serviceSlice";
import userSlice from "../../../../store/userSlice";
import accountNumberHider from "../../../../helpers/account";
import {
  setRegularSaRequest,
  setSaRequest,
} from "../../../../store/inputSlice";
import { useNavigate } from "react-router-dom";

export default function AccountManagement({
  modalBankAccount,
  setModalBankAccount,
}) {
  const navigate = useNavigate();
  const { bank, emoney } = useSelector(serviceSlice.selector.bank);
  const { bank_account, company_name } = useSelector(
    userSlice.selector.userData
  );

  const [banks, setBanks] = useState([]);
  const [emoneys, setEmoneys] = useState([]);
  const { flag_information } = useSelector(userSlice.selector.userData);

  useEffect(() => {
    if (bank_account.length) {
      let tempBank = [];
      let tempEmoney = [];
      for (let ba = 0; ba < bank_account.length; ba++) {
        for (let b = 0; b < bank.length; b++) {
          if (bank[b].bank_name === bank_account[ba].bank_name) {
            tempBank.push(bank_account[ba]);
          }
        }
        for (let e = 0; e < emoney.length; e++) {
          if (emoney[e].bank_name === bank_account[ba].bank_name) {
            tempEmoney.push(bank_account[ba]);
          }
        }
      }
      setBanks(
        tempBank.filter((bnk) => bnk.emp_acc_name !== "PikkatMasterBankAccount")
      );
      setEmoneys(tempEmoney);
    }
    /* eslint-disable */
  }, [bank_account, bank, emoney]);

  return (
    <div className="col-12 col-md-4 spacing-card relative">
      {!flag_information && (
        <div className="smrtk-not-kyc">
          {"NOT_KYC".tr()}
          <span onClick={() => navigate("/dashboard/kyc")}>
            {"NOT_KYC_ACT".tr()}
          </span>
        </div>
      )}

      <div className="card border-0 border-radius-10 h-100">
        <div className="card-body ps-4 pe-4 pt-3 pb-3">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <ModalBankAccount
              show={modalBankAccount}
              onHide={() => setModalBankAccount(false)}
            />
            <p className="fw-semibold mb-0">
              {"DISBURSE_ACC_BANK_MANAGE_TXT".tr()}
            </p>
            {company_name === "PT Berlian Amal Perkasa" ? (
              <></>
            ) : (
              <ButtonMui
                onClick={() => setModalBankAccount(true)}
                size="small"
                startIcon={<AddIcon />}
              >
                {"DISBURSE_ACC_BANK_TXT".tr()}
              </ButtonMui>
            )}
          </div>
          <Tabs defaultActiveKey="bank" className="bsa-tabs-custom mb-3" fill>
            <Tab eventKey="bank" title={"DB_BANK".tr()}>
              {banks.length ? (
                banks.map((bankItem, bankId) => {
                  return (
                    <AccountButton
                      key={bankId}
                      data={bankItem}
                      company_name={company_name}
                    />
                  );
                })
              ) : (
                <Button
                  variant="light"
                  className="bsa-box-account w-100"
                  onClick={() => setModalBankAccount(true)}
                >
                  <div className="row align-items-center">
                    <div className="col-12 d-flex align-items-center justify-content-center flex-column">
                      <AddIcon className="mb-1 fill-primary" />
                      <p className="mb-0 text-center text-primary">
                        {"DB_PLEASE_ADD".tr()}
                      </p>
                    </div>
                  </div>
                </Button>
              )}
            </Tab>
            <Tab eventKey="emoney" title={"DB_E_MONEY".tr()}>
              {emoneys.length ? (
                emoneys.map((emoneyItem, emoneyId) => {
                  return (
                    <AccountButton
                      key={emoneyId}
                      data={emoneyItem}
                      company_name={company_name}
                    />
                  );
                })
              ) : company_name === "PT Berlian Amal Perkasa" ? (
                <></>
              ) : (
                <Button
                  variant="light"
                  className="bsa-box-account w-100"
                  onClick={() => setModalBankAccount(true)}
                >
                  <div className="row align-items-center">
                    <div className="col-12 d-flex align-items-center justify-content-center flex-column">
                      <AddIcon className="mb-1 fill-primary" />
                      <p className="mb-0 text-center text-primary">
                        {"DB_BEFORE_YOU".tr()}
                      </p>
                    </div>
                  </div>
                </Button>
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

const AccountButton = ({ company_name, data }) => {
  const dispatch = useDispatch();
  const activeAccount = useSelector(serviceSlice.selector.activeAccount);

  const { emp_acc_name, emp_acc_number, bank_name, account_id } = data;

  const selectAccount = () => {
    dispatch(setActiveAccount(data));
    dispatch(setSaRequest({ name: "account_id", value: account_id }));
    dispatch(setRegularSaRequest({ name: "account_id", value: account_id }));
  };

  return (
    <Button
      onClick={selectAccount}
      variant="light"
      className="bsa-box-account w-100"
    >
      <div className="d-flex w-100 align-items-center">
        <div className="col-2 col-md-1">
          <div className="bsa-account-bank-button d-flex align-items-center">
            {activeAccount && activeAccount.account_id === account_id ? (
              <RadioButtonCheckedIcon
                name={account_id}
                className="fill-primary"
              />
            ) : (
              <RadioButtonUnchecked
                name={account_id}
                className="fill-primary"
              />
            )}
          </div>
        </div>
        <div className="col-md-4 d-none d-md-block">
          <div className="bsa-account-bank-mini d-none d-md-block">
            <img src={Card} alt="Card" width={"100%"} className="" />
            <p className="bsa-account-bank-mini-account mb-0">{bank_name}</p>
            <p className="bsa-account-bank-mini-number mb-0">
              {accountNumberHider(emp_acc_number)}
            </p>
          </div>
        </div>
        <div className="col-9 col-md-7 ps-2 ps-md-3">
          <div className="d-flex align-items-center h-100">
            <div className="w-100">
              <p className="font-12 mb-0">{emp_acc_name}</p>
              <p className="mb-0 font-11">{bank_name}</p>
              <p className="mb-0 font-13">
                <strong>{emp_acc_number}</strong>
              </p>
            </div>

            {/* <img
              src={ThaiFlag}
              alt="Card"
              width={"100%"}
              className="bsa-img-flag"
            /> */}
          </div>
        </div>
      </div>
    </Button>
  );
};
